import axios from "axios";
const token = localStorage.getItem('token');
const ApiService = axios.create({
    baseURL: 'https://api.pickmonk.com/api/',
    xsrfHeaderName: "XSRF-TOKEN",
    withCredentials: false,
    headers: {
        'Authorization': 'Bearer' + token,
    }
});
export default ApiService;