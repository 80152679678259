import * as React from 'react';
import { Button, Grid, TextField, Typography } from "@mui/material";
import ApiService from '../../api/ApiService';
import SectionTitle from '../../components/section-title';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';


function AddContestCategory() {
    const { id } = useParams();
    const [contestCategory, setContestCategory] = React.useState([]);
    const [error, setError] = React.useState(false)
    const navigate = useNavigate();
    const [input, setInput] = React.useState({
        name: '',
        description: '',
    })

    const getContestCategory = () => {
        ApiService.post("v1/admin/getContestCategory").then((response) => {
            if (response.status === 200) {
                setContestCategory(response.data.body);
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }

    const validation = () => {
        if (input.name === '' || input.description === '') {
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        if (!validation()) {
            toast.error('Please enter contest category details')
            return;
        }
        const payload = Object.assign(input);
        if (id) {
            ApiService.post(`v1/admin/updateContestCategory/${id}`, payload).then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        } else {
            ApiService.post("v1/admin/createContestCategory", payload).then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                    navigate('/admin/contestcategory-list', {replace: true});
                } else {
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }

    }

    React.useEffect(() => {
        getContestCategory();
        if (id) {
            getDetails();
        }
    }, [])
    const getDetails = () => {
        ApiService.post(`v1/admin/getContestCategoryById/${id}`).then((response) => {
            if (response.status === 200) {
                setInput(response.data.body);
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }
    
    return (
        <div>
            <React.Fragment>
                <Grid style={{ margin: '50px' }}>
                    <form>
                        <SectionTitle title="ContestCategory" subtitle="Create Contest Category" />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Name"
                                    fullWidth
                                    name='name'
                                    error={error}
                                    onBlur={validation}
                                    value={input.name}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, name: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Description"
                                    style={{ width: '100%' }}
                                    name='description'
                                    multiline
                                    rows={4}
                                    error={error}
                                    onBlur={validation}
                                    value={input.description}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, description: event.target.value
                                        })
                                    }}
                                >
                                </TextField>
                            </Grid>

                        </Grid>
                        <div className="flex items-start justify-start space-x-4 p-3">
                            <Button
                                variant="contained"
                                size="small"
                                color="success"
                                style={{ marginTop: "10px" }}
                                onClick={handleSubmit} >
                                Submit
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                style={{ marginTop: "10px" }}
                                onClick={() => navigate(-1)}     >
                                <FiArrowLeft className="stroke-current" />
                                <span className="mr-2">Back</span>
                            </Button>
                        </div>
                    </form>
                </Grid>
            </React.Fragment>

        </div >
    );
}
export default AddContestCategory