import React, { useEffect, useState } from 'react';
import moment from 'moment';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Datepicker from '../../../components/datepicker'; 
import Paper from '@mui/material/Paper';
import { Button, Grid, TextField, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ApiService from '../../../api/ApiService';
import SectionTitle from '../../../components/section-title';
import Widget from '../../../components/widget';
import DetailDialog from '../../../components/dialogs/DetailDialog';
import RejectBankRequest from '../../../components/dialogs/RejectBankRequest';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ExportToCsv } from 'export-to-csv';
import { options, setSorting } from '../helper/helper';
import excelIcon from '../../../images/excel.png';
const GetAllBanks = () => {
    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setDate(d.getDate() - 30);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const [pageSize, setPageSize] = useState(10);
    const [recordCount, setRecordCount] = useState(null);
    const [bankList, setBankList] = useState([]);
    const [isLoading, setIsFetching] = useState(false);
    const [labelSearch, setLabelSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState(null);
    const [openReject, setOpenReject] = useState(false);
    const [beneficiaryOpen, setBeneficiaryOpen] = useState(false);
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const [detail, setDetail] = useState(null);
    const [requestId, setRequestId] = useState(null);
    const [startDate, setStartDate] = useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MMM-DD'));
    const [requestStatusType, setRequestStatusType] = useState("4");
    const processDataForCSV =(csvData)=>{

        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            if(Array.isArray(csvRow.users)) {
               csvRow.Title = csvRow.users[0].name;
               delete csvRow.users;
            }
                         
        }
        return csvData;
    
    }
    const getAllBankList = (currentIndex, search, sort, size,csv) => {
        let payload = {}

        if (csv) {
            payload = {
                "filterOption": labelSearch || search,
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size }
            }
        } else {
        }
            payload = {
                "filterOption": {
                    'keywords': labelSearch || search,"startDate": startDate,
                    "endDate": endDate,
                    'status': requestStatusType
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
            }
        
        
        setIsFetching(true);
        ApiService.post(`v1/admin/getAllBankDetails`, payload).then((response) => {
            if (response.status === 200) {
                if (csv) {
                    options.filename = 'Get all bank'
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(processDataForCSV(response.data.body.data));
                } else {
                    setBankList(response.data.body.data);
                    setRecordCount(response.data.body.recordCount);
                }
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    
    
    const downloadList = () => {
        getAllBankList(0, '', '', 100000,' ');
    }
    const addBeneficary = (id) => {
        const payload = {
            id: id
        }
        ApiService.post(`v1/admin/addUserBankAsBeneficiary`, payload).then((response) => {
            if (response.status === 200) {
                setBeneficiaryOpen(true);
                getAllBankList(0, '');
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const viewDetail = (id) => {
        const payload = {
            id: id
        }
        ApiService.post(`v1/admin/getBankDetailsById`, payload).then((response) => {
            if (response.status === 200) {
                setDetail(response.data.body.bankProofImage)
                setOpen(true);
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const handleClose = () => {
        setOpen(false);
        setDetail(null);
        setBeneficiaryOpen(false)
    }
    const approveRejectRequest = () => {
        const payload = {
            id: requestId,
            reason: reason
        }
        ApiService.post(`v1/admin/rejectUserBankRequest`, payload).then((response) => {
            toast.success(response.data.message);
            setOpenReject(false);
            getAllBankList(0, '');
        }).catch((error) => {
            toast.error(error.response.data.message);
            setOpenReject(false);
        });
    }
    const openRejectDialog = (id) => {
        setRequestId(id)
        setOpenReject(true)
    }
    
    const columns = [
        {
            field: 'name', headerName: `Name`, flex: 1,
            renderCell: (params) => {
                return params.row.users[0]?.name;
            }
        },
        {
            field: 'mobile', headerName: `mobile`, flex: 1,
            renderCell: (params) => {
                return params.row.users[0]?.mobile;
            }
        },
        {
            field: 'bankName', headerName: 'Bank Name', flex: 0.5
        },
        {
            field: 'branchName', headerName: 'Branch Name', flex: 0.5
        },
        {
            field: 'accountNumber', headerName: 'Account No.', flex: 1
        },
        {
            field: 'ifscCode', headerName: 'Ifsc Code', flex: 0.5
        },
        {
            field: 'beneficaryId', headerName: 'Beneficiary Id', flex: 1
        },
        {
            field: 'status', headerName: 'Status', flex: 0.6
        },
        {
            field: 'viewDetail', headerName: 'View Detail', flex: 0.6,
            renderCell: (params) => (
                <div>
                    <button className="btn btn-default bg-orange-500 hover:bg-orange-600 text-white btn-rounded ml-1 mr-1" onClick={() => viewDetail(params.row.id)}>
                        View
                    </button>
                </div>
            )
        },
        {
            field: 'action', headerName: 'Action', flex: 2,
            renderCell: (params) => (
                <>
                    {params.row.status === 'Pending' &&
                        <div>
                            <button className="btn btn-default bg-green-500 hover:bg-green-600 text-white btn-rounded ml-1 mr-1" style={{  whiteSpace: 'normal'}}onClick={() => addBeneficary(params.row.id)}>
                                Add As Beneficiary
                        </button>
                        </div>}
                    {params.row.status !== 'Pending' ? '' :
                        <div>
                            <button className="btn btn-default bg-red-500 hover:bg-red-600 text-white btn-rounded ml-1 mr-1" onClick={() => openRejectDialog(params.row.id)}>
                                reject
                        </button>
                        </div>}
                </>
            )
        },

    ];
    const handleSearch = () => {
        getAllBankList(0, '');
    }
    const rejectClose = () => {
        setOpenReject(false);
    }
    useEffect(() => {
        getAllBankList(0, '');
    }, [])
    return (
        <>
            <SectionTitle title="All Banks" subtitle="All Banks"></SectionTitle>
            <Widget>
            <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '1%', padding: '1%' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }}>
                            <Grid item xs={1} sm={4} md={2}>
                            <TextField id="standard-basic" label="Search by Name, Bank" variant="standard" style={{ width: '100%' }}
                            onChange={(e) => {
                                setLabelSearch(e.target.value);
                            }} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <TextField
                                id="outlined-select-request-status"
                                select
                                label="Filter"
                                size="small"
                                value={requestStatusType}
                                onChange={(e) => setRequestStatusType(e.target.value)}
                                style={{ width: '100%', marginTop: '15px'}}
                                >
                                <MenuItem value={'1'}>Link</MenuItem>
                                <MenuItem value={'2'}>Unlink</MenuItem>
                                <MenuItem value={'3'}>Rejected</MenuItem>
                                <MenuItem value={'4'}>Pending</MenuItem>
                                <MenuItem value={'0'}>All</MenuItem>
                            </TextField>
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={startDate} onChange={(e)=>setStartDate(e.format('YYYY-MMM-DD , hh:mm:ss'))}  label={'Start Date'}   />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={endDate} onChange={(e)=>setEndDate(e.format('YYYY-MMM-DD , hh:mm:ss'))}  label={'End Date'}  />
                            </Grid>

                            <Grid item xs={1} sm={4} md={2}>
                            <Button variant="contained"
                            style={{ float: 'right', margin: '1%', marginTop:"7%" }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<SearchIcon />}
                            onClick={() =>  getAllBankList(0,'')}
                        >
                            Search
                        </Button>
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                
                            <img src={excelIcon} width='30px' height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: 15,
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%', height: '100px !important' }}
                            rowCount={recordCount}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={bankList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getAllBankList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) =>{
                                const sort = setSorting(modal);
                                setSortOption(sort);
                                 getAllBankList(0, '', sort)
                                }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getAllBankList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                            getRowClassName={(params) =>
                                params.row.users[0]?.hasAadharCardKYCByPassed === true ? 'hasAadharCardKYCByPassedBackColor' : ''
                            }
                        />
                    </div>
                </Paper>
            </Widget>
            <DetailDialog open={open} handleClose={handleClose} image={detail} header={'Bank Detail'} />
            {beneficiaryOpen ?
                <DetailDialog open={beneficiaryOpen} handleClose={handleClose} detail={'Successfully added as beneficary'} header={'Beneficiary'} /> : ''
            }
            <RejectBankRequest open={openReject} handleClose={rejectClose}
                contentText='Please enter the reason for reject request'
                rejectRequest={approveRejectRequest} setReason={(value) => setReason(value)} />

        </>
    )
}
export default GetAllBanks