import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import SectionTitle from '../../components/section-title';
import Widget from '../../components/widget'
import ApiService from '../../api/ApiService';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import moment from 'moment';
import { ArrowBack } from '@mui/icons-material';
import Loader from '../../components/loader';
import { setSorting, options } from './helper/helper';
import CircularProgress from '@mui/material/CircularProgress';

const WhatshAppNotification = () => {
    const params = useParams();
    const [userList, setUserList] = useState([]);
    const [notificationTemplateList, setNotificationTemplateList] = useState([]);
    const [userGroupList, setUserGroupList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [labelSearch, setLabelSearch] = React.useState('');
    const [pageSize, setPageSize] = React.useState(10);
    const [matchData, setMatchData] = useState(null);
    const [id, setId] = useState('');
    const [sortOption, setSortOption] = useState({ updatedOn: -1 });
    const [lablesSearch, setLablesSearch] = useState('');
    const [listCount, setListCount] = React.useState(null);
    const [extendTime, setExtendTime] = useState("");
    const [isLoading, setIsFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sendAllloading, setSendAllLoading] = useState(false);
    const [templateBody, setTemplateBody] = useState({});

    const [set, setReset] = useState(false);

    const [isTeamCreated, setIsTeamCreated] = useState(null);
    const navigate = useNavigate();


    const selectUnselectUser = (item) => {

        console.log(selectedList.filter((i) => console.log(i.id)));

        if (selectedList.filter((i) => i.id === item.id).length > 0) {
            let index = selectedList.findIndex((i) => i.id === item.id)
            console.log(index);
            selectedList.splice(index, 1)
            setReset(!set)
        } else {
            console.log("call", userList);
            selectedList.push(item)
            setReset(!set)


        }
    }


    const getTemplate = () => {
        setIsFetching(true);

        ApiService.post(`v1/admin/getWatiMessageTemplates`).then((response) => {


            if (response.status === 200) {
                setNotificationTemplateList(response.data.body.messageTemplates);

            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const getUserGroup = () => {

        ApiService.post(`v1/admin/getNotificationWorkflowList`, { "onlyWatiGroup": true }).then((response) => {
            if (response.status === 200) {
                let groupList = response.data.body;
                groupList.push({
                    "name": "All Users",
                    "id": "000000545ff6ab5371ae1476"
                })
                setUserGroupList(groupList);
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const sendToAll = () => {

        let data = {};

        if (id === '' || extendTime === '') {
            toast.error("Select notification template and  user group");
            return
        }

        setSendAllLoading(true)

        data.notificationGroupId = id
        data.sendToAll = true
        data.template_name = extendTime
        data.broadcast_name = extendTime
        ApiService.post(`v1/admin/sendTemplateMessages`, data).then((response) => {
            if (response.status === 200) {
                setSendAllLoading(false)

                toast.success(response.data.message);

            } else {
                toast.error(response.data.message)
            }
            setSendAllLoading(false);
            setSendAllLoading(false)

        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data.message);
            setSendAllLoading(false);


        });
    }


    const sendMessage = () => {
        if (id === '' || extendTime === '') {
            toast.error("Select notification template and  user group");
            return
        }
        if (selectedList.length < 1) {
            toast.error("Select atleast one user");
            return
        }

        setLoading(true)
        let data = {};
        let recievers = []
        for (let i = 0; i < selectedList.length; i++) {
            let user = {
                "userId": selectedList[i]?.id,
                "whatsappNumber": selectedList[i]?.mobile,
                "customParams": [
                    {
                        "name": "name",
                        "value": selectedList[i]?.name
                    }
                ]
            }

            recievers.push(user)
        }
        data.template_name = extendTime
        data.broadcast_name = extendTime
        data.notificationGroupId = id
        data.recievers = recievers
        ApiService.post(`v1/admin/sendTemplateMessages`, data).then((response) => {
            if (response.status === 200) {
                setLoading(false)
                toast.success(response.data.message);

            } else {
                toast.error(response.data.message)
            }
            setLoading(false)

            setIsFetching(false);
        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data.message);
            setIsFetching(false);
            setLoading(false)

        });
    }


    const getUsers = (id, skip, size) => {
        const data = {
            'notificationGroupId': id ? id : id,
            "keyword": lablesSearch || "",
            "sortOption": { "name": -1 },
            "pageSizeOption": { "skip": skip || 0, "limit": size ? size : pageSize }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getAllUsersByGroup`, data).then((response) => {
            if (response.status === 200) {
                setUserList(response.data.body.data);
                setListCount(response.data.body.recordCount);
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    useEffect(() => {
        getUserGroup();
        getTemplate();
    }, [])
    const columns = [
        {
            field: 'name', headerName: `Player Name`, flex: 1


        },
        { field: 'mobile', headerName: `Mobile`, flex: 1 },
        { field: 'email', headerName: `Email`, flex: 1 },
        {
            field: 'pincode', headerName: `Pincode`, flex: 1,

        },
        {
            field: 'state', headerName: `State`, flex: 1,

        },
        {
            field: 'teamName', headerName: `TeamName`, flex: 1,

        },
        {
            field: 'city', headerName: `City`, flex: 1,

        },
        {
            field: 'createdOn', headerName: `CreatedOn`, flex: 1,
            renderCell: (params) => (
                moment(params?.row?.createdOn).format('MMM DD, YYYY HH:mm:ss')
            )
        },
        {
            headerName: `Selected`, flex: 1,
            renderCell: (params) => {

                return (<Checkbox
                    checked={selectedList.filter((e) => e.id === params?.row?.id).length > 0}
                    onChange={(e) => selectUnselectUser(params?.row)}

                    color="primary"
                />)
            }
        },


    ];
    const getuser = (e) => {
        setId(e)
        getUsers(e)
    }
    const setTemplate = (item) => {

        setExtendTime(item?.elementName)
        setTemplateBody(item)
    }
    return (
        <>
            <SectionTitle title="Manage Wati messages" subtitle={matchData?.competition[0]?.title}></SectionTitle>
            <Widget>
                <Grid container spacing={2}>

                    <Grid item xs={6} md={2} sm={2} lg={2}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="User Group"
                            size="small"
                            style={{ width: '100%', marginTop: '3px' }}
                            onChange={(e) => setId(e.target.value)}
                        >
                            {userGroupList.map((item) => <MenuItem value={item?.id}>{item?.name}</MenuItem>)}

                        </TextField>
                    </Grid>
                    <Grid item xs={6} md={2} sm={2} lg={2}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Notification Template"
                            size="small"
                            style={{ width: '100%', marginTop: '3px' }}
                            onChange={(e) => setTemplate(e.target.value)}
                        >
                            {notificationTemplateList.map((item) => <MenuItem value={item}>{item?.elementName}</MenuItem>)}

                        </TextField>
                    </Grid>
                    <Grid item xs={1} md={2} sm={2} >
                        <TextField id="standard-basic" label="Search by Name" variant="standard" style={{ width: '100%' }}
                            onChange={(e) => {
                                setLablesSearch(e.target.value);
                            }} />
                    </Grid>

                    <Grid item xs={6} md={2} sm={2} lg={2}>
                        <Button variant="contained"
                            style={{ margin: '1%', marginLeft: 0 }}
                            color="success"
                            className='[btn btn-default text-white btn-rounded]'
                            onClick={() => getUsers(id)}
                        >
                            Search
                        </Button>

                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ padding: "1%", }}>
                    <Grid item spacing={5} xs={2} md={2} sm={2} lg={6}>
                        <div>
                            {templateBody?.bodyOriginal}
                        </div>
                    </Grid>
                    <Grid item xs={2} md={2} sm={2} lg={2} >
                        <Button variant="contained"
                            style={{ margin: '1%', marginLeft: 0 }}
                            color="success"
                            className='[btn btn-default text-white btn-rounded]'
                            onClick={() => sendToAll()}
                            loading
                        >
                            Send to All {sendAllloading && <CircularProgress size={18} style={{ color: "white", marginLeft: 10 }}>
                            </CircularProgress>}
                        </Button>
                    </Grid>
                    <Grid item xs={2} md={4} sm={4} lg={2}>

                        <Button variant="contained"
                            style={{ margin: '1%', marginLeft: 0 }}
                            color="success"
                            className='[btn btn-default text-white btn-rounded]'
                            onClick={() => sendMessage()}
                        >
                            Send to selected {loading && <CircularProgress size={18} style={{ color: "white", marginLeft: 10 }}>

                            </CircularProgress>}
                        </Button>
                    </Grid>
                </Grid>
            </Widget>
            <Widget>
                <Paper elevation={2}>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%' }}
                            disableColumnMenu
                            loading={isLoading}
                            rows={userList || []}
                            disableColumnFilter
                            disableColumnSelector
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            columns={columns}
                            pageSize={pageSize}
                            onPageChange={(index) => {
                                let skip = pageSize * index;
                                getUsers(id, skip)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getUsers(id, 0, '', sort);
                                setSortOption(sort);
                            }}
                            disableSelectionOnClick
                            paginationMode="server"
                            rowCount={listCount}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getUsers(id, 0, newPageSize);
                            }}
                            getRowId={(row) => row.id}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
    )
}
export default WhatshAppNotification