import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Layout from '../../layouts/centered'
import CenteredForm from '../../layouts/centered-form'
import { APPLogoSVG } from '../../components/assets/images';
import Validation from '../../components/forms/validation';
import ApiService from '../../api/ApiService'
import { toast } from 'react-toastify'
import Alert from '../../components/alerts'


const AdminLogin = ({ message = null }) => {
    const [data, onSubmit] = useState(null)
    const navigate = useNavigate()
    let items = [
        {
            label: 'User Name',
            error: { required: 'Please enter a valid email' },
            name: 'username',
            type: 'username',
            placeholder: 'Enter you email'
        },
        {
            label: 'Password',
            error: {
                required: 'Password is required',
                minLength: {
                    value: 4,
                    message: 'Your password should have at least 4 characters'
                },
               
            },
            name: 'password',
            type: 'password',
            placeholder: 'Enter your password'
        },
    ]
    const onSubmitFn = (data) => {
        ApiService.post("v1/admin/login", data).then((response) => {
            if (response.status === 200) {
                toast.success(`welcome ${response.data.body.user.name}`)
                localStorage.setItem('token', response.data.body.token);
                localStorage.setItem('user', JSON.stringify(response.data.body.user));
                localStorage.setItem('permission',JSON.stringify(response.data.body.allowedPages));
                localStorage.setItem('admin',response.data.body.admin);
                navigate('/admin/dashboard?login=true')
            } else {
                toast.error(response.response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
        })
    }
    return (
        <Layout>
            <div className='items-center justify-center' style={{ backgroundColor: '#faf7f9' }}>
                <img src={APPLogoSVG} alt="" style={{ width: 230, marginLeft: 120 }}  ></img>
                <CenteredForm
                    title="Admin Login"
                    subtitle="Please enter your username and password to login">
                    <div className="flex flex-col">
                        {data && message && (
                            <div className="w-full mb-4">
                                <Alert
                                    color="bg-transparent border-green-500 text-green-500"
                                    borderLeft
                                    raised>
                                    {message}
                                </Alert>
                            </div>
                        )}
                        <Validation items={items} onSubmitFn={onSubmitFn} onSubmit={onSubmit} />
                    </div>
                    <div className="w-full mt-3 mb-6">
                    </div>
                      
                </CenteredForm></div>
        </Layout>
    )
}

export default AdminLogin
