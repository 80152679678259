import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function RejectPanRequestDialog(props) {
    const { open, handleClose, rejectRequest, setReason, data } = props;
    const [required, setRequired] = React.useState(null)
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Reject Request</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To Reject pan request, please enter reason here. We
                        will reject the request.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Reason"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            setReason(e.target.value);
                            setRequired(e.target.value);
                        }}
                        error={required === null ? true : false}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => rejectRequest(data.id, 'Rejected')}>Reject Request</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}