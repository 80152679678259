import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SectionTitle from '../../../components/section-title';
import ApiService from '../../../api/ApiService';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
const SendNotification = () => {
    const [input, setInput] = useState({
        title: '',
        body: '',
        password: '',
    })
    const [isloading, setIsloading] = useState(false);

    const validation = () => {
        if (input.title === '' || input.body === '') {
            return false;
        }
        return true;
    }
    const handleSubmit = () => {
        if (!validation()) {
            toast.error('Please enter the details')
            return;
        }
        setIsloading(true)
        setInput({
            ...input, body: '', title: '', password: ''
        })
        const payload = Object.assign(input);
        const apiTimeout = 1000*60*5; //5 minutes
        ApiService.post(`v1/admin/sendNotification`, payload,{timeout:apiTimeout}).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message);
                setIsloading(false)
                setInput({
                    ...input, body: '', title: '', password: ''
                })
            } else {
                setIsloading(false)
                toast.error(response.data.message)
            }
        }).catch((error) => {
            setIsloading(false)
            toast.error(error.response.data.message);
        });

    }

    return (
        <div>
            <React.Fragment>
                <Grid style={{ margin: '50px' }}>
                    <form>
                        <SectionTitle title="Send Notification" subtitle={`Send Notification`} />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Title"
                                    fullWidth
                                    name='Title'
                                    value={input.title}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, title: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Body"
                                    style={{ width: '100%' }}
                                    name='Body'
                                    multiline
                                    rows={4}
                                    value={input.body}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, body: event.target.value
                                        })
                                    }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Password"
                                    fullWidth
                                    name='Password'
                                    type='password'
                                    value={input.password}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, password: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            style={{ marginTop: "15px" }}
                            disabled={input.password.length === 0}
                            onClick={isloading === false && handleSubmit}
                            loading={true}
                            size="medium" >
                            Send   {isloading && <CircularProgress size={18} style={{ color: "white", marginLeft: 10 }}>

                            </CircularProgress>}
                        </Button >
                    </form>
                </Grid>
            </React.Fragment>

        </div >
    )
}
export default SendNotification