import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function SettlementRequestDialog(props) {
    const { open, handleClose, acceptSettlementRequest, setReason, data } = props;
    const [required, setRequired] = React.useState(null)
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Settlement Request</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    Please enter settlement discription.  
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Remarks"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            setReason(e.target.value);
                            setRequired(e.target.value);
                        }}
                        error={required === null ? true : false}
                    />

                </DialogContent>
                <DialogActions>
                <Button variant="contained"
                        style={{ float: 'right', marginTop: '2%', marginRight: '1%' }}
                        color="success"
                        className='[btn btn-default text-white btn-rounded]'
                        onClick={() =>acceptSettlementRequest(data.id)}>Submit</Button>
                    <Button
                        variant="contained"
                        style={{ float: 'right', marginTop: '2%', marginRight: '1%' }}
                        color="error"
                        className='[btn btn-default text-white btn-rounded]'
                        onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}