import { CircularProgress } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import parse, { Element } from 'html-react-parser';
import ApiService from '../api/ApiService';
import '../css/public/css/style.css';
import '../css/public/css/media.css';
import 'bootstrap/dist/css/bootstrap.css';
import logo from '../css/public/images/logo.png';
import logofoo from './../css/public/images/logofoot.png';
import { isMobile } from "react-device-detect";
import CommanFooter from './CommanFooter';

const ContentPage = () => {
    const { pageName } = useParams();
    const [pageData, setPageData] = React.useState(null);
    const [pageTitle, setPageTitle] = React.useState(null);
    const [isLoading, setIsFetching] = React.useState(true);

    const getStaticPage = () => {
         
        setIsFetching(true);
        ApiService.post(`v1/public/getSiteContent`, {
            pageName
        }).then((response) => {
            if (response.status === 200 && response?.data?.body?.html) {
                if (response?.data?.body?.html === "Oops! the requested page not available") {
                    setPageData(`
            <h2 className='text-xl text-black'>
              Oops! the requested page not available
            </h2>
          `)
                } else {
                    setPageData(response?.data?.body?.html || `<p></p>`)
                    setPageTitle(response?.data?.body?.title || `About Pickmonk`)
                }
            } else {
                toast.error(response.data.message);
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error?.response?.data?.message || "Error occurred");
            setIsFetching(false);
        });
    }

    React.useEffect(() => {
        if (pageName) {
            getStaticPage();
        }
    }, []);

    const parser = input =>
        parse(input, {
            replace: domNode => {
                if (domNode instanceof Element && domNode.attribs.class === 'remove') {
                    return <></>;
                }
            }
        });

    if (isLoading) return <div className="h-screen w-screen bg-white flex justify-center items-center">
        <CircularProgress />
    </div>
    return (
        <>
            <div id='innerpage'>
                <header class="home">
                    <div class="container"> <a class="navbar-brand logo" href="/"><img src={logofoo} style={{display:"inline-block",marginTop:10}}
                        alt="" /></a>
                    </div>
                </header>
                <main>
                    <div class="container">
                        <div class="innercontent">
                            <div class="row">                                
                                {isMobile===true && <h4 style={{ textAlign: "center", paddingBottom: "40px" }}>{pageTitle}</h4>}
                                {isMobile===false && <h2>{pageTitle}<span></span></h2>}
                                <div class="col-lg-8">
                                    <div>{pageData && parser(pageData)}</div>
                                </div>
                                {isMobile===false && <div class="col-lg-4">
                                    <div class="getapplink"> 
                                    {/* <span class="title">GET APP LINK ON SMS</span> */}
                                        {/* <div class="mb-3">
                                            <input type="text" class="form-control" aria-label="Sizing example input"
                                                placeholder="Type your mobile no. here..." />
                                        </div> */}
                                        <button class="btn getlinkbtn" type="button" id="button-addon2">Get App From</button>

                                        <div class="appbtn rside"><a href="https://play.google.com/store/apps/details?id=com.pickmonk"><img class="img-fluid" src="../images/btn-goggleplay.png" alt="" /></a></div>
                                        <div class="appbtn rside"><a href="https://apps.apple.com/us/app/pickmonk/id1561916915"><img class="img-fluid" src="../images/btn-appstore.png" alt="" /></a></div></div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <CommanFooter />
                </main>
            </div>
        </>

    )
}

export default ContentPage;