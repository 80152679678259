import React, {useEffect, useState} from 'react'
import ReactQuill from 'react-quill'
import './styles.css'
import beautify from 'js-beautify'

import { CKEditor } from '@ckeditor/ckeditor5-react';
 import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import Editor from '../../ckeditor5/build/ckeditor';


//export const CustomToolbarExample = (props) => {
//  const { handleChange, value } = props;
//  const modules = {
//      toolbar: [
//          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
//          ['blockquote', 'code-block'],

//          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
//          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
//          [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
//          [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
//          [{ 'direction': 'rtl' }],                         // text direction

//          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
//          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

//          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
//          [{ 'font': [] }],
//          [{ 'align': [] }],

//          ['clean']                                         // remove formatting button
//      ]
//  }

//  const formats = [
//    'header',
//    'bold',
//    'italic',
//    'underline',
//    'strike',
//    'blockquote',
//    'list',
//    'bullet',
//    'indent',
//    'link',
//    'image'
//  ]
//  return (
//    <div className="w-full">
//      <ReactQuill
//        theme="snow"
//              onChange={(newval) => handleChange(newval)}
//              preserveWhitespace={true}
//        value={value}
//        modules={modules}
//        formats={formats}
//      />
//    </div>
//  )
//}

export const TextEditorExample = () => {
  const sample = `<h3>Lorem ipsum...</h3>
<p>Madman finer merely valor shield prosaic set justice state Cair stinks legendary. L kingsfoil infectious never terribly means warrior Isildur's asleep glasses deserves people's. Nobody tosses a Dwarf.</p>
<h2>Strongest investment...</h2>
<p>Sing nonsense Balin wound bastards names! Bodyguard Elessar sage bones born deny swung reaches. Nobody tosses a Dwarf.</p>`

  const [value, setValue] = useState(sample)
  let {html} = {...beautify}

  return (
    <>
      <div className="w-full mb-4">
        <div className="w-full">
          <ReactQuill theme="snow" value={value} onChange={setValue} />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row w-full lg:space-x-4 space-y-4 lg:space-y-0">
        <div className="w-full lg:w-1/2">
          <div className="text-base font-bold mb-2">Raw HTML</div>
          {value && <pre className="p-2">{html(value)}</pre>}
        </div>
        <div className="w-full lg:w-1/2">
          <div className="text-base font-bold mb-2">Formatted output</div>
          {value && (
            <div className="p-3" dangerouslySetInnerHTML={{__html: value}} />
          )}
        </div>
      </div>
    </>
  )
}
export const CustomToolbarExample = (props) => {
   const { handleChange, value } = props;
  return (
    <div >
          
          <CKEditor
              editor={ClassicEditor}
              config={{
                  toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote',
                      'link', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable',
                      'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed',
                      'outdent',
                      'indent', '|', 'undo', 'redo',

                  ]
              }}

              data={value}
              onReady={editor => {
              }}
              onBlur={(event, editor) => {
                  handleChange(editor.getData());
              }}
              onChange={(event, editor) => {
              }}
          />

             
    </div>
  )
}