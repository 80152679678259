import React, { useCallback } from 'react'
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material'
import { toast } from 'react-toastify';
import { ArrowBack } from '@mui/icons-material';
import SectionTitle from '../../../components/section-title'
import { CustomToolbarExample } from '../../../components/text-editor';
import Widget from '../../../components/widget'
import { useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../../api/ApiService';
import moment from 'moment';
 import './styles.css';

export default function CreatePage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [input, setInput] = React.useState({
    "pageName": "",
    "pageTitle": "",
    "content": "",
    "isActive": false
  });
  const [fetchingData, setFetchingData] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const fetchPage = () => {
    const payload = {
      "id": id
    }
    if (id) {
      ApiService.post(`v1/admin/getSiteStaticContentsById`, payload)
        .then((response) => {
          const responseBody = response?.data?.body || null;
          if (responseBody && typeof responseBody === 'object' && !Array.isArray(responseBody)) {
            setInput({
              ...input,
              ...responseBody
            })
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || "Error");
          navigate('/admin/static-pages', { replace: true });
        })
        .finally(() => setFetchingData(false))
    }
  }

  React.useEffect(() => {
    if (id) {
      setFetchingData(true);
      fetchPage();
    }
  }, [id]);

  const handleSubmission = () => {
    setShowError(false);
    if (input.pageName
      && input.content) {
      ApiService.post(id ? 'v1/admin/updateSiteStaticContents' : `v1/admin/insertSiteStaticContents`, {
        ...input,
        ...(id && { updatedOn: moment() })
      })
        .then((response) => {
          const responseMessage = response?.data?.message;
          toast.success(responseMessage || `Static page ${id ? 'updated' : 'created'}!`);
          navigate('/admin/static-pages', { replace: true });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || "Error");
        })
    } else {
      setShowError(true);
    }
  }

  const handlePageNameValue = (event) => setInput({
    ...input, pageName: event.target.value.split(" ").join("-")
  })

  const handlePageTitleValue = (event) => setInput({
    ...input, pageTitle: event.target.value
  })

    const handleContentValue = (value) => setInput({
        ...input, content : value === "<p><br></p>" ? "" : value
    })

  const handleActive = (event) => {
    setInput({
      ...input, isActive: event.target.checked
    })
  }

  if (fetchingData) {
    return (
      <div className='w-full h-full bg-white flex justify-center items-center'>
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <SectionTitle title="Static Pages" subtitle="Create / Edit Static Page" />
      <Widget>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <TextField
                error={showError && !input.name}
                helperText={showError && !input.pageName && "Required"}
                label="Page Name"
                fullWidth
                name='pageName'
                value={input.pageName}
                autoComplete="cc-exp"
                variant="outlined"
                disabled={id && input.pageName}
                onChange={handlePageNameValue}
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                error={showError && !input.name}
                label="Page Title"
                fullWidth
                name='pageTitle'
                value={input.pageTitle}
                autoComplete="cc-exp"
                variant="outlined"
                onChange={handlePageTitleValue}
                inputProps={{
                  maxLength: 100,
                }}
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
     
              <CustomToolbarExample handleChange={(value)=>handleContentValue(value)} value={input.content} />
              {showError && !input.content && <div className="title text-red-700 mt-2 mb-2">Required</div>}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  name="isActive"
                  checked={input.isActive}
                  onChange={handleActive}
                />}
                label="Active"
              />
            </Grid>
            <Grid item xs={12} md={6}
              className="flex flex-row justify-end gap-4"
            >
              <Button
                variant="contained"
                onClick={() => { navigate('/admin/static-pages', { replace: true }) }}
                size="medium"
                startIcon={<ArrowBack />}>
                Back
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmission}
                size="medium" >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Widget>
    </>
  )
}
