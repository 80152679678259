import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../components/section-title';
import MasterTable from '../../../components/MasterTable/MasterTable';
import Datepicker from '../../../components/datepicker'
import Widget from '../../../components/widget';
import ApiService from '../../../api/ApiService';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ExportToCsv } from 'export-to-csv';
import excelIcon from '../../../images/excel.png';
import { setSorting, options } from '../helper/helper';
import { DataGrid } from '@mui/x-data-grid';
const UserTransaction = () => {

    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setMonth(d.getMonth() - 3);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const params = useParams();
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(10);
    const [transactionList, setTransactionList] = useState([]);
    const [labelSearch, setLabelSearch] = useState('');
    const [amountData, setAmountData] = useState('');
    const [search, setSearch] = useState({ transactionType: '0' });
    const [startDate, setStartDate] = useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MMM-DD'));
    const [requestStatusType, setRequestStatusType] =  useState('0');
    const [isLoading, setIsFetching] = useState(false);
    const [total, setTotal] = useState(null)
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const processDataForCSV =(csvData)=>{

        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            if(Array.isArray(csvRow.Users)) {
                delete csvRow.Users;
            }
                         
        }
        return csvData;
    
    }



    const getTransactionList = (currentIndex, search, sort, size, csv) => {
        let payload = {}
        
        if (csv) {
            payload = {
                "userId": params?.id,
                "filterOption": {
                    "keywords": labelSearch || search,
                    "startDate": startDate,
                    "endDate": endDate,'transactionType': requestStatusType
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
            }
        } else {

        }
        payload = {
            "userId": params?.id,
            "filterOption": {
                "keywords": labelSearch || search,
                "startDate": startDate,
                "endDate": endDate,'transactionType': requestStatusType
            },
            "sortOption": sort || sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
        }
        setIsFetching(true);

        ApiService.post(`v1/admin/getAllTransactions`, payload).then((response) => {
            if (response.status === 200) {
                if (csv) {
                    setIsFetching(false);
                    options.filename = 'UserTransaction'
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(processDataForCSV(response.data.body.data.transactions));
                } else {
                setTransactionList(response.data.body.data);
                setTotal(response.data.body.recordCount)
            }
         } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error?.response?.data?.message);
            setIsFetching(false);
        });
    }

    const columns = [
       {
            field: 'startDate', headerName: 'Created on', flex: 1,
            renderCell: (params) => {
                return moment(params.row.startDate).format('MMM DD, YYYY HH:MM:SS');
            },
        },
        {
            field: 'amount', headerName: 'Amount', flex: 1,
            renderCell: (params) => {
                if(params.row.amount !== null &&params.row.amount !== undefined ){
                return <div style={{ color: params.row.isCredit === false ? "red" : "green" }}>
                    ₹ {params.row.amount.toLocaleString()}
                    </div>
                }
            },



        },
        {
            field: 'isPaymentSuccess', headerName: 'Success', flex: 1,  
            renderCell: (params) => {
                if (params.row.isPaymentSuccess === true) {
                    return <div style={{ color: "green" }}>Yes</div>
                }
                return <div style={{ color: "red" }}>No</div>
            },
        },
        {
            field: 'transactionNote', headerName: 'Note', flex: 1
        },
    ];

    const handleSearch = () => {
        getTransactionList(0, '');
    }
    useEffect(() => {
        getTransactionList(0, '')
    }, [])
    const downloadList = () => {
        getTransactionList(0, '', '', 100000,' ');
    }

    
    return (
        <>
            <SectionTitle title='User Transaction' subtitle='User Transaction'></SectionTitle>
            <Widget>
                <Widget>
                    <Grid container spacing={2} >
                        <Grid item xs={4} md={4}>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Total Deposite Amount :  ₹ { transactionList?.TotalDepositeAmount!==undefined && transactionList?.TotalDepositeAmount!==null && transactionList?.TotalDepositeAmount.toLocaleString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Total Winning Amount :  ₹ {transactionList?.TotalWinningAmount!==undefined && transactionList?.TotalWinningAmount!==null&& transactionList?.TotalWinningAmount.toLocaleString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Total Withdrawal Amount :  ₹ {transactionList?.TotalWithdrawalAmount!==undefined && transactionList?.TotalWithdrawalAmount!==null && transactionList?.TotalWithdrawalAmount.toLocaleString()}
                            </Typography>
                        </Grid>
                    </Grid>
                </Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '1%', padding: '1%' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }}>
                        
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={startDate} onChange={(e)=>setStartDate(e.format('YYYY-MMM-DD'))}  label={'Start Date'} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={endDate} onChange={(e)=>setEndDate(e.format('YYYY-MMM-DD'))}  label={'End Date'} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <TextField
                                id="outlined-select-request-status"
                                select
                                label="Filter"
                                value={requestStatusType}
                                onChange={(e) => setRequestStatusType(e.target.value)}
                                size="small"
                                style={{ width: '100%', marginTop: '15px'}}
                                >
                                    <MenuItem value={'0'}>All</MenuItem>
                                    <MenuItem value={'1'}>Amount added</MenuItem>
                                    <MenuItem value={'2'}>Withdrawal</MenuItem>
                                    <MenuItem value={'4'}>Winning</MenuItem>
                                    <MenuItem value={'5'}>Coupon Code</MenuItem>
                                    <MenuItem value={'8'}>Bonus Received</MenuItem>
                                    <MenuItem value={'10'}>Contest Joined</MenuItem>
                                    <MenuItem value={'24'}>Refund</MenuItem>
                            </TextField>
                            </Grid>

                            <Grid item xs={1} sm={4} md={2}>
                                <Button variant="contained"
                                    style={{ marginTop: '17px' }}
                                    className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                    startIcon={<SearchIcon />}
                                    onClick={() => getTransactionList(0, '')}
                                >
                                    Search
                                </Button>
                                <img src={excelIcon} width='30px' height='30px' style={{
                            display: 'inline-flex',  
                            float: 'right',
                            margin: '18px 5px 0 0',
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                            </Grid>
                            {/* <Grid item xs={1} sm={4} md={2}>
                                <Button style={{ marginTop: '17px' }} variant="contained" onClick={() => (navigate('/admin/gst-list'))}>
                                    View Gst
                                </Button>
                            </Grid> */}
                        </Grid>

                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%', height: '100px !important' }}
                            loading={isLoading}
                            rows={transactionList?.transactions || []}
                            rowCount={total}
                            pagination
                            pageSize={pageSize}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getTransactionList(skip, labelSearch)
                            }}
                            sortingMode="server"
                            paginationMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getTransactionList(0, '', sort);
                                setSortOption(sort);
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getTransactionList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
    )
}
export default UserTransaction