import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../components/section-title';
import Datepicker from '../../../components/datepicker'
import Widget from '../../../components/widget';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, MenuItem, TextField, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import ApiService from '../../../api/ApiService';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { ExportToCsv } from 'export-to-csv';
import excelIcon from '../../../images/excel.png';
import { setSorting, options } from '../helper/helper';

const TDS = () => {
    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setDate(d.getDate() - 15);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const [startDate, setStartDate] = useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MMM-DD'));
    const [isLoading, setIsFetching] = useState(false);
    const [index, setIndex] = React.useState(null);
    const [total, setTotal] = useState(null)
    const [pageSize, setPageSize] = useState(10);
    const [labelSearch, setLabelSearch] = useState('');
    const [userTDSList, setUserTDSList] = useState([]);
    const [totalRecord, setTotalRecord] = useState(null);
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const [requestStatusType, setRequestStatusType] = React.useState('All');
    const processDataForCSV =(csvData)=>{

        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            if(Array.isArray(csvRow.Users)) {
                csvRow.Name = csvRow.Users[0].name;
                csvRow.Email = csvRow.Users[0].email;
                csvRow.title = csvRow.Matches[0]?.title
                csvRow.panNumber = csvRow.UserPANCard[0]?.panNumber
                csvRow.name = csvRow.Contests[0]?.name
                delete csvRow.Users;
                delete csvRow.Matches;
                delete csvRow.UserPANCard;
                delete csvRow.id;
                delete csvRow.Contests;
            }
                         
        }
        return csvData;
    
    }

    const getTDS = (currentIndex, search, sort, size,csv) => {
        setIndex(currentIndex)
        let payload = {}
        
        if (csv) {
            payload = {
                "filterOption": {
                    'keyword': labelSearch || search,
                    "startDate": startDate,
                    "endDate": endDate,'status': requestStatusType
                },
                "sortOption": sort || sortOption,
                "pageSizeOption":{"skip": parseInt(currentIndex), "limit": size ? size : pageSize}
            }

        } else {
         
        } payload = {
            "filterOption": {
                'keyword': labelSearch || search,
                "startDate": startDate,
                "endDate": endDate,'status': requestStatusType
            },
            "sortOption": sort || sortOption,
            "pageSizeOption":{"skip": parseInt(currentIndex), "limit": size ? size : pageSize}
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getAllTDSData`, payload).then((response) => {
            if (response.status === 200) {
                if (csv) {
                    setIsFetching(false);
                    options.filename = 'TDS'
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(processDataForCSV(response.data.body.data));
                } else {
                setUserTDSList(response.data.body.data);
                setTotalRecord(response.data.body.recordCount)
                }
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }

    const columns = [
        {
            field: 'name', headerName: 'Name', flex: 1,
            renderCell: (params) => {
                return params?.row?.Users[0]?.name
            }
        },
        {
            field: 'Email', headerName: 'Email', flex: 1,
            renderCell: (params) => {
                return params?.row?.Users[0]?.email
            }
        },
        {
            field: 'match', headerName: `Match`, flex: 1,
            renderCell: (params) => {
                return params?.row?.Matches[0]?.title
            }
        },
        {
            field: 'contest', headerName: `Contests`, flex: 1,
            renderCell: (params) => {
                return params?.row?.Contests[0]?.name
            }
        },
        {
            field: 'UserPANCard', headerName: `Pancard`, flex: 1,
            renderCell: (params) => {
                return params?.row?.UserPANCard[0]?.panNumber
            }
        },
        {
            field: 'winning amount', headerName: `Winning amount`, flex: 1,
            renderCell: (params) => {
                return '₹ ' +params?.row?.totalWinningAmount
            }
        },
        {
            field: 'TDS amount', headerName: `TDS amount`, flex: 1,
            renderCell: (params) => {
                return '₹ ' +params?.row?.TDSAmount
            }
        },
        {
            field: 'after TDS amount', headerName: `After TDS amount`, flex: 1,
            renderCell: (params) => {
                return '₹ ' +params?.row?.amountAfterTDS
            }
        },
        {
            field: 'status', headerName: `Status`, flex: 1,
            renderCell: (params) => {
                return params?.row?.status
            }
        },
        {
            field: 'Created at', headerName: 'Created at', flex: 1,
            renderCell: (params) => {
                return moment(params.row.createdOn).format('DD/MM/YYYY ');
            }
        },
    ];
    
    useEffect(() => {
        getTDS(0, '')
    }, [])
    const downloadList = () => {
        getTDS(0, '', '', 100000,' ');
    }
    return (
        <>
                    <SectionTitle title='TDS' subtitle='TDS'></SectionTitle>
                    <Widget>
                        <Paper elevation={2}>
                            <Grid style={{ width: '100%', margin: '1%', padding: '1%' }}>
                                <Grid container spacing={{ xs: 2, md: 2 }}>
                                    <Grid item xs={1} sm={4} md={2}>
                                        <TextField id="standard-basic" label="Search" variant="standard" style={{ width: '100%' }} onChange={(e) => {
                                            setLabelSearch(e.target.value)
                                        }} />
                                    </Grid>
                                    <Grid item xs={1} sm={4} md={2}>
                                        <TextField
                                            id="outlined-select-request-status"
                                            style={{ marginTop: '17px', width: '90%' }}
                                            select
                                            label="Filter"
                                            value={requestStatusType}
                                            onChange={(e) => setRequestStatusType(e.target.value)}
                                            size="small" >
                                            <MenuItem value={'All'}>All</MenuItem>
                                            <MenuItem value={'Pending'}>Pending</MenuItem>
                                            <MenuItem value={'Removed'}>Removed</MenuItem>
                                        </TextField>
                                    </Grid>
                                        <Grid item xs={1} sm={4} md={2}>
                                        <Datepicker Value={startDate} onChange={(e)=>setStartDate(e.format('YYYY-MMM-DD'))}  label={'Start Date'} />
                                        </Grid>
                                        <Grid item xs={1} sm={4} md={2}>
                                        <Datepicker Value={endDate} onChange={(e)=>setEndDate(e.format('YYYY-MMM-DD'))}  label={'End Date'} />
                                        </Grid>
                                    <Grid item xs={1} sm={4} md={2}>
                                        <Button variant="contained"
                                            style={{ marginTop: '17px' }}
                                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                            startIcon={<SearchIcon />}
                                            onClick={() => getTDS(0, '')}>
                                            Search
                                        </Button>
                                        <img src={excelIcon} width='30px' height='30px' style={{
                            display: 'inline-flex',  
                            float: 'right',
                            margin: '18px 5px 0 0',
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                                    </Grid>
                                
                                </Grid>
                                
                            </Grid>
                        <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                            <DataGrid
                            style={{ width: '100%', height: '100px !important' }}
                            loading={isLoading}
                            rows={userTDSList}
                            rowCount={totalRecord}
                            pagination
                            pageSize={pageSize}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getTDS(skip, labelSearch)
                            }}
                            sortingMode="server"
                            paginationMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getTDS(0, '', sort);
                                setSortOption(sort);
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getTDS(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                        </div>
                        </Paper>
                    </Widget>
                    </>
        
            )

}

export default TDS