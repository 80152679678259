import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../components/section-title';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { IconButton, Tooltip, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ApiService from '../../../api/ApiService';
import Widget from '../../../components/widget';
import Button from '@mui/material/Button';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { ExportToCsv } from 'export-to-csv';
import SearchIcon from '@mui/icons-material/Search';
import { options, setSorting } from '../helper/helper';

const UpdateTeamData = () => {
    
    const [teamList, setTeamList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setIsFetching] = useState(false);
    const [labelSearch, setLabelSearch] = React.useState('');
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const [fileObject, SetFileObject] = useState(null);
    const [input, setInput] = React.useState('');
    
    const getTeamdata = (currentIndex, search, sort, size,csv) => {
        let payload = {}
          if (csv) {
              payload = {
                  "filterOption": labelSearch,
                  "sortOption": sort || sortOption,
                  "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size }
              }
  
          } else {
           
          }
           payload = {
              "filterOption": search || labelSearch,
              "sortOption": sort || sortOption,
              "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
          }
          setIsFetching(true);
          ApiService.post(`v1/admin/getAllTeams`, payload).then((response) => {
              if (response.status === 200) {
                  if (csv) {
                      setIsFetching(false);
                      options.filename = 'Teams'
                      const csvExporter = new ExportToCsv(options);
                      //csvExporter.generateCsv(processDataForCSV(response.data.body));
                  } else {
                      setIsFetching(false);
                      setTeamList(response.data.body);
                  }
              } else {
                  toast.error(response.data.message)
              }
              setIsFetching(false);
          }).catch((error) => {
              toast.error(error.response.data.message);
              setIsFetching(false);
          });
      }

    const setImage = (e) => {
        if (e.target.files.length) {
            let fileSize = parseFloat(e.target.files[0].size / (1024 * 1024)).toFixed(2);
            if (fileSize > 1) {
                toast.error('please select file having 1 or less than 1 mb')
                SetFileObject(null)
                return false
            } else {
                toBase64(e.target.files[0]).then(
                    data => SetFileObject(data)
                );
            }
        }

    }

    const uploadImage = (params) => {
        if (fileObject) {
            const payload = { 
                tid: params?.tid,
                newTeamName: input,
                newFlag: fileObject
            }
            ApiService.post(`v1/admin/updateTeamData`, payload).then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message)
                    getTeamdata(0, '');
                    SetFileObject(null);
                } else {
                    SetFileObject(null);
                    toast.error(response.data.message)
                }
                setIsFetching(false);
            }).catch((error) => {
                SetFileObject(null);
                toast.error(error.response.data.message);
                setIsFetching(false);
            })
        } else {
            toast.error('Please select File')
            return false
        }
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const columns = [
         { field: 'title', headerName: `Name`, flex: 1 },
         {
             field: 'abbr', headerName: 'Short Name', flex: 1
         },
        {
            field: 'name', headerName: 'New Name', flex: 1,
            renderCell: (params) => {
                 return <TextField 
                 fullWidth
                 variant="outlined"
                defaultValue={params?.row?.newTeamName}
                onChange={(e) => setInput(e.target.value)} 
               />
              
            },
            sortable: false
        },
        {
            field: 'playerImages', headerName: 'Image', flex: 1,
            renderCell: (params) => {
                    return <img src={params?.row?.newFlag} width={30} />
            },
            sortable: false
        },
        {
            field: '', headerName: 'Upload Image', flex: 1,
            renderCell: (params) => {
                if (params.row.id) {
                    return <input type='file' onChange={(e) => setImage(e)} accept="image/webp,image/png, image/gif, image/jpeg" />
                }
            },
            sortable: false
        },
        {
            field: 'action', headerName: 'Action', flex: 1, width: 50,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Upload">
                        <IconButton
                            onClick={() => uploadImage(params.row)}  >
                            <DriveFolderUploadIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
            sortable: false
        },
    ];

    const handleSearch = () => {
        getTeamdata(0, '');
    }
    useEffect(() => {
        getTeamdata(0, '');
    }, [])

    return (
        <>
        <SectionTitle title="UPDATE TEAM DATA" subtitle="update team data"></SectionTitle>
        <Widget>
            <Paper elevation={2}>
                <Grid style={{ width: '100%', paddingLeft: '1%' }}>
                    <TextField id="standard-basic" label="Keywords" variant="standard" style={{ width: '30%' }}
                        onChange={(e) => {
                            setLabelSearch(e.target.value);
                        }} /> 
                    <Button variant="contained"
                        style={{  margin: '1%' }}
                        className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                        startIcon={<SearchIcon />}
                        onClick={() => handleSearch()}
                        >
                        Search
                    </Button>
                </Grid>
                <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                    <DataGrid
                        style={{ width: '100%', height: '100px !important' }}
                        loading={isLoading}
                        rows={teamList}
                        pagination
                        pageSize={pageSize}
                        sortingMode="server"
                        onSortModelChange={(modal) => {
                            const sort = setSorting(modal);
                            getTeamdata(0, '', sort);
                            setSortOption(sort)
                        }}
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        onPageSizeChange={(newPageSize) => {
                            setPageSize(newPageSize);
                            getTeamdata(0, labelSearch, '', newPageSize)
                        }}
                        columns={columns}
                    />
                </div>
            </Paper>
        </Widget> 
        </>
    )
}
export default UpdateTeamData