import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../../components/section-title';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Widget from '../../../../components/widget';
import ApiService from '../../../../api/ApiService';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import { options, setSorting } from '../../helper/helper';
import excelIcon from '../../../../images/excel.png';
import Datepicker from '../../../../components/datepicker';
import MenuItem from '@mui/material/MenuItem';

const NewGSTReport = () => {
    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setDate(d.getDate() - 7);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const [startDate, setStartDate] = useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MMM-DD'));
    const [pageSize, setPageSize] = useState(10);
    const [gstList, setgstList] = useState([]);
    const [isLoading, setIsFetching] = useState(false);
    const [labelSearch, setLabelSearch] = useState('');
    const [sortOption, setSortOption] = useState({ updatedOn: -1 }); 
    const [total, setTotal] = useState(null)
    const processDataForCSV =(csvData)=>{
        let allCSVRows=[];
        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            let row ={};
            row.serialNum =index+1;
            row._id = csvRow._id
            row.contestantName = csvRow.Users[0].name;
            row.panNumber = csvRow.UserPANCard[0]?.panNumber;
            row.email = csvRow.Users[0].email;
            row.mobile = csvRow.Users[0].mobile;
            row.state = csvRow.Users[0].state;
            row.amount = csvRow.amount;
            row.gstAmount = csvRow.gstAmount;
            row.cgst = csvRow.cgst;
            row.sgst = csvRow.sgst;
            row.date = moment(csvRow.startDate).format('MM-DD-YYYY hh:mm:ss');
            allCSVRows.push(row)
        }
        //return csvData;
        return allCSVRows;
    
    }
    const getGSTList = (currentIndex, search, sort, size,csv) => {
        let payload = {}
 
        const user = JSON.parse(localStorage.getItem('user'));
         if (csv) {
             payload = {
                "filterOption": {
                    'keyword': labelSearch || search,"startDate": startDate,
                    "endDate": endDate,
                    // ,"month":month,
                    // "year":year,
                    "adminId":user.id
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
            }
         } else {
         }
          payload = {
                "filterOption": {
                    'keyword': labelSearch || search,"startDate": startDate,
                    "endDate": endDate,
                    // ,"month":month,
                    // "year":year,
                    "adminId":user.id
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
            }
         setIsFetching(true);
         console.log("payload", payload);
         ApiService.post(`v1/admin/getGSTReportNewRule`, payload).then((response) => {
             if (response.status === 200) {
                 if (csv) {
                     setIsFetching(false);
                     options.filename = 'GST Report'
                     const csvExporter = new ExportToCsv(options);
                     csvExporter.generateCsv(processDataForCSV(response.data.body.data));
                 } else {
                    setgstList(response.data.body.data);
                     setTotal(response.data.body.recordCount);
                     setIsFetching(false);
                 }
             } else {
                 setIsFetching(false);
                 toast.error(response.data.message)
             }
         }).catch((error) => {
             toast.error(error.response.data.message);
             setIsFetching(false);
         });
     }
     const columns = [
        {
            field: 'Users.name', headerName: `Name`, flex: 1,
            renderCell: (params) => {
                return params.row.Users[0].name;
            }
        },
        {
            field: 'Users.email', headerName: `Email`, flex: 1,
            renderCell: (params) => {
                return params.row.Users[0].email;
            }
        },
        {
            field: 'Users.mobile', headerName: `Mobile`, flex: 1,
            renderCell: (params) => {
                return params.row.Users[0].mobile;
            }
        },
        {
            field: 'Users.state', headerName: `State`, flex: 1,
            renderCell: (params) => {
                return params.row.Users[0].state;
            }
        },
        {
            field: 'UserPANCard', headerName: `Pan Card`, flex: 1,
            renderCell: (params) => {
                return params.row?.UserPANCard[0]?.panNumber;
            }
        },
        {
            field: 'amount', headerName: `Amount`, flex: 1,
            renderCell: (params) => {
                return '₹ ' + params.row.amount;
            }
        },
        {
            field: 'gstAmount', headerName: `gst Amount`, flex: 1,
            renderCell: (params) => {
                return '₹ ' + params.row.gstAmount;
            }
        },
        {
            field: 'sgst', headerName: `sgst`, flex: 1,
            renderCell: (params) => {
                return '₹ ' + params.row.sgst;
            }
        },
        {
            field: 'cgst', headerName: `cgst`, flex: 1,
            renderCell: (params) => {
                return '₹ ' + params.row.cgst;
            }
        },
        {
            field: 'createdOn', headerName: 'Date', flex: 1,
            valueGetter: (params) => {
                    return moment(params.row.startDate).format('MMM Do YYYY, h:mm:ss a');
            },
        },
    ];
    const handleSearch = () => {
        
        var date1 = moment(startDate);
        var date2 = moment(endDate);
        var result =  date2.diff(date1, 'days');
        if ((result > 31) ) {
          toast.error("Diffrence between start date and end date should not be more than 31 days")
          return;
        }
        
        getGSTList(0, '');
    }
    useEffect(() => {
        getGSTList(0, '');
    }, [])
    const downloadList = () => {
        getGSTList(0, '', '', 100000,' ');
    }
    return (
        <>
         <SectionTitle title="GST Report" subtitle="GST Report"></SectionTitle>
         <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '10px' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }} >
                            <Grid item xs={1} sm={4} md={2}>
                        <TextField id="standard-basic" label="Search by Name" variant="standard" style={{ width: '100%' }}
                            onChange={(e) => {
                                setLabelSearch(e.target.value);
                            }} />
                            </Grid>
                        <Grid item xs={1} sm={4} md={2}>
                        <Datepicker Value={startDate} onChange={(e)=>setStartDate(e.format('YYYY-MMM-DD'))}  label={'Start Date'} />
                        </Grid>
                        <Grid item xs={1} sm={4} md={2}>
                        <Datepicker Value={endDate} onChange={(e)=>setEndDate(e.format('YYYY-MMM-DD'))}  label={'End Date'} />
                        </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                        <Button variant="contained"
                            style={{  marginTop: '7%' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<SearchIcon />}
                            onClick={() => handleSearch()}>
                            Search
                        </Button>
                        </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                      <img src={excelIcon} 
                                      alt='1'
                                      width='30px' 
                                      height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: 15,
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                        </Grid>
              </Grid>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            style={{ width: '100%', height: '100px !important' }}
                            rowCount={total}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={gstList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getGSTList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                setSortOption(sort);
                                getGSTList(0, '', sort)
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getGSTList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
        )
    }
    
export default NewGSTReport