import React, { useEffect, useState } from 'react';
import MasterTable from '../../../components/MasterTable/MasterTable';
import SectionTitle from '../../../components/section-title';
import Datepicker from '../../../components/datepicker'
import Widget from '../../../components/widget';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, TextField, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import ApiService from '../../../api/ApiService';
import { toast } from 'react-toastify';
import { ModeComment } from '@mui/icons-material';
import { setSorting } from '../helper/helper';
import { DataGrid } from '@mui/x-data-grid';

const UserEnquires = () => {
    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setDate(d.getDate() - 15);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const [startDate, setStartDate] = useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MMM-DD'));
    const [isLoading, setIsFetching] = useState(false);
    const [index, setIndex] = React.useState(null);
    const [total, setTotal] = useState(null)
    const [pageSize, setPageSize] = useState(10);
    const [labelSearch, setLabelSearch] = useState('');
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const [userEnquiresList, setRemoveUserEnquiresList] = useState([]);

    const getUserEnquiresList = (currentIndex, search, sort, size) => {
        setIndex(currentIndex)
        
        const payload = {
            "filterOption": {
                "keywords": labelSearch || search,
                "startDate": startDate,
                "endDate": endDate
            },
            "sortOption": sort || sortOption,
            "pageSizeOption":{"skip": parseInt(currentIndex), "limit": size ? size : pageSize}
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getAllUserTickets`, payload).then((response) => {
            if (response.status === 200) {
                setRemoveUserEnquiresList(response.data.body.data);
                setTotal(response.data.body.recordCount);
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    
    const columns = [
        
        {
            field: 'Ticket', headerName: `Tickets`, flex: 0.1,
            renderCell: (params) => {
                return params?.row?.ticketNumber
            }
        },
        {
            field: 'name', headerName: 'Name', flex: 0.5,
            renderCell: (params) => {
                return params?.row?.Users[0]?.name
            }
        },
        {
            field: 'userEnquiry', headerName: `Enquires`, flex: 1,
            renderCell: (params) => {
                return params?.row?.userEnquiry
            }
        },
        {
            field: 'Email', headerName: 'Email', flex: 0.5,
            renderCell: (params) => {
                return params?.row?.Users[0]?.email
            }
        },
        {
            field: 'Created at', headerName: 'Created at', flex: 0.5,
            renderCell: (params) => {
                return moment(params.row.createdOn).format('DD/MM/YYYY ');
            }
        },
    ];
    const handleSearch = () => {
        getUserEnquiresList(0, '');
    }
    useEffect(() => {
        getUserEnquiresList(0, '')
    }, [])

    return (
<>
            <SectionTitle title='Enquires' subtitle='User Enquires'></SectionTitle>

            <Widget>
          
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '1%', padding: '1%' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }}>
                            <Grid item xs={1} sm={4} md={2}>
                                <TextField id="standard-basic" label="Search" variant="standard" style={{ width: '100%' }} onChange={(e) => {
                            setLabelSearch(e.target.value)
                        }} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={startDate} onChange={(e)=>setStartDate(e.format('YYYY-MMM-DD'))}  label={'Start Date'} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={endDate} onChange={(e)=>setEndDate(e.format('YYYY-MMM-DD'))}  label={'End Date'} />
                            </Grid>
                            {/* <Grid item xs={1} sm={4} md={2}>
                                <TextField
                                    style={{ marginTop: '17px', width: '90%' }}
                                    select
                                    onChange={(e) => setSearch({
                                        ...search, status: e.target.value
                                    })}
                                    SelectProps={{
                                        native: true    
                                    }}
                                    size="small" >
                                   <option value={'All'}>All</option>
                                    <option value={'Pending'}>Pending</option>
                                    <option value={'Removed'}>Removed</option>
                               
                                </TextField>
                            </Grid> */}

                            <Grid item xs={1} sm={4} md={2}>
                                <Button variant="contained"
                                    style={{ marginTop: '17px' }}
                                    className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                    startIcon={<SearchIcon />}
                                    onClick={() => getUserEnquiresList(0, '')}
                                >
                                    Search
                                </Button>
                            </Grid>
                        
                        </Grid>

                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                            <DataGrid
                            style={{ width: '100%', height: '100px !important' }}
                            loading={isLoading}
                            rows={userEnquiresList}
                            rowCount={total}
                            pagination
                            pageSize={pageSize}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getUserEnquiresList(skip, labelSearch)
                            }}
                            sortingMode="server"
                            paginationMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getUserEnquiresList(0, '', sort);
                                setSortOption(sort);
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getUserEnquiresList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                        </div>
                    {/* <MasterTable 
                            isLoading={isLoading} 
                            columns={columns}
                            list={userEnquiresList} total={total}
                            getList={getUserEnquiresList}
                            labelSearch={labelSearch}
                            /> */}
                </Paper>
            </Widget>
            </>

    )
}
export default UserEnquires