import * as React from 'react';
import SectionTitle from '../../components/section-title';
import Widget from '../../components/widget'
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import ApiService from '../../api/ApiService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

function AddContest(params) {
    const { id } = useParams();
    const [contestCategory, setContestCategory] = React.useState([]);
    const [matchList, setMatchList] = React.useState([]);
    const [error, setError] = React.useState(false)
    const navigate = useNavigate();
    const route = window.location.pathname;
    const defaultPrizeObj = {
        itemNo: 1,
        startRank: 1,
        endRank: '',
        percentageEach: '',
        priceEach: 0,
        totalWinnerPerRow: 0,
        totalPercentage: 0,
        totalPrice: '',
        isRowLocked: false
    }
    const [discount, setDiscount] = React.useState(0)
        
    const [prizeBreakDown, setPrizeBreakDown] = React.useState([defaultPrizeObj]);
    const [input, setInput] = React.useState({
        name: '',
        minEntry: 1,
        maxEntry: null,
        type: '',
        entryFee: null,
        totalWinners: '',
        commissionType: 'P',
        commissionVal: 0,
        winningAmount: 0,
        entriesPerUser: 1,
        contestCategoryId: '',
        matchId: '',
        entryFeeDiscount: 0,
        templateName: '',
        bonusPerUtilize: 0,
        isGuaranteed: false,
        allowAutoCreate: false,
        isFlexible: false,
    })


    if(discount>0){
        input.entryFeeDiscount=discount>0?input?.entryFee - ((discount/100)*input?.entryFee):input?.entryFee;
    }
    const resetPrizeBreakup = () => {
        setPrizeBreakDown([defaultPrizeObj]);
        return false;
    }

    const setFreeContestPrizeBreakup = () => {
        let freeContestPrizeBrk = defaultPrizeObj;
        freeContestPrizeBrk.startRank = 1;
        freeContestPrizeBrk.endRank = 1;
        freeContestPrizeBrk.percentageEach = 100;
        freeContestPrizeBrk.totalPrice = 0;
        setPrizeBreakDown([freeContestPrizeBrk])
    }

    const calculateWinningAmount = () => {
        try {

            if (!input.commissionType || isNaN(input.entryFee) || isNaN(input.maxEntry || isNaN(input.totalWinners)))
                return;

            if (parseInt(input.totalWinners) > parseInt(input.maxEntry)) {
                toast.error('Total winner cannot be greater than max entries')
                return;
            }
            let totalEarning = input.maxEntry * input.entryFee;
            let AdminComm = 0;
            let winAmt = 0;
            if (input.commissionType == 'P') {
                AdminComm = (totalEarning * input.commissionVal) / 100;
                winAmt = totalEarning - AdminComm;
            } else {
                if (input.commissionVal > totalEarning) {
                    toast.error('Winning amount canot be greater than earning amount')
                    return
                }
                AdminComm = input.commissionVal;
                winAmt = totalEarning - input.commissionVal;
            }

            if (!isNaN(winAmt)) {
                setInput({
                    ...input, winningAmount: winAmt
                });
            }
            return false;
        }
        catch {

        }
    };

    function compare(a, b) {
        if (a.itemNo < b.itemNo) {
            return -1;
        }
        if (a.itemNo > b.itemNo) {
            return 1;
        }
        return 0;
    }

    const calculatePrizeBreakup = () => {
        if (prizeBreakDown.length > 0) {
            let totalPrice = 0;
            let totalPercentage = 0;
            prizeBreakDown.forEach(prizeLine => {
                if (!prizeLine.percentageEach || isNaN(prizeLine.percentageEach)) {
                    return;
                }
                prizeLine.totalWinnerPerRow = prizeLine.endRank - prizeLine.startRank + 1;

                prizeLine.priceEach = Math.ceil(((parseFloat(input.winningAmount) / 100) * parseFloat(prizeLine.percentageEach))).toString();
                let rowTotalPrice = (parseFloat(prizeLine.totalWinnerPerRow) * prizeLine.priceEach);
                totalPrice = totalPrice + rowTotalPrice;
                totalPercentage = totalPercentage + ((rowTotalPrice * 100) / parseFloat(input.winningAmount));
                prizeLine.totalPrice = totalPrice;
                prizeLine.totalPercentage = totalPercentage.toFixed(2);
                if (parseFloat(totalPercentage).toFixed(0) > 100) {
                    toast.error('Total winning amount cannot be more than 100%');
                    return;
                }
            });
        }
    }


    const addNextRow = () => {
        if (isNaN(input.winningAmount)) {
            toast.error('Please enter proper contest details')
            return;
        }
        let isValidEntry = true;
        let errorMessage = '';
        let totalPrice = 0;
        let totalPercentage = 0;
        for (let i = 0; i < prizeBreakDown.length; i++) {
            let prizeLine = prizeBreakDown[i];
            if (!prizeLine.percentageEach || prizeLine.percentageEach === 0) {
                isValidEntry = false;
                errorMessage = 'Please enter percentage each for row ' + prizeLine.itemNo;
            }
            else if (parseInt(prizeLine.endRank) < parseInt(prizeLine.startRank)) {
                errorMessage = 'Start Rank should be less than end rank for row ' + prizeLine.itemNo;
                isValidEntry = false;
            }
            prizeLine.totalWinnerPerRow = prizeLine.endRank - prizeLine.startRank + 1;
            prizeLine.priceEach = ((parseFloat(input.winningAmount) / 100) * parseFloat(prizeLine.percentageEach)).toString();
            let rowTotalPrice = (parseFloat(prizeLine.totalWinnerPerRow) * prizeLine.priceEach);
            totalPrice = totalPrice + rowTotalPrice;
            totalPercentage = totalPercentage + ((rowTotalPrice * 100) / parseFloat(input.winningAmount));
            prizeLine.totalPrice = totalPrice;
            prizeLine.totalPercentage = totalPercentage;
            if (parseFloat(totalPercentage).toFixed(0) >= 100) {
                errorMessage = 'Total winning amount cannot be more than 100%';
                isValidEntry = false;
            }
        };
        if (!isValidEntry) {
            toast.error(errorMessage)
            return;
        }
        let newRow = defaultPrizeObj;
        newRow.itemNo = prizeBreakDown.length + 1;
        prizeBreakDown.sort(compare);
        newRow.startRank = prizeBreakDown[prizeBreakDown.length - 1].endRank + 1;
        newRow.isRowLocked = false;
        prizeBreakDown[prizeBreakDown.length - 1].isRowLocked = true;
        setPrizeBreakDown([...prizeBreakDown, newRow])
    }
    const handleChange = (index, event) => {
        const values = [...prizeBreakDown];
        values[index][event.target.name] = parseFloat(event.target.value);
        setPrizeBreakDown(values);
        calculatePrizeBreakup();
    }
    const getContestCategory = () => {
        ApiService.post("v1/admin/getContestCategory").then((response) => {
            if (response.status === 200) {
                setContestCategory(response.data.body);
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }
    const getMatchList = () => {
        ApiService.post("v1/admin/getMatchList").then((response) => {
            if (response.status === 200) {
                setMatchList(response.data.body);
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }
    const isValidData = () => {

        if (input.matchId === '') {
            toast.error('Please select match')
            return false;
        }
        else if (input.name === '') {
            toast.error('Please enter contest name')
            return false;
        }
        else if (input.type === '') {
            toast.error('Please select type')
            return false;
        }
        else if (input.minEntry === '') {
            toast.error('Please enter minimum entry')
            return false;
        }
        else if (input.maxEntry === '') {
            toast.error('Please enter max entry')
            return false;
        }
        else if (input.entryFee === '') {
            toast.error('Please enter entry fee')
            return false;
        }
        else if (input.totalWinners === '') {
            toast.error('Please enter total winners')
            return false;
        }
        else if (input.commissionType === '') {
            toast.error('Please enter commision type')
            return false;
        }
        else if ((!input.winningAmount || isNaN(input.winningAmount)) && (input.type!=='F') ) {
            toast.error('Please enter contest details')
            return false;
        }
        else if (input.entriesPerUser === '') {
            toast.error('Please enter entries per user')
            return false;
        }
        else if (input.commissionVal === '') {
            toast.error('Please enter commission Value')
            return false;
        }
        else {
            return true;
        }

    }
    let totalPrizeWinners = 0;
    prizeBreakDown.forEach(x => {
        totalPrizeWinners += (x.endRank - x.startRank) + 1;
    });
    const isValidPrizeBreakup = () => {

        if (input.type === 'F') {
            return true;
        }

        if (prizeBreakDown.length === 0) {
            toast.error('Invalid prize breakup');
            return false;
        }
        if (totalPrizeWinners > input.totalWinners) {
            toast.error('Number of winners are greater than total winners');
            return false;
        }
        if (parseFloat(prizeBreakDown[prizeBreakDown.length - 1].totalPercentage).toFixed(0) !== '100') {
            toast.error('Total prize distribution must be 100%');
            return false;
        }
        return true;
    }

    const submitForm = () => {
        if (!isValidData() || !isValidPrizeBreakup()) {
            return;
        }
        const prize = {
            prizeBreakUp: prizeBreakDown
        }
        const user = JSON.parse(localStorage.getItem('user'));
        const payload = Object.assign(input, prize, { createdBy: user.id });
        if (id) {
            
            ApiService.post(`v1/admin/UpdateContest/${id}`, payload).then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                    navigate(`/admin/contest-list`);
                } else {
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        } else {
            ApiService.post("v1/admin/createContest", payload).then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                    navigate(`/admin/contest-list`);
                } else {
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }

    const [searchParams] = useSearchParams();
    let templateId = null

    React.useEffect(() => {
        templateId = searchParams.get('template');
        getContestCategory();
        getMatchList();
        if (id || templateId) {
            getDetails();
        }
    }, [])

 

    const getFlexiblePrizebreakup = (event) => {
        if(event === false) return;
        
        const user = JSON.parse(localStorage.getItem('user'));
        const payload ={
            "spotSize" :parseInt(input.maxEntry),
            "entryFee":parseInt(input.entryFee),
            "winningAmount":parseFloat(input.winningAmount),
            "userId" :user.id
        }
        
        ApiService.post("v1/admin/getFlexiblePrizeBreakupTemplate",payload).then((response) => {
            
            if (response.status === 200) {            
             setPrizeBreakDown(response.data.body.prizeBreakUp)
                
            } else {
                toast.error('Something Went Wrong')
            }
        }).catch((error) => {
            console.log(error.response);
            toast.error(error.response.data.message);
        })
    }


    const getDetails = () => {
        let contestId = null;
        let getContestAPIEndPoint = '';
        if (templateId) {             
            getContestAPIEndPoint = `v1/admin/getContestByIdForTemplate/${templateId}`;
            contestId = templateId;
        }
        else if (id) {
            contestId = id;
            getContestAPIEndPoint = `v1/admin/getContestById/${contestId}`;            
        }
        ApiService.post(getContestAPIEndPoint).then((response) => {
            if (response.status === 200) {
                if (contestId = templateId) {
                    setInput(response.data.body.templateName = '');
                    setInput(response.data.body.matchId = '');
                    setInput(response.data.body);
                } else {
                    setInput(response.data.body);
                }           
                setPrizeBreakDown(response.data.body.prizeBreakUp.length === 0 ? [
                    {
                        itemNo: '',
                        startRank: 1,
                        endRank: '',
                        percentageEach: '',
                        priceEach: '',
                        totalPercentage: '',
                        totalPrice: ''
                    }
                ] : response.data.body.prizeBreakUp);
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }
    const changeType = (event) => {
        if (event.target.value === 'F') {
            setInput({
                ...input, entryFee: 0, totalWinners: 1, commissionVal: 0, type: event.target.value
            });
            setFreeContestPrizeBreakup();
        } else {
            setInput({
                ...input, type: event.target.value
            });
            resetPrizeBreakup();
        }
    }  
    return (
        <>
            <SectionTitle title="Contest" subtitle="Create / Edit Contest" />
            <Widget>
                <Grid>
                    <form>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Contest Category"
                                    style={{ width: '100%' }}
                                    value={input.contestCategoryId}
                                    onChange={(event) => {
                                        setInput({
                                            ...input, contestCategoryId: event.target.value
                                        })
                                    }}
                                >
                                    {contestCategory.map((e) => (
                                        <MenuItem value={e.id} key={e.id}>
                                            {e.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {  
                                (input.matchDetails?.status === "1" || route.includes('create-contest')) && 
                                    <TextField
                                    select
                                    className='input-label'
                                    label="--Select Contest Match--"
                                    style={{ width: '100%' }}
                                    value={input.matchId}
                                    error={error}
                                    onChange={(event) => {
                                        setInput({
                                            ...input, matchId: event.target.value
                                        })
                                    }}
                                >
                                    {matchList.map((option) => (
                                        <MenuItem value={option.match_id} key={option.match_id}>
                                            {option.teama[0].short_name} vs {option.teamb[0].short_name}   on   {option.date_start}
                                        </MenuItem>
                                    ))}
                                </TextField>}
                                { (input.matchDetails?.status !== "1" &&  route.includes('create-contest')===false) && 
                                <TextField
                                className='input-label'
                                label=" "
                                style={{ width: '100%' }}
                                    value={input.matchDetails?.title}>
                                </TextField>
                                } 
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Contest Name"
                                    fullWidth
                                    name='name'
                                    value={input.name}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, name: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    select
                                    label="Contest Type"
                                    style={{ width: '100%' }}
                                    value={input.type}
                                    onChange={(event) => changeType(event)}
                                >
                                    <MenuItem value='P'> Paid </MenuItem>
                                    <MenuItem value='F'> Free </MenuItem>
                                    <MenuItem value='D'> Discounted </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Min Entry"
                                    fullWidth
                                    name='minEntry'
                                    value={input.minEntry}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    inputProps={{ min: 0 }}
                                    type='number'
                                    onChange={(event) => {
                                        setInput({
                                            ...input, minEntry: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Max Entry"
                                    fullWidth
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    type='number'
                                    value={input.maxEntry ? input.maxEntry : "Max entry"}
                                    name='maxEntry'
                                    onBlur={() => (calculateWinningAmount())}
                                    inputProps={{ min: 0 }}
                                    onChange={(event) => {
                                        setInput({
                                            ...input, maxEntry: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Entry Fee"
                                    fullWidth
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    type='number'
                                    disabled={input.type === 'F' ? true : false}
                                    value={input.entryFee || 0 ? input.entryFee : "Entry fee"}
                                    inputProps={{ min: 0 }}
                                    onBlur={() => (calculateWinningAmount())}
                                    name='entryFee'
                                    onChange={(event) => {
                                        setInput({
                                            ...input, entryFee: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Total Winners"
                                    fullWidth
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    type='number'
                                    inputProps={{ min: 0 }}
                                    disabled={input.type === 'F' ? true : false}
                                    value={input.totalWinners}
                                    name='totalWinners'
                                    onChange={(event) => {
                                        setInput({
                                            ...input, totalWinners: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    select
                                    label="Admin Commission Type"
                                    style={{ width: '100%' }}
                                    value={input.commissionType}
                                    onChange={(event) => {
                                        setInput({
                                            ...input, commissionType: event.target.value
                                        })

                                    }}
                                >
                                    <MenuItem value='P'> Percentage </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Admin Comission"
                                    fullWidth
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    type='number'
                                    value={input.commissionVal}
                                    disabled={input.type === 'F' ? true : false}
                                    inputProps={{ min: 0 }}
                                    name='commissionVal'
                                    onBlur={() => { calculateWinningAmount(); }}
                                    onChange={(event) => {
                                        setInput({
                                            ...input, commissionVal: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    disabled={input.type==="D"?false:true}
                                    label="Winning Amount"
                                    style={{ backgroundColor: '#e4e7ea' }}
                                    value={input.winningAmount}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Entries Per User"
                                    fullWidth
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    type='number'
                                    value={input.entriesPerUser}
                                    name='entriesPerUser'
                                    onChange={(event) => {
                                        setInput({
                                            ...input, entriesPerUser: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Bonus Allocation (%)"
                                    fullWidth
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    type='number'
                                    inputProps={{ min: 0 }}
                                    value={input.bonusPerUtilize}
                                    name='bonusPerUtilize'
                                    onChange={(event) => {
                                        setInput({
                                            ...input, bonusPerUtilize: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    control={<Checkbox color="primary" name="isGuaranteed" onChange={(event) => {
                                        setInput({
                                            ...input, isGuaranteed: event.target.checked
                                        })
                                    }} checked={input.isGuaranteed} />}
                                    label="Confirmed winning"
                                    disabled={(input.allowAutoCreate||input.isFlexible) ? true : false}
                                />
                                <FormControlLabel
                                    control={<Checkbox color="primary" name="allowAutoCreate" onChange={(event) => {
                                        setInput({
                                            ...input, allowAutoCreate: event.target.checked
                                        })
                                    }} checked={input.allowAutoCreate} />}
                                    label="Auto Create"
                                    disabled={(input.isGuaranteed||input.isFlexible||input.type==="D") ? true : false}
                                />  
                                <FormControlLabel
                                  
                                control={<Checkbox color="primary" name="isFlexible" onChange={(event) => {
                                    setInput({
                                        ...input, isFlexible: event.target.checked
                                    })
                                }} 
                                onClick={(event)=>getFlexiblePrizebreakup(event.target.checked)}
                                checked={input.isFlexible} />}
                                label="Flexible contest"
                                disabled={((input.isGuaranteed||input.allowAutoCreate) || ( input.maxEntry < 1  || input.entryFee < 1)||input.type!=="D") ? true : false}
                            />
                            </Grid>                       
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Template Name"
                                    fullWidth
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    type='string'
                                    value={input.templateName}
                                    name='templateName'
                                    onChange={(event) => {
                                        setInput({
                                            ...input, templateName: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                          {input.type==="D"&&  <Grid item xs={12} md={6}>
                                <TextField
                                    label="Discount in percentage"
                                    fullWidth
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    type='number'
                                    value={input.discount}
                                    name='discount'
                                    onChange={(event) => {
                                         if(event.target.value<101){
                                        setDiscount(event.target.value)
                                    }else{
                                        
                                            toast.error('Discount should be within 100') 
                                        
                                    }
                                    }}
                                />
                                 <div>New Entry fee : {discount>0?input.entryFee - ((discount/100)*input.entryFee):input.entryFee}</div>
                            </Grid>}
                        
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} style={{ marginTop: '10px', color: '#63c2de' }}>
                                <Typography variant="h6" gutterBottom className="float-start">
                                    Prize Breakdown
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="prize table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell align="right">Start Rank</TableCell>
                                                <TableCell align="right">End Rank</TableCell>
                                                <TableCell align="right">Percentage (each)</TableCell>
                                                <TableCell align="right">Price (each)</TableCell>
                                                <TableCell align="right">Total Percentage</TableCell>
                                                <TableCell align="right">Total Price</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {prizeBreakDown.map((prizeBreakDown, index) => (
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    key={index}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        name='itemNo'
                                                        value={prizeBreakDown.itemNo}
                                                    >{prizeBreakDown.itemNo}</TableCell>
                                                    <TableCell align="right">
                                                        <TextField
                                                            style={{ backgroundColor: '#e4e7ea' }}
                                                            disabled={true}
                                                            autoComplete="cc-exp"
                                                            variant="outlined"
                                                            type='number'
                                                            name='itemNo'
                                                            value={prizeBreakDown.startRank}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <TextField
                                                            style={{}}
                                                            name='endRank'
                                                            autoComplete="cc-exp"
                                                            variant="outlined"
                                                            type='number'
                                                            inputProps={{ min: 0 }}
                                                            value={prizeBreakDown.endRank}
                                                            onChange={(event) => {
                                                                handleChange(index, event)

                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <TextField
                                                            style={{}}
                                                            name='percentageEach'
                                                            autoComplete="cc-exp"
                                                            variant="outlined"
                                                            type='number'
                                                            inputProps={{ min: 0.0 }}
                                                            value={prizeBreakDown.percentageEach}
                                                            onChange={(event) => {
                                                                handleChange(index, event)

                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <TextField
                                                            style={{ backgroundColor: '#e4e7ea' }}
                                                            disabled={true}
                                                            autoComplete="cc-exp"
                                                            variant="outlined"
                                                            type='number'
                                                            name='priceEach'
                                                            value={prizeBreakDown.priceEach}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <TextField
                                                            style={{ backgroundColor: '#e4e7ea' }}
                                                            disabled={true}
                                                            autoComplete="cc-exp"
                                                            variant="outlined"
                                                            type='number'
                                                            name='totalPercentage'
                                                            value={prizeBreakDown.totalPercentage}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <TextField

                                                            style={{ backgroundColor: '#e4e7ea' }}
                                                            disabled={true}
                                                            autoComplete="cc-exp"
                                                            variant="outlined"
                                                            type='number'
                                                            name='totalPrice'
                                                            value={prizeBreakDown.totalPrice}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <div className='w-full'>
                                        {route.includes('view-contest') ? '' :
                                            <div className="flex items-start justify-start space-x-4 p-4">
                                                <Button variant="contained"
                                                    size="medium"
                                                    type='Button'
                                                    disabled={input.type==='F'?true:false}
                                                    className='btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded btn-icon'
                                                    onClick={addNextRow}>
                                                    Add Row
                                                </Button>
                                                <Button variant="contained"
                                                    size="medium"
                                                    type='Button'
                                                    disabled={input.type === 'F' ? true : false}
                                                    className='btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded btn-icon'
                                                    onClick={() => { resetPrizeBreakup() }}>
                                                    Reset
                                                </Button>
                                            </div>
                                        }
                                        <div className="flex items-end justify-end space-x-4 p-4">
                                            { (input.matchDetails?.status === "1" || route.includes('create-contest'))  &&
                                                <Button className='btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded btn-icon'
                                                    variant="contained"
                                                    color="success"
                                                    onClick={submitForm}
                                                    size="medium" >
                                                    Submit
                                                </Button>
                                            }
                                            <Button className='btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded btn-icon'
                                                variant="contained"
                                                onClick={() => { navigate(`/admin/contest-list`) }}
                                                size="medium" >
                                                <FiArrowLeft className="stroke-current" />
                                                <span className="mr-2">Back</span>
                                            </Button>
                                        </div>
                                    </div>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Widget>
        </>

    );
}
export default AddContest