
import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { Button, colors, Grid, InputLabel, MenuItem, Paper, TextField } from '@mui/material';
import { Search, RestartAlt } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../../components/section-title';
import Widget from '../../../components/widget';
import { toast } from 'react-toastify';
import ApiService from '../../../api/ApiService';
import Datepicker from '../../../components/datepicker';
import SearchIcon from '@mui/icons-material/Search';
import { setSorting, options } from '../helper/helper';
import { ExportToCsv } from 'export-to-csv';
import RejectWithdrawRequest from '../../../components/dialogs/RejectWithdrawRequest';
import excelIcon from '../../../images/excel.png';
import { useParams } from 'react-router-dom';

export default function TDSbyline() {
  let date= new Date();
  const Datefind=()=>{

      var a = moment(date).format('MMM DD, YYYY ')
      var d = new Date(a);

      return d.setDate(d.getDate() - 365);
     }
    
  let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
  const [pageSize, setPageSize] = React.useState(10);
  const [requestStatusType, setRequestStatusType] = React.useState("Pending");
  const [startDate, setStartDate] = React.useState(moment(finaldate).format('YYYY-MMM-DD'));
  const [endDate, setEndDate] = React.useState(moment(new Date()).format('YYYY-MMM-DD'));
  const [labelSearch, setLabelSearch] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [listCount, setListCount] = React.useState(null);
  const [isLoading, setIsFetching] = React.useState(false);
  const [sortOption, setSortOption] = useState({ updatedOn: -1 });
  const [reason, setReason] = useState(null);
  const [openReject, setOpenReject] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const processDataForCSV =(csvData)=>{

    let allCSVRows=[];
    for (let index = 0; index < csvData.length; index++) {
        let csvRow = csvData[index];
        let row ={};
        row.serialNum =index+1;
        row.name = csvRow.Users[0]?.name;
        row.email = csvRow.Users[0].email;
        // row.totalWinningAmount = csvRow.totalWinningAmount;
        row.TDSAmount = csvRow.TDSAmount;
        // row.amountAfterTDS = csvRow.amountAfterTDS;
        row.status = csvRow.status;
        // row.panNumber = csvRow.UserPANCard[0].panNumber;
        row.name = csvRow.Contests[0].name;
        row.title = csvRow.Matches[0].title;
        //  csvRow.panNumber = csvRow.UserPANCard[0]?.panNumber;
        //  csvRow.name = csvRow.Users[0].name;
        //  csvRow.contestantAmount = csvRow.amount;
        //  csvRow.amount = csvRow.UserWithdrawalRequest[0]?.amount;
        //  csvRow.adminComment = csvRow.UserWithdrawalRequest[0]?.adminComment
        //  delete csvRow.UserPANCard;
        //  delete csvRow.UserWithdrawalRequest;
        //  delete csvRow.Users;
        //  delete csvRow.tdsReferenceId;
         allCSVRows.push(row)
    }
    // return csvData;
    return allCSVRows;

  }

  const getRequests = (currentIndex, search,sort, size,csv) => {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log("params?.id", params?.id, user?.id);
    let payload = {}

    if (csv) {
      payload = 
      {
        "userId": params?.id ,//'64b7dfb3051b2e53ffe433ad'
        "filterOption": { 
          "startDate": startDate,
          "endDate": endDate, 
          "adminId":user?.id
        },
        "sortOption": sort || sortOption,
        "pageSizeOption": { "skip": currentIndex, "limit": size ? size : pageSize }
      }
    // {
    //     "filterOption": {   
    //       "startDate": "2023-Jul-31",
    //       "endDate": "2023-Aug-07",
    //       "adminId": "6348199057403b9afa691a64"
    //     },
    //     "userId":"62d935937c2918ac9eb0786e"    ,
    //     "sortOption": { "updatedOn": -1 },
    //     "pageSizeOption": { "skip": 0, "limit": 10 }
    //   }
    } else {

    } 
    payload = 
    {
        "userId": params?.id, //'64b7dfb3051b2e53ffe433ad'
      "filterOption": { 
        "startDate": startDate,
        "endDate": endDate, 
        "adminId":user?.id
      },
      "sortOption": sort || sortOption,
      "pageSizeOption": { "skip": currentIndex, "limit": size ? size : pageSize }
    }
    // {
    //     "filterOption": {   
    //       "startDate": "2023-Jul-31",
    //       "endDate": "2023-Aug-07",
    //       "adminId": "6348199057403b9afa691a64"
    //     },
    //     "userId":"62d935937c2918ac9eb0786e"    ,
    //     "sortOption": { "updatedOn": -1 },
    //     "pageSizeOption": { "skip": 0, "limit": 10 }
    //   }
    setIsFetching(true);
    ApiService.post(`v1/admin/getUserTDSLines`, payload).then((response) => {
      if (response.status === 200) {
        if (csv) {
          setIsFetching(false);
          options.filename = 'TDS Line'
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(processDataForCSV(response.data.body.data));
      } else {
        setRows(response.data.body.data);
        setListCount(response.data.body.recordCount);
      }
     } else {
        toast.error(response.data.message)
      }
      setIsFetching(false);
    }).catch((error) => {
      toast.error(error.response.data.message);
      setIsFetching(false);
    });
  }

  const columns = [
    { field: 'createdOn', headerName: `Created On`, flex: 1 ,
    renderCell: (params) => {
        return moment(params.row.createdOn).format('MMM Do YYYY, h:mm:ss a');
    },
},
    {
      field: 'Users[0].name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => { 
        return params?.row?.Users[0]?.name
    }
    },
    {
      field: 'users[0]?.email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params) => {
        return params?.row?.Users[0]?.email
    }
    },
    // {
    //   field: 'totalWinningAmount',
    //   headerName: 'total Winning Amount',
    //   flex: 1,
    //   renderCell: (params) => {
    //     return '₹ ' + params?.row?.totalWinningAmount
    // }
    // },
    {
      field: 'TDSAmount',
      headerName: 'TDS Amount',
      flex: 1,
      renderCell: (params) => {
        return '₹ ' + params?.row?.TDSAmount
    }
    },
    // {
    //   field: 'amountAfterTDS',
    //   headerName: 'amount After TDS',
    //   flex: 1,
    //   renderCell: (params) => {
    //     return '₹ ' + params?.row?.amountAfterTDS
    // }
    // },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return params?.row?.status
    }
    },
    // {
    //   field: 'UserPANCard',
    //   headerName: 'PANCard',
    //   flex: 1,
    //   renderCell: (params) => {
    //     return  params?.row?.UserPANCard[0].panNumber
    // }
    // },
    {
      field: 'name',
      headerName: 'Contest name',
      flex: 1,
      renderCell: (params) => {
        return params?.row?.Contests[0].name
    }
    },
    {
      field: 'title',
      headerName: 'Match title',
      flex: 1,
      renderCell: (params) => {
        return params?.row?.Matches[0].title
    }
    },
  ];
  
  useEffect(() => {
    getRequests(0, "");
  }, [])

  const downloadList = () => {
    getRequests(0, '', '', 100000,' ');
}

    return (
        <div>
        <SectionTitle title="TDS by line" subtitle="TDS by line" />
        <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '10px' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }} >
                            <Grid item xs={1} sm={4} md={2}>
                                      <img src={excelIcon} 
                                      alt='1'
                                      width='30px' 
                                      height='30px' style={{
                            display: 'inline-flex',
                            float: 'left',
                            margin: 15,
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                        </Grid>
                        </Grid>
                        </Grid>
          <div style={{ minHeight: 700, height: 600, width: '100%' }}>
            <DataGrid
              rows={rows}
              loading={isLoading}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 50, 100]}
              onPageChange={(index) => { 
                let skip =pageSize*index;
                getRequests(skip, labelSearch) }}
              pagination
              sortingMode="server"
              onSortModelChange={(modal) => {
                const sort = setSorting(modal);
                getRequests(0, '', sort);
                setSortOption(sort);
              }}
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              disableSelectionOnClick
              paginationMode="server"
              rowCount={listCount}
              onPageSizeChange={(newPageSize) => {
                setPageSize(newPageSize);
                getRequests(0, labelSearch, '', newPageSize);
              }}
              getRowId={(row) => row.id}
              columns={columns}
            />
          </div>
          </Paper>
          </Widget>
            </div>
    )

}