import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../../components/section-title';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Widget from '../../../../components/widget';
import ApiService from '../../../../api/ApiService';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import { options, setSorting } from '../../helper/helper';
import excelIcon from '../../../../images/excel.png';
import Datepicker from '../../../../components/datepicker';
import MenuItem from '@mui/material/MenuItem';

const EarningReport = () => {
    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setDate(d.getDate() - 7);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const [startDate, setStartDate] = useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MMM-DD'));
    const [pageSize, setPageSize] = useState(10);
    const [EarningList, setEarningList] = useState([]);
    const [isLoading, setIsFetching] = useState(false);
    const [labelSearch, setLabelSearch] = useState('');
    const [sortOption, setSortOption] = useState({ startDate: -1 });
    const [total, setTotal] = useState(null)
    const processDataForCSV =(csvData)=>{

        let allCSVRows=[];
        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            let row ={};
            row.serialNum =index+1;
            row.contestId = csvRow._id;
            row.contestName = csvRow.contestName;
            row.netPlatformFees = csvRow.netPlatformFees;
            row.actualSpots = csvRow.actualSpots;
            row.entryFee = csvRow.entryFee;
            row.totalFeeRecieveable = csvRow.totalFeeRecieveable;
            row.ContestTotalFeeRecieveable = csvRow.ContestTotalFeeRecieveable;
            row.bonusPerUtilize = csvRow.bonusPerUtilize;
            row.grossPlatformFees = csvRow.grossPlatformFees;
            row.totalGSTOfContest = csvRow.totalGSTOfContest;
            row.grossRevenue = csvRow.grossRevenue;
            row.netRevenueBeforeGst = csvRow.netRevenueBeforeGst;
            row.netRevenueAfterGst = csvRow.netRevenueAfterGst;
            allCSVRows.push(row)
        }
        // return csvData;
        return allCSVRows;
    
    }
    const getEarningList = (currentIndex, search, sort, size,csv) => {
        let payload = {}
 
        const user = JSON.parse(localStorage.getItem('user'));
         if (csv) {
             payload = {
                "filterOption": {
                    'keyword': labelSearch || search,"startDate": startDate,
                    "endDate": endDate
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
            }
         } else {
         }
          payload = {
                "filterOption": {
                    'keyword': labelSearch || search,"startDate": startDate,
                    "endDate": endDate
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
            }
         setIsFetching(true);
         ApiService.post(`v1/admin/getEarningReport`, payload).then((response) => {
             if (response.status === 200) {
                 if (csv) {
                     setIsFetching(false);
                     options.filename = 'Earning Report'
                     const csvExporter = new ExportToCsv(options);
                     csvExporter.generateCsv(processDataForCSV(response.data.body.data));
                 } else {
                    setEarningList(response.data.body.data);
                     setTotal(response.data.body.recordCount);
                     setIsFetching(false);
                 }
             } else {
                 setIsFetching(false);
                 toast.error(response.data.message)
             }
         }).catch((error) => {
             toast.error(error.response.data.message);
             setIsFetching(false);
         });
     }
     const columns = [
        {
            field: 'contestId', headerName: 'Contest Id', flex: 1,
            valueGetter: (params) => {
                    return params.row?.contestId;
            },
        },
        {
            field: 'MatchES.title', headerName: `title`, flex: 1,
            renderCell: (params) => {
                return params.row.MatchES[0].title;
            }
        },
        {
            field: 'contestName', headerName: `Contest Name`, flex: 1,
            renderCell: (params) => {
                return params.row?.contestName;
            }
        },
        {
            field: 'commissionVal', headerName: `Platform Fees in %`, flex: 1,
            renderCell: (params) => {
                return  params.row?.commissionVal;
            }
        },
        {
            field: 'maxEntry', headerName: `Max Entry`, flex: 1,
            renderCell: (params) => {
                return params.row?.maxEntry;
            }
        },
        {
            field: 'actualSpots', headerName: `Actual Spots`, flex: 1,
            renderCell: (params) => {
                return params.row?.actualSpots;
            }
        },
        {
            field: 'entryFee', headerName: `Entry Fee`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row?.entryFee;
            }
        },
        {
            field: 'totalFeeRecieveable', headerName: `Total Fee Recieveable`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row?.totalFeeRecieveable;
            }
        }, 
        {
            field: 'ContestTotalFeeRecieveable', headerName: `Contest Total Fee Recieveable`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row?.ContestTotalFeeRecieveable;
            }
        }, 
        {
            field: 'bonusPerUtilize', headerName: `Bonus Utilize`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row?.bonusPerUtilize;
            }
        },
        {
            field: 'grossRevenue', headerName: `Gross Revenue`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row?.grossRevenue;
            }
        },
        {
            field: 'grossPlatformFees', headerName: `Gross Platform Fees`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row?.grossPlatformFees;
            }
        },
        {
            field: 'netPlatformFees', headerName: `Net Platform Fees`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row?.netPlatformFees;
            }
        }, 
        {
            field: 'totalGSTOfContest', headerName: `Total GST Of Contest`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row?.totalGSTOfContest;
            }
        }, 
        {
            field: 'netRevenueBeforeGst', headerName: `Net Revenue Before Gst`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row?.netRevenueBeforeGst;
            }
        }, 
        {
            field: 'netRevenueAfterGst', headerName: `Net Revenue After Gst`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row?.netRevenueAfterGst;
            }
        }, 
        
    ];
    const handleSearch = () => {
        getEarningList(0, '');
    }
    useEffect(() => {
        getEarningList(0, '');
    }, [])
    const downloadList = () => {
        getEarningList(0, '', '', 100000,' ');
    }
    return (
        <>
         <SectionTitle title="Earning Report" subtitle="Earning REPORT"></SectionTitle>
         <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '10px' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }} >
                            <Grid item xs={1} sm={4} md={2}>
                        <TextField id="standard-basic" label="Search by Name" variant="standard" style={{ width: '100%' }}
                            onChange={(e) => {
                                setLabelSearch(e.target.value);
                            }} />
                            </Grid>
                        <Grid item xs={1} sm={4} md={2}>
                        <Datepicker Value={startDate} onChange={(e)=>setStartDate(e.format('YYYY-MMM-DD'))}  label={'Start Date'} />
                        </Grid>
                        <Grid item xs={1} sm={4} md={2}>
                        <Datepicker Value={endDate} onChange={(e)=>setEndDate(e.format('YYYY-MMM-DD'))}  label={'End Date'} />
                        </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                        <Button variant="contained"
                            style={{ marginTop: '7%' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<SearchIcon />}
                            onClick={() => handleSearch()}>
                            Search
                        </Button>
                        </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                      <img src={excelIcon} 
                                      alt='1'
                                      width='30px' 
                                      height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: 15,
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                        </Grid>
              </Grid>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            style={{ width: '100%', height: '100px !important' }}
                            rowCount={total}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={EarningList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getEarningList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                setSortOption(sort);
                                getEarningList(0, '', sort)
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getEarningList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
        )
    }
    
export default EarningReport