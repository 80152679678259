import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { ExportToCsv } from 'export-to-csv';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import Widget from '../../../components/widget'
import TextField from '@mui/material/TextField';
import ApiService from '../../../api/ApiService';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CampaignIcon from '@mui/icons-material/Campaign';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SectionTitle from '../../../components/section-title';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { options, setSorting } from '../helper/helper';
import excelIcon from '../../../images/excel.png';
import Datepicker from '../../../components/datepicker';
export default function FetchSeriesList() {
    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setDate(d.getDate() - 15);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const [pageSize, setPageSize] = React.useState(10);
    const [matchList, setMatchList] = React.useState([]);
    const [isLoading, setIsFetching] = React.useState(false);
    const [labelSearch, setLabelSearch] = React.useState('');
    const [listCount, setListCount] = React.useState(null);
    const [filterStatus, setFilterStatus] = React.useState('1');
     const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MMM-DD'));
    
    const processDataForCSV =(csvData)=>{

        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            if(Array.isArray(csvRow.competition)) {
               csvRow.Title = csvRow.competition[0].title;
               delete csvRow.competition;
               delete csvRow.venue;
            }
                         
        }
        return csvData;
    
    }
    const getMatchList = (currentIndex, search, sort, size,csv) => {
        let payload = {}
       
        if (csv) {
            payload = {
                "filterOption": {
                    'keywords': labelSearch,
                    'status': filterStatus
                },
                "sortOption": sort||sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size }
            }
        } else {
        
        }
         payload = {
            "filterOption": {
                'keywords': labelSearch || search,"fromDate": startDate,
                "toDate": endDate,
                'status': filterStatus
            },
            "sortOption": sort ||sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getMatchData`, payload, {
            headers: {
                'Authorization': 'Bearer' + token,
            }
        }).then((response) => {
            if (response.status === 200) {
                if (csv) {
                    setIsFetching(false);
                    options.filename = 'Match list'
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(processDataForCSV(response.data.body.data));
                } else{
                    setIsFetching(false);
                    setMatchList(response.data.body.data);
                    setListCount(response.data.body.recordCount)
                }
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const resetFilter = () => {
        setStartDate("");
        setEndDate("");
        setFilterStatus("");
        setLabelSearch("");
        getMatchList(0, "");
      }
    const columns = [
        { field: 'match_id', headerName: `Id`, flex: 0.5 },
        { field: 'title', headerName: `Name`, flex: 1.5 },
        {
            field: 'competition',
            valueGetter: (params) => {
                if (params.row.competition.length > 0) {
                    return params.row.competition[0].title;
                }
                return "";
            }, headerName: 'Competition', flex: 1
        },
        {
            field: 'shortname',
            valueGetter: (params) => {
                // if (params.row.venue.length > 0) {
                    return params.row.teama[0].short_name + " vs " + params.row.teamb[0].short_name;
                // }
                // return "";
            }, headerName: 'Short name', flex: 1
        },
        { field: 'status', headerName: 'status', flex: 0.5 },
        { field: 'format_str', headerName: 'format', flex: 0.5 },
        { field: 'isAnnounced', headerName: 'IsAnnounced?', flex: 0.5 },
        {
            field: 'venue',
            valueGetter: (params) => {
                if (params.row.venue.length > 0) {
                    return params.row.venue[0].name;
                }
                return "";
            }, headerName: 'Venue', flex: 1
        },
        {
            field: 'timestamp_start', headerName: 'Start Time', flex: 1,
            valueGetter: (params) => {
                    return  moment.unix(params?.row?.timestamp_start).format('YYYY-MM-DD HH:mm:ss')
            },
        },
        {
          field: 'announcedDate',
          valueGetter: ({ value }) => value && moment(value).format("MMMM DD, YYYY h:mm A"),
          headerName: 'Announced Date',
          flex: 1,
        },
        {
            field: 'action', headerName: 'Action', flex: 1,
            renderCell: (params) => (
                <div>
                    {/* <Tooltip title="Edit">
                        <IconButton
                            onClick={() =>
                                (navigate(`/admin/edit-match/${params.id}`))}  >
                            <ModeEditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View Details">
                        <IconButton>
                            <PreviewOutlinedIcon />
                        </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Manage Match">
                        <IconButton
                            onClick={() =>
                                (navigate(`/admin/manage-match/${params.row.match_id}`))}>
                            <SettingsApplicationsIcon />
                        </IconButton>
                    </Tooltip>
                    {(params.row.isAnnounced === false) && <Tooltip title="Announce Players">
                        <IconButton
                            onClick={() =>
                                (navigate(`/admin/announce-player/${params.row.match_id}`))}>
                            <CampaignIcon />
                        </IconButton>
                    </Tooltip>}
                </div>
            )
        },
    ];
    const handleSearch = () => {
        getMatchList(0, '');
    }
    React.useEffect(() => {
        getMatchList(0, '');
    }, [])
    const downloadList = () => {
        getMatchList(0, '', '', 100000,' ');
    }
    return (
        <>
            <SectionTitle title="Matches" subtitle="Manage Match"></SectionTitle>
            <Widget>
            <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '1%', padding: '1%' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }}>
                            <Grid item xs={1} sm={4} md={2}>
                            <TextField id="standard-basic" label="Search by Name" variant="standard" style={{ width: '100%' }}
                            onChange={(e) => setLabelSearch(e.target.value)} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <TextField
                            id="outlined-select-currency"
                            select
                            label="Filter"
                            size="small"
                            value={filterStatus}
                            style={{ width: '100%', marginTop: '15px'}}
                            onChange={(e) => setFilterStatus(e.target.value)}
                        >
                            <MenuItem value={'1'}>Upcomming</MenuItem>
                            <MenuItem value={'2'}>Completed</MenuItem>
                            <MenuItem value={'0'}>All</MenuItem>
                        </TextField>
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={startDate} onChange={(e)=>setStartDate(e.format('YYYY-MMM-DD'))}  label={'Start Date'} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={endDate} onChange={(e)=>setEndDate(e.format('YYYY-MMM-DD'))}  label={'End Date'} />
                            </Grid>

                            <Grid item xs={1} sm={4} md={2}>
                        <Button variant="contained"
                            style={{ float: 'right', margin: '1%', marginTop:"7%"}}
                            color="success"
                            className='[btn btn-default text-white btn-rounded]'
                            startIcon={<RestartAltIcon />}
                            onClick={resetFilter}
                        >
                            Reset
                        </Button>
                            <Button variant="contained"
                            style={{ float: 'right', margin: '1%', marginTop:"7%" }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<SearchIcon />}
                            onClick={() => getMatchList(0, "")}
                        >
                            Search
                        </Button>
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            
                        <img src={excelIcon} width='30px' height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: '18px 5px 0 0',
                            cursor:'pointer',
                            margin:15
                        }}  onClick={() => downloadList()}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%', marginTop: '1%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            style={{ width: '100%' }}
                            rowCount={listCount}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={matchList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getMatchList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getMatchList(0, '', sort)
                                setSortOption(sort)
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getMatchList(0, labelSearch, '', newPageSize)
                            }}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            columns={columns}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
    );
}