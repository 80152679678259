import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FiMail, FiStar, FiUser, FiLogIn } from 'react-icons/fi'

const AccountLinks = () => {
    const navigate = useNavigate()
    const logout = () => {
        localStorage.clear();
        navigate('/admin');
    }
    return (
        <div className="flex flex-col w-full">
            <ul className="list-none">
                <li className="dropdown-item">
                    <div className="flex flex-col justify-start w-full px-2">
                        <button className="flex flex-row mt-2 app-button" onClick={()=>navigate('/admin/change-password')}>
                            <FiUser size={18} className="stroke-current" />
                            <span className="mx-2">Change password</span>
                        </button>
                        <div className="flex flex-row mt-2 app-button">
                        <button className="flex flex-row mt-2 app-button" onClick={() => logout()}>
                            <FiLogIn size={18} className="stroke-current"  />
                            <span className="mx-2">Logout</span>
                            </button>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
    )
}

export default AccountLinks
