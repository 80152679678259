import React, { useEffect, useState } from 'react';
import Widget from '../../../components/widget'
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ApiService from '../../../api/ApiService';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import SectionTitle from '../../../components/section-title';

export const SubAdminForm = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [permission, setPermission] = useState([]);
    const [checked, setChecked] = useState(false);
    const [selectedPerm, setSelectedPerm] = useState([]);
    const [input, setInput] = useState({
        name: '',
        username: '',
        password: '',
        email: '',
        mobile: '',
        isAdmin:false
    });
    const getModules = () => {
        ApiService.post("v1/admin/getAdminModules").then((response) => {
            if (response.status === 200) {
                setPermission(response.data.body);
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }
    useEffect(() => {
        if (params.id) {
            userDetail()
        }
        getModules();
    }, [])

    const handleChecked = (e, index) => {

        const { value, checked } = e.target
        if (checked) {
           
            setSelectedPerm([...selectedPerm, { 'moduleId': value }])
          
        } else {
            setSelectedPerm(selectedPerm.filter((e) => e.moduleId !== value))

        }
    }
    const userDetail = () => {
        ApiService.post("v1/admin/getAdminUserById", { 'id': params.id }).then((response) => {
            if (response.status === 200) {
                setSelectedPerm(response.data.body.allowedModules);
                setInput(response.data.body);
          
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }
    const createAdmin = () => {
        const payload = {
            ...input, "allowedModules": selectedPerm
        }
        ApiService.post("v1/admin/createAdminUser", payload).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message);
                navigate('/admin/sub-admin');
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }
    const updateAdmin = () => {
        const payload = {
            ...input, "allowedModules": selectedPerm, 'userId': params?.id
        }
        ApiService.post("v1/admin/updateAdminUser", payload).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message);
                navigate('/admin/sub-admin')
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }
const handleonchange=()=>{
    setChecked(!checked)
    setSelectedPerm([])
}
    return (
        <>
        <SectionTitle title="Manage User" subtitle="Manage User"></SectionTitle>
        <Widget>
            <Grid>
                <form>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Sub Admin Name"
                                fullWidth
                                name='name'
                                value={input.name}
                                autoComplete="cc-exp"
                                variant="outlined"
                                onChange={(event) => {
                                    setInput({
                                        ...input, name: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="User Name"
                                fullWidth
                                name='username'
                                value={input.username}
                                autoComplete="cc-exp"
                                variant="outlined"
                                onChange={(event) => {
                                    setInput({
                                        ...input, username: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Email"
                                fullWidth
                                name='email'
                                type="email"
                                value={input.email}
                                autoComplete="cc-exp"
                                variant="outlined"
                                onChange={(event) => {
                                    setInput({
                                        ...input, email: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        {params.id ? '' :
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Password"
                                    fullWidth
                                    name='password'
                                    type="password"
                                    value={input.password}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, password: event.target.value
                                        })
                                    }}
                                />
                                {(input.password.length>1 && input.password.length<6) && <div style={{color:"red"}}>Your password should have at least 6 characters</div>}
                            </Grid>
                        }
                        <Grid item xs={12} md={6} className={'flex-row'}>
                            <TextField
                                label="Mobile"
                                fullWidth
                                name='mobile'
                                type='number'
                                inputProps={{ min: 0 }}
                                value={input.mobile}
                                autoComplete="cc-exp"
                                variant="outlined"
                                onChange={(event) => {
                                    setInput({
                                        ...input, mobile: event.target.value
                                    })
                                }}
                            />

                        </Grid>
                        <Grid item xs={12} md={6} className={'flex-row'}>

                         <FormControlLabel
                                control={
                                    <Checkbox checked={input.isAdmin} name={"isAdmin"}onChange={()=>handleonchange()} onClick={() =>setInput({...input, isAdmin:checked===true?false:true})}
                                
                                    />
                                }
                                label={"Is admin ?"}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormGroup>
                                <Grid>
                                    {permission.map((perm, index) => (
                                        <Grid key={index}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox name={perm.name}
                                                        disabled={checked===true||input.isAdmin===true}
                                                        checked={selectedPerm.filter((e) => e.moduleId === perm.value).length>0 ?true:false}
                                                        value={perm?.value} key={perm.value}  
                                                        onClick={(e) => handleChecked(e, index)} />
                                                }
                                                label={perm.name}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormGroup>
                        </Grid>
                        <Grid container spacing={3}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center">
                            <Button variant="contained"
                                style={{ margin: '10px 10px 0 0' }}
                                className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                onClick={() => navigate('/admin/sub-admin')}
                            >
                                Cancel
                            </Button>
                            {params.id ? <Button variant="contained"
                                style={{ margin: '10px 0px 0 10px' }}
                                className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                onClick={() => updateAdmin()}
                            >
                                Update
                            </Button> :
                                <Button variant="contained"
                                    style={{ margin: '10px 0px 0 10px' }}
                                    className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                    onClick={() => createAdmin()}
                                    disabled={input.password.length<6}
                                >
                                    Create
                                </Button>
                            }

                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Widget>
        </>
    )
}