import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import ApiService from '../../../api/ApiService';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SectionTitle from '../../../components/section-title';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import RemoveCircleOutlineOutlined from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import Widget from '../../../components/widget';
import { setSorting } from '../helper/helper';

export default function FetchNotificationList() {
    const [pageSize, setPageSize] = React.useState(10);
    const [notificationList, setNotificationList] = React.useState([]);
    const [isLoading, setIsFetching] = React.useState(false);
    const [labelSearch, setLabelSearch] = React.useState('');
    const [totalRecord, setTotalRecord] = React.useState(null);
    const [sortOption, setSortOption] = React.useState({name : 1});
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const getNotificationList = (currentIndex, search, sort, size) => {
        const payload = {
            "filterOption": search,
            "sortOption": sort || sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size? size : pageSize }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getNotificationTemplate`, payload).then((response) => {
            if (response.status === 200) {
                setNotificationList(response.data.body.data);
                setTotalRecord(response.data.body.recordCount)
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const deleteOffer = (id) => {
      const payload = {
        "id": id
      }
      if (id) {
        ApiService.post(`v1/admin/deleteNotificationTemplate`, payload)
          .then((response) => {
            const responseMessage = response?.data?.message
            toast.success(responseMessage || 'Notification Deleted Successfully!');
            getNotificationList(0, " ");
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message || "Error");
          })
      }
    }
    const columns = [
        { field: 'name', headerName: `Name`, flex: 1 },
        { field: 'content', headerName: 'Content', flex: 1 },
        { field: 'workFlowName', headerName: 'workFlowName', flex: 1 },
        {
            field: 'action', headerName: 'Action', flex: 1,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Edit">
                        <IconButton
                            onClick={() =>
                                (navigate(`/admin/edit-notification/${params.id}`))}  >
                            <ModeEditIcon />
                        </IconButton>
                    </Tooltip> 
                    <>{ params?.row?.workFlowName !== "Default" &&
            <Tooltip title="Remove offer">
              <IconButton
                onClick={() => (deleteOffer(params.id))}
              >
                <RemoveCircleOutlineOutlined />
              </IconButton>
            </Tooltip>}</>
                </div>
            )
        },
    ];
    React.useEffect(() => {
        getNotificationList(0, '')
    }, [])
    return (
        <>
            <SectionTitle title="Notification" subtitle="Manage Notifications"></SectionTitle>
            <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '10px' }}>
                        <TextField id="standard-basic" label="Search" variant="standard" style={{ width: '30%' }}
                            onChange={(e) => (getNotificationList(0, e.target.value))} />
                            <Button variant="contained"
                                style={{ float: 'right', margin: '10px' }}
                                className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                startIcon={<AddIcon />}
                                onClick={(params) =>
                                    (navigate(`/admin/add-notification`))}
                            >
                                Create Notification
                            </Button>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%' }}
                            rowCount={totalRecord}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={notificationList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getNotificationList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getNotificationList(0, '', sort)
                                setSortOption(sort);
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getNotificationList(0, labelSearch, '', newPageSize)
                            }}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            columns={columns}
                        />
                    </div>
                </Paper>

            </Widget>
        </>
    );
}