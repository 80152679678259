import React, { useState, useEffect, useRef } from 'react'
import AccountLinks from './account-links'
import { CgProfile } from "react-icons/cg";


const Dropdown = () => {
  const [hidden, setHidden] = useState(true)

  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        buttonRef.current.contains(event.target) ||
        dropdownRef.current.contains(event.target)
      ) {
        return false
      }
      setHidden(!hidden)
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [hidden, dropdownRef, buttonRef])

  const handleDropdownClick = () => {
    setHidden(!hidden)
  }
  const loginuser = JSON.parse(localStorage.getItem('user'));
  return (
    <div className="relative">
     <div className="flex-row w-full">
     {/* <div className="absolute top-0 left-0 5 mt-4 mr-20 flex-row">{loginuser?.name}</div> */}
      <button
        ref={buttonRef}
        onClick={handleDropdownClick}
        className="flex h-16 w-8 rounded-full ml-2 relative">
        <span className="absolute top-0 left-0 mt-4 ml-2 flex-row">
          <CgProfile style={{ height: '22px', width: '33px' }} />
        </span>
         
      </button>
      </div>
      <div ref={dropdownRef}
        className={`dropdown absolute top-0 right-0 mt-16 ${hidden ? '' : 'open'}`}>
        <div className="dropdown-content w-48 bottom-end">
          <AccountLinks />
        </div>
      </div>
    </div>
  )
}

export default Dropdown
