import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { Button, colors, Grid, InputLabel, MenuItem, Paper, TextField } from '@mui/material';
import { Search, RestartAlt } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../../components/section-title';
import Widget from '../../../components/widget';
import { toast } from 'react-toastify';
import ApiService from '../../../api/ApiService';
import Datepicker from '../../../components/datepicker';
import SearchIcon from '@mui/icons-material/Search';
import { setSorting, options } from '../helper/helper';
import { ExportToCsv } from 'export-to-csv';
import RejectWithdrawRequest from '../../../components/dialogs/RejectWithdrawRequest';
import excelIcon from '../../../images/excel.png';

export default function WithdrawRequest() {
  let date= new Date();
  const Datefind=()=>{

      var a = moment(date).format('MMM DD, YYYY ')
      var d = new Date(a);

      return d.setDate(d.getDate() - 30);
     }
    
  let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
  const [pageSize, setPageSize] = React.useState(10);
  const [requestStatusType, setRequestStatusType] = React.useState("Pending");
  const [startDate, setStartDate] = React.useState(moment(finaldate).format('YYYY-MMM-DD'));
  const [endDate, setEndDate] = React.useState(moment(new Date()).format('YYYY-MMM-DD'));
  const [labelSearch, setLabelSearch] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [listCount, setListCount] = React.useState(null);
  const [isLoading, setIsFetching] = React.useState(false);
  const [sortOption, setSortOption] = useState({ createdOn: -1 });
  const [reason, setReason] = useState(null);
  const [openReject, setOpenReject] = useState(false);
  const [data, setData] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const navigate = useNavigate();
  const processDataForCSV =(csvData)=>{

      for (let index = 0; index < csvData.length; index++) {
          let csvRow = csvData[index];
          if(Array.isArray(csvRow.user)) {
              csvRow.Name = csvRow.user[0]?.name;
              csvRow.bankName = csvRow.BankDetails[0]?.bankName;
              delete csvRow.user;
              delete csvRow.cashFreeResponse;
              delete csvRow.BankDetails;
          }
                       
      }
      return csvData;
  
  }

  const getRequests = (currentIndex, search, sort, size,csv) => {
    let payload = {}

    if (csv) {
      payload = {
        "filterOption": {
          "status": requestStatusType,
          "fromDate": startDate,
          "toDate": endDate,
          "keyword":  labelSearch || search,
        },
        "sortOption": sort || sortOption,
        "pageSizeOption": { "skip": currentIndex, "limit": size ? size : pageSize }
      }
    } else {

    } 
    payload = {
      "filterOption": {
        "status": requestStatusType,
        "fromDate": startDate,
        "toDate": endDate,
        "keyword":  labelSearch || search,
      },
      "sortOption": sort || sortOption,
      "pageSizeOption": { "skip": currentIndex, "limit": size ? size : pageSize }
    }
    setIsFetching(true);
    ApiService.post(`v1/admin/userWithdrawlRequest`, payload).then((response) => {
      if (response.status === 200) {
        if (csv) {
          setIsFetching(false);
          options.filename = 'Withdraw'
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(processDataForCSV(response.data.body.data));
      } else {
        setRows(response.data.body.data);
        setListCount(response.data.body.recordCount);
      }
     } else {
        toast.error(response.data.message)
      }
      setIsFetching(false);
    }).catch((error) => {
      toast.error(error.response.data.message);
      setIsFetching(false);
    });
  }

    const performAction = ({ id, status }) => {
      
    if (isBusy === true) return;
    setIsBusy(true);

    const user = localStorage.getItem('user');
    const userId = user && JSON.parse(user)?.id || null
    const payload = {
      "id": id,
      "userId": userId || "", 
      "status": status,
      "reason": reason
    }
    if (userId) {
      ApiService.post(`v1/admin/approvRejectWithdrawlRequest`, payload)
        .then((response) => {
          const responseMessage = response?.data?.body?.msg || response?.data?.message
          toast.success(responseMessage || 'Request added!');
          setOpenReject(false);
          getRequests(0, "")
          setIsBusy(false);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || "Error");
          setIsBusy(false);
        })
    }
  }
  
  const rejectClose = () => {
      setOpenReject(false);
  }
  const openRejectDialog = (value) => {
      setData(value);
      setOpenReject(true)
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Full Name',
      flex: 1,
      renderCell: (params) => {
        return params?.row?.user[0]?.name
    }
    },
    {
      field: 'mobile',
      headerName: 'Phone',
      flex: 1,
      renderCell: (params) => {
        return params?.row?.user[0]?.mobile
    }
    },
    {
      field: 'amount',
      headerName: 'Request Amount',
      flex: 1,
      renderCell: (params) => {
        return '₹ ' + params?.row?.amount
    }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
    },
    {
      field: 'requestDate',
      headerName: 'Created At',
      type: 'date',
      flex: 1,
      type: 'dateTime',
      valueGetter: ({ value }) => value && moment(value).format("MMMM DD, YYYY h:mm A")
    },
     {
      field: 'cashFreeResponse',
      headerName: 'Cashfree Response',
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1.5,
      type: "actions",
      headerAlign: "left",
      align: "left",
      getActions: ({ row }) => {
        const actions = [];
        if (row.status === "Pending") {
          if (!isBusy) {
            actions.push(
              <Button
                key="approve"
                variant="contained"
                color="info"
                onClick={(e) => {
                  performAction({ id: row?.id || "", status: "Approved" });
                }}
              >
                Approve
              </Button>
            );
          }
          actions.push(
            <Button
              key="reject"
              variant="outlined"
              color="info"
              onClick={() => {
                openRejectDialog({ id: row?.id || "", status: "Rejected" });
              }}
            >
              Reject
            </Button>
          );
        }
        return actions;
      },
    },
    {
        field: 'actions', headerName: 'View TDS line', flex: 1,
        renderCell: (params) => (
            <div>
                <Tooltip title="View TDS line">
                    <IconButton
                        onClick={() =>
                            (navigate(`/admin/TDSbyline/${params.row.userId}`))}>
                        <PreviewOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </div>
        )
    },
  ];

  useEffect(() => {
    getRequests(0, "");
  }, [])

  const downloadList = () => {
    getRequests(0, '', '', 100000,' ');
}
  return (
    <div>
      <SectionTitle title="Requests" subtitle="Withdraw Requests" />
      <Widget>
        <Paper elevation={2}>
          <Grid style={{ width: '100%', margin: '1%', padding: '1%' }}>
          <Grid container spacing={{ xs: 2, md: 2 }}>
          <Grid item xs={1} sm={4} md={2}>
            <TextField
              id="standard-basic"
              label="Search by Name"
              variant="standard"style={{ width: '100%' }}
              onChange={(e) => setLabelSearch(e.target.value)}
            />
            </Grid>
            <Grid item xs={1} sm={4} md={2}>
            <TextField
              id="outlined-select-request-status"
              select
              label="Filter"
              value={requestStatusType}
              size="small"
              onChange={(e) => setRequestStatusType(e.target.value)}
              style={{ width: '100%', marginTop: '15px'}}
            >
              <MenuItem value={'Pending'}>Pending</MenuItem>
              <MenuItem value={'Approved'}>Approved</MenuItem>
              <MenuItem value={'PendingFromBank'}>Pending From Bank</MenuItem>
              <MenuItem value={'Failed'}>Failed</MenuItem>
              <MenuItem value={'Rejected'}>Rejected</MenuItem>
              <MenuItem value={'Processed'}>Processed</MenuItem>
              <MenuItem value={'All'}>All</MenuItem>
            </TextField>
            </Grid>
            <Grid item xs={1} sm={4} md={2}>
            <Datepicker Value={startDate} onChange={(e)=>setStartDate(e.format('YYYY-MMM-DD'))}  label={'Start Date'} />
            </Grid>
            <Grid item xs={1} sm={4} md={2}>
            <Datepicker Value={endDate} onChange={(e)=>setEndDate(e.format('YYYY-MMM-DD'))}  label={'End Date'} />
            </Grid>
            <Grid item xs={1} sm={4} md={2}>
            <Button
              variant="contained"
              style={{ float: 'Left', margin: '1%', marginTop:"7%" }}
              className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
              startIcon={<SearchIcon />}
              onClick={() => getRequests(0, "")}
            >Search</Button>
            <img src={excelIcon} width='30px' height='30px' style={{
                            display: 'inline-flex',  
                            float: 'right',
                            margin: '18px 5px 0 0',
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
          </Grid>
          </Grid>
          </Grid>
          <div style={{ minHeight: 700, height: 600, width: '100%' }}>
            <DataGrid
              rows={rows}
              loading={isLoading}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 20, 50, 100]}
              onPageChange={(index) => { 
                let skip =pageSize*index;
                getRequests(skip, labelSearch) }}
              pagination
              sortingMode="server"
              onSortModelChange={(modal) => {
                const sort = setSorting(modal);
                getRequests(0, '', sort);
                setSortOption(sort);
              }}
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              disableSelectionOnClick
              paginationMode="server"
              rowCount={listCount}
              onPageSizeChange={(newPageSize) => {
                setPageSize(newPageSize);
                getRequests(0, labelSearch, '', newPageSize);
              }}
              getRowId={(row) => row.id}
              columns={columns}
            />
          </div>
        </Paper>
      </Widget>
      <RejectWithdrawRequest open={openReject} handleClose={rejectClose} data={data}
                contentText='Please enter the reason for reject request'
                rejectRequest={performAction} setReason={(value) => setReason(value)} />

    </div>
  );
}
