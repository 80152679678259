import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Widget from '../../../components/widget';
import ApiService from '../../../api/ApiService';
import SearchIcon from '@mui/icons-material/Search';
import SectionTitle from '../../../components/section-title';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ExportToCsv } from 'export-to-csv';
import { options, setSorting } from '../helper/helper';
import moment from 'moment';
import excelIcon from '../../../images/excel.png';
import Datepicker from '../../../components/datepicker';

const Gst = () => {
    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setDate(d.getDate() - 15);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const [pageSize, setPageSize] = useState(10);
    const [gstList, setGstList] = useState([]);
    const [isLoading, setIsFetching] = useState(false);
    const [labelSearch, setLabelSearch] = useState('');
    const [contestList, setContestList] = useState([]);
    const [contestId, setContestId] = useState('');
    const [matchId, setMatchId] = useState('');
    const [total, setTotal] = useState(null)
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const [upcomming, setUpcomming] = React.useState(1);
    const [matches, setMatches] = React.useState(0);
    const [match, setMatch] = React.useState([]);
    const [index, setIndex] = React.useState(0);
    const [contests, setContests] = React.useState([]);
    const [contestscount, setContestscount] = React.useState(0);
    const [startDate, setStartDate] = useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MMM-DD'));
    const processDataForCSV =(csvData)=>{

        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            if(Array.isArray(csvRow.Contests)) {
                csvRow.Contestname = csvRow.Contests[0].name;
               csvRow.Username = csvRow.Users[0].name;
               csvRow.title = csvRow.MatchES[0]?.title;
               delete csvRow.Contests;
               delete csvRow.Users;
               delete csvRow.id;
               delete csvRow.MatchES;
            }             
        }
        return csvData;
    
    }
    const getGstList = (currentIndex, search, sort, size,csv) => {
        let payload = {}
        if (csv) {
            payload = {
                "filterOption": {
                    'keyword': labelSearch,
                    'contestId': contestId
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size? size : pageSize }
            }
        } else {
            payload = {
                "filterOption": {
                    'keyword': labelSearch,
                    'contestId': contestId,
                    "startDate": startDate,
                    "endDate": endDate,
                    'matchId' :matchId!==0? matchId:''
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size? size : pageSize }
            }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getUserInvoice`, payload).then((response) => {
            if (response.status === 200) {
                if (csv) {
                    setIsFetching(false);
                    options.filename = 'Gst'
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(processDataForCSV(response.data.body.data.usersInvoice));
                } else {
                    setIsFetching(false);
                    setGstList(response.data.body.data.usersInvoice);
                    setTotal(response.data.body.recordCount);
                }
            } else {
                setGstList([])
                setIsFetching(false);
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const getContestList = () => {
        ApiService.post(`v1/admin/ContestListDropDown`).then((response) => {
            if (response.status === 200) {
                setContestList(response?.data?.body);
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }
    const handleSearch = () => {
        getGstList(0, '');
    }
    const downloadList = () => {
        getGstList(0, '', '', 100000,' ');
    }
    const columns = [
        {
            field: 'Match', headerName: `Match Name`, flex: 1,
            renderCell: (params) => {
                return params.row.MatchES[0]?.title;
            }
        },
        {
            field: 'Contest', headerName: `Contest Name`, flex: 1,
            renderCell: (params) => {
                return params.row.Contests[0].name;
            }
        },
        {
            field: 'user', headerName: `User Name`, flex: 1,
            renderCell: (params) => {
                return params.row.Users[0].name;
            }
        },

        {
            renderCell: (params) => {
                return '₹' + params?.row?.amount;
            }, headerName: 'Amount', field: 'amount', flex: 1
        },
        {
            field: 'taxableAmount', headerName: 'Taxable Amount', flex: 1,
            renderCell: (params) => {
                return '₹' + params.row.taxableAmount;
            }
        },

        {
            field: 'igst', headerName: 'IGST', flex: 1,
            renderCell: (params) => {
                return '₹' + params.row.igst;
            }
        },
        {
            field: 'createdOn', headerName: 'Created At', flex: 1,
            valueGetter: (params) => {
                if (params.row.createdOn) {
                    return moment(params.row.createdOn).format('MM/DD/YYYY');
                }
                return "";
            },
        },
    ];
    useEffect(() => {
        getGstList(0, '');
    }, [])


    const handleChange = (keywords,event) => {
        setUpcomming(event)
        const payload = {
            "filterOption":{"keywords":(keywords !== undefined)===true? keywords:'',"status":event} ,
            "sortOption": { "timestamp_start": 1 },
            "forDropdown":true,
            "pageSizeOption": { "skip":parseInt(index), "limit":  1000 }
        }
        
        ApiService.post(`v1/admin/getMatchData`, payload).then((response) => {
            if (response.status === 200) {
                setMatch(response.data.body.data);
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
      
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
     
    };
    const handleChangeContests = (event) => {
        setMatches(event)
        
        const payload = {
           "matchId":event
        }
        
        ApiService.post(`v1/admin/contestListDropDown`, payload).then((response) => {
           
            if (response.status === 200) {
                setContests(response.data.body);
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
      
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
     
    };

    return (
        <>
            <SectionTitle title="GST Report" subtitle="GST"></SectionTitle>
            <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '10px' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }} >
                            <Grid item xs={1} sm={4} md={2}>
                                <TextField id="standard-basic" label="Search" variant="standard" style={{ width: '100%' }} onChange={(e) => {
                                    setLabelSearch(e.target.value);
                                }} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={startDate} onChange={(e)=>setStartDate(e.format('YYYY-MMM-DD , hh:mm:ss'))}  label={'Start Date'} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={endDate} onChange={(e)=>setEndDate(e.format('YYYY-MMM-DD , hh:mm:ss'))}  label={'End Date'} />
                            </Grid>
                            {/* <Grid item xs={1} sm={4} md={2}>
                                <TextField
                                    select
                                    id="outlined-select-currency"
                                    label="Select Contest Match"
                                    size="small"
                                    style={{ width: '100%', marginTop: '10px', }}
                                    onChange={(event) => {
                                        setContestId(event.target.value);
                                    }}
                                >
                                    {contestList?.map((option) => (
                                        <MenuItem value={option.id} key={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid> */}
                            {/* <Grid item xs={3} sm={4} md={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Filter"
                                    size="small"
                                    value={upcomming}
                                    style={{ width: '100%', marginTop: '10px', }}
                               
                                    onChange={(e) =>handleChange(labelSearch.keywords!==undefined?labelSearch.keywords:'',e.target.value)}
                                >
                                    <MenuItem  value={'1'}>Upcomming</MenuItem>
                                    <MenuItem value={'2'}>Completed</MenuItem>
                                    <MenuItem onChange={(e) =>handleChange(labelSearch.keywords!==undefined?labelSearch.keywords:'',e.target.value)} value={0}>All</MenuItem>

                                </TextField>
                            </Grid>
                            <Grid item xs={3} sm={4} md={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Matches"
                                    size="small"
                                    value={matches}
                                    onChange={(e)=>setMatchId(e.target.value)}
                                    style={{ width: '100%', marginTop: '10px', }}
                                
                                >
                                    <MenuItem onClick={(e) =>handleChangeContests(e.target.value)} value={0}>All</MenuItem>
                                    {match!==null && match.map((option) => (
                                        <MenuItem  onClick={() =>handleChangeContests(option.match_id)} key={option.match_id} value={option.match_id}>
                                            {option.title}
                                        </MenuItem>
                                    ))}

                                </TextField>
                            </Grid>
                            <Grid item xs={3} sm={4} md={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Contests"
                                    size="small"
                                     value={contestscount}
                                     onChange={(event) => {
                                        setContestId(event.target.value);
                                    }}
                                    style={{ width: '100%', marginTop: '10px', }}
                                
                                >
                                      <MenuItem onClick={(e) =>setContestscount(e.target.value)} value={0}>All</MenuItem>
                                    {contests!==null && contests!== undefined && contests.map((option) => (
                                        <MenuItem  onClick={() =>setContestscount(option.id)} key={option.id} value={option.id}>
                                            {option.name }
                                        </MenuItem>
                                    ))}

                                </TextField>
                            </Grid> */}
                            <Grid item xs={1} sm={4} md={2}>
                                <Button variant="contained"
                                    style={{ marginTop: '17px' }}
                                    className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                    startIcon={<SearchIcon />}
                                    onClick={() =>  getGstList(0, '')}
                                >
                                    Search
                                </Button>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                            <img src={excelIcon} width='30px' height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: '18px 5px 0 0',
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%', height: '100px !important' }}
                            rowCount={total}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={gstList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getGstList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getGstList(0, '', sort);
                                setSortOption(sort);
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getGstList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
    )
}
export default Gst