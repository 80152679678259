import React,{useState} from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import TextField from '@mui/material/TextField';

  function SketchExample(props) {
    const {setcolor,data } = props;
    const [color, setColor] = useState(data);
    const [open, setClose] = useState(false);
    const handlechange = (color) => {
      setColor(color.hex);
      setcolor(color.hex)
    };
    const handletextchange = (color) => {
      setColor(color);
      setcolor(color)
    };

    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: color
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',marginLeft:"20px"
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    return (
      <> <TextField
      fullWidth
      name="color"
      autoComplete="cc-exp"
      variant="outlined"
      onChange={(e) =>handletextchange(e.target.value) }
      value={color}
  />
        <div style={ styles.swatch } onClick={() => setClose(true)}>
          <div style={ styles.color } />
        </div>
      <div style={styles.popover}>
     
        {open === true && (
          <div style={ styles.cover } onClick={() => setClose(false)}>
            <SketchPicker color={color} onChange={handlechange} />
          </div>
        )}
    </div>

      </>
    )
  }


export default SketchExample