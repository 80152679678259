import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../components/section-title';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Widget from '../../../components/widget';
import ApiService from '../../../api/ApiService';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import { options, setSorting } from '../helper/helper';
import excelIcon from '../../../images/excel.png';
import Datepicker from '../../../components/datepicker';
import MenuItem from '@mui/material/MenuItem';

const NotificationLog = () => {
    const [pageSize, setPageSize] = useState(100);
    const [NotificationList, setNotificationList] = useState([]);
    const [isLoading, setIsFetching] = useState(false);
    const [total, setTotal] = useState(null)
    const processDataForCSV =(csvData)=>{
        let allCSVRows=[];
        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            let row ={};
            row.serialNum =index+1;
            row._id = csvRow._id
            row.title = csvRow.title;
            row.content = csvRow.content;
            row.createdOn = csvRow.createdOn;
            allCSVRows.push(row)
        }
        return allCSVRows;
    
    }
    const getNotificationList = (csv) => {
        let payload = {}
 
        const user = JSON.parse(localStorage.getItem('user'));
         if (csv) {
             payload = {
            }
         } else {
         }
          payload = {
            }
         setIsFetching(true);
         ApiService.post(`v1/admin/getNotificationLogs`, payload).then((response) => {
             if (response.status === 200) {
                 if (csv) {
                     setIsFetching(false);
                     options.filename = 'Notification Logs'
                     const csvExporter = new ExportToCsv(options);
                     csvExporter.generateCsv(processDataForCSV(response.data.body));
                 } else {
                    setNotificationList(response.data.body);
                    //  setTotal(response.data.body.recordCount);
                     setIsFetching(false);
                 }
             } else {
                 setIsFetching(false);
                 toast.error(response.data.message)
             }
         }).catch((error) => {
             toast.error(error.response.data.message);
             setIsFetching(false);
         });
     }
     const columns = [
        {
            field: 'title', headerName: `Name`, flex: 1,
            renderCell: (params) => {
                return params.row.title;
            }
        },
        {
            field: 'content', headerName: `content`, flex: 1,
            renderCell: (params) => {
                return params.row.content;
            }
        },
        {
            field: 'createdOn', headerName: 'Created On', flex: 1,
            valueGetter: (params) => {
                    return moment(params.row.createdOn).format('MMM Do YYYY, h:mm:ss a');
            },
        },
    ];
    
    useEffect(() => {
        getNotificationList();
    }, [])
    const downloadList = () => {
        getNotificationList(' ');
    }
    return (
        <>
         <SectionTitle title="Notification Log" subtitle="Notification Log"></SectionTitle>
         <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '10px' }}>
                        {/* <Grid container spacing={{ xs: 2, md: 2 }} > */}
                            <Grid item xs={1} sm={4} md={2}>
                                      <img src={excelIcon} 
                                      alt='1'
                                      width='30px' 
                                      height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: 15,
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                        </Grid>
              {/* </Grid> */}
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            style={{ width: '100%', height: '100px !important' }}
                            rowCount={total}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={NotificationList}
                            columns={columns}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
        )
    }
    
export default NotificationLog