import  React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Button, Grid, Icon, IconButton, TextField, Tooltip } from '@mui/material';
import ApiService from '../../../api/ApiService';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import SectionTitle from '../../../components/section-title';
import Widget from '../../../components/widget'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import { ExportToCsv } from 'export-to-csv';
import { AiOutlineFile } from "react-icons/ai";
import SubmitAmount from '../../../components/dialogs/SubmitAmount';
import moment from 'moment';
import {
    FaDollarSign,
    FaRegAddressCard
  } from 'react-icons/fa'
import { Description } from '@mui/icons-material';
import Datepicker from '../../../components/datepicker'
import Switch from '@mui/material/Switch';
import { options, setSorting } from '../helper/helper';
import excelIcon from '../../../images/excel.png';
export default function FetchParticipantList() {
    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setMonth(d.getMonth() - 1);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const [pageSize, setPageSize] = React.useState(10);
    const [participantList, setParticipantList] = React.useState([]);
    const [isLoading, setIsFetching] = React.useState(false);
    const [labelSearch, setLabelSearch] = React.useState('');
    const [filter, setFilter] = React.useState(0);
    const [listCount, setListCount] = React.useState(null);
    const [requestDialog, setRequestDialog] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [amount, setAmount] = React.useState(null);
    const [discription, setdiscription] = React.useState(null);
    const [password, setpassword] = React.useState(null);
    const [enddate, setEnddate] = React.useState(moment(new Date()).format('YYYY-MMM-DD'));
    const [startdate, setStartdate] = React.useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
        const [checked, setChecked] = useState(false);
    const navigate = useNavigate();


    const openRequestDialog = (value) => {
        setData(value);
        setRequestDialog(true);
    }
    const closeRequestDialog = () => {
        setRequestDialog(false);
    }

    const addBalanceRequest = (id) => { 
        const loginuser = JSON.parse(localStorage.getItem('user'));
        const payload = {
            "userId":id,
            "amount":parseInt(amount),
            "secreatKey":password,
            "reason":discription,
            "addedBy":loginuser.id
        }
            ApiService.post(`v1/admin/addUserBalance`, payload).then((response) => {

            if (response.status === 200) {
                toast.success(response.data.message);
                closeRequestDialog();
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }





    const getParticipantList = (currentIndex, search, sort, size,csv) => {
       let payload = {}
       
        if (csv) {
            payload = {
                "filterOption": {
                    'keyword': labelSearch,
                    startData: "",
                    endDate: "",
                    showAll: filter
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size }
            }
        } else {
          
        }
         payload = {
            "filterOption": {
                keyword: labelSearch || search,
                startDate: startdate,
                endDate: enddate,
                showAll: filter
            },
            "sortOption": sort || sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getAllUsers`, payload).then((response) => {
          
            if (response.status === 200) {
                  if (csv) {
                    setIsFetching(false);
                    options.filename = 'participents'
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(response.data.body.data);
                } else {
                    setIsFetching(false);
                    setParticipantList(response.data.body.data);
                    setListCount(response.data.body.recordCount)
                }

            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const setStatus = (status,id) => {
        const payload = {
            "userId":id,
            "isActive":status===false? true:false
        }
      
            ApiService.post(`v1/admin/setUserStatus`, payload).then((response) => {

            if (response.status === 200) {
                toast.success(response.data.body.msg);
                getParticipantList(0, '');
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    
    const columns = [
        { field: 'name', headerName: `Name`, flex: 1 },
        { field: 'teamName', headerName: `Team Name`, flex: 1 },
        { field: 'email', headerName: `Email`, flex: 1.2 },
        // { field: 'dob', headerName: `dob`, flex: 1 },
        { field: 'mobile', headerName: `mobile`, flex: 1 },
        // { field: 'address', headerName: `address`, flex: 2 },
        // { field: 'city', headerName: `city`, flex: 1 },
        // { field: 'pincode', headerName: `pincode`, flex: 1 },
        { field: 'state', headerName: `state`, flex: 1 },
        {
            field: 'isActive', headerName: `Status`, flex: 1,
            renderCell: (params) => {
                return <Switch
                    checked={params.row.isActive}
                    onChange={(e) => setStatus(params.row.isActive,params.row.id)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            }
        },
        { field: 'createdOn', headerName: `Created On`, flex: 1 ,
        renderCell: (params) => {
            return moment(params.row.createdOn).format('MMM Do YYYY, h:mm:ss a');
        },
    },
        {field: 'action', headerName: 'Action', flex: 1,
            
            renderCell: (params) => (
                
                <div>
                   
                    <Tooltip title="View Details">
                        <IconButton onClick={() => (navigate(`/admin/view-details/${params.id}`))}>
                            <PreviewOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add user balance">
                         <IconButton  onClick={() => { openRequestDialog(params.row) }}>
                        <FaDollarSign size={19} style={{borderWidth:2.5,borderColor:"grey",borderRadius:3,}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View TDS line">
                        <IconButton
                            onClick={() =>
                                (navigate(`/admin/TDSbyline/${params.id}`))}>
                            <AiOutlineFile />
                        </IconButton>
                    </Tooltip>
                   
                </div>
            )
        },
    ];
    
    const handleSearch = () => {
        getParticipantList(0, '');
    }
    React.useEffect(() => {
        getParticipantList(0, '')
    }, [])
    const downloadList = () => {
        getParticipantList(0, '', '', 100000,' ');
    }
    return (
        <>
            <SectionTitle title="Participants" subtitle="Manage Participants"></SectionTitle>
            <Widget>
                <Paper elevation={2}>
                <Grid style={{ width: '100%', margin: '1%', padding: '1%' }}>
                    <Grid container spacing={{ xs: 2, md: 2 }}>
                    <Grid item xs={1} sm={4} md={2}>
                        <TextField id="standard-basic" label="Keywords" variant="standard" style={{width: '100%' }}
                            onChange={(e) => {
                              
                                setLabelSearch(e.target.value)
                            }} />
                    </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                <Datepicker Value={startdate} onChange={(e)=>setStartdate(e.format('YYYY-MMM-DD'))}  label={'Start Date'}   />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                <Datepicker Value={enddate} onChange={(e)=>setEnddate(e.format('YYYY-MMM-DD'))}  label={'End Date'} 
                                 />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                        <TextField
                          style={{ marginTop: '17px', width: '90%' }}
                            select
                            label="Status"
                            size="small"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            SelectProps={{
                                native: true
                            }}
                        >
                            <option value={0}>All</option>
                            <option value={1}>Active</option>
                            <option value={2}>InActive</option>
                        </TextField>
                        </Grid>
                        <Grid item xs={1} sm={4} md={2}>
                        <Button variant="contained"
                            style={{  marginTop: '17px' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<SearchIcon />}
                            onClick={() => handleSearch()}
                        >
                            Search
                        </Button>
                        </Grid>
                        <Grid item xs={1} sm={4} md={2}>
                       
                        
                        <img src={excelIcon} width='30px' height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: '18px 5px 0 0',
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                        </Grid>
                    
                        </Grid>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%', marginTop: '2%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            style={{ width: '100%' }}
                            rowCount={listCount}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={participantList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getParticipantList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getParticipantList(0, '', sort);
                                setSortOption(sort)
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getParticipantList(0, labelSearch, '', newPageSize)
                            }}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            columns={columns}

                        />
                    </div>
                </Paper>
                <SubmitAmount 
                 open={requestDialog} 
                 data={data}
                 handleClose={closeRequestDialog}
                 setAmount={(value) => setAmount(value)} 
                 setdiscription={(value) => setdiscription(value)} 
                 setpassword={(value) => setpassword(value)}
                 addBalanceRequest={addBalanceRequest}
                 />
            </Widget>
        </>
    );
}
