import React, { useState } from 'react'
import ApiService from '../../../api/ApiService';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Link, useNavigate } from 'react-router-dom'


const ChangePassword = ({ message = null }) => {
  const navigate = useNavigate()
  const [data, onSubmit] = useState(null)
  const [old, setOld] = useState(null)
  const [New, setNew] = useState(null)
  const [confirm, setConfirm] = useState(null)
  const logout = () => {
    localStorage.clear();
    navigate('/admin');
  }
  const onSubmitFn = (data) => {

    const loginuser = JSON.parse(localStorage.getItem('user'));
    const payload = {
      "adminId": loginuser.id,
      "currentPass": old,
      "newPass": New
    }
    ApiService.post("v1/admin/changeAdminPassword", payload).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.body.msg)
        logout()
      } else {
        toast.error(response.response.data.message)
      }
    }).catch((error) => {
      toast.error(error.response.data.message)
    })
  }
  const disableButton = () => {
    if (old?.length > 5) {
      if (New?.length > 5  && New?.length < 13) {
        if (confirm?.length > 5 ) {
          if (New === confirm) {
            return false
          }
        }
      }
    }
    return true
  }
  return (
    <div className=" p-4 mb-4 rounded-lg bg-white border lg:w-2/10">
      <div className="flex flex-col lg:w-2/6">
        <div>

          <TextField
            required

            margin="dense"
            id="name"
            label="Old password"
            type="password"
            fullWidth
            variant="outlined"

            onChange={(e) => {
              setOld(e.target.value);

            }}

          />
          <TextField

            required
            margin="dense"
            id="outlined-basic"
            label="New password"
            type="password"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setNew(e.target.value);

            }}

          />
          {New !== null &&
            <div style={{ color: "red" }}>{New?.length > 5 || New?.length < 13 && 'Your password should be between 6 to 12 charecters'}
            </div>}
          <TextField

            required
            margin="dense"
            id="name"
            label="Confirm password"
            type="password"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setConfirm(e.target.value);

            }}

          />
          {confirm !== null &&
            <div style={{ color: "red" }}>{confirm?.length > 5 || confirm?.length < 13 && 'Your password should be between 6 to 12 charecters'}
            </div>}
          {confirm !== null &&
            <div style={{ color: "red" }}>{New !== confirm && 'Password are not same'}
            </div>}

          <Button variant="contained"
            style={{ float: 'left', marginTop: '2%', marginRight: '1%' }}
            color="success"
            className='[btn btn-default text-white btn-rounded]'
            disabled={disableButton()}
            onClick={() => onSubmitFn()}
          >
            Submit
          </Button>



        </div>
      </div>
    </div>
  )
}
export default ChangePassword
