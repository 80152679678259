import React from 'react'
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material'
import { toast } from 'react-toastify';
import { ArrowBack } from '@mui/icons-material';
import SectionTitle from '../../../components/section-title'
import { CustomToolbarExample } from '../../../components/text-editor';
import Widget from '../../../components/widget'
import { useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../../api/ApiService';
import moment from 'moment';
import axios from 'axios';

const API_ENDPOINTS = [
  {
    api: "v1/admin/getMatchList",
    body: null
  },
  {
    api: "v1/admin/ContestList",
    body: {
      "filterOption": "",
      "sortOption": {
        "createdOn": -1
      },
      "pageSizeOption": {
        "skip": 0,
        "limit": 50
      }
    }
  },
]

export default function CreateOffer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const route = window.location.pathname;

  const [input, setInput] = React.useState({
    "name": "",
    "shortDesc": "",
    "htmlContents": "",
    "bannerImage": "",
    "startDate": "",
    "endDate": "",
    "linkedContest": null,
    "linkedMatch": null,
    "isActive": false
  });
  const [matchList, setMatchList] = React.useState([]);
  const [contestList, setContestList] = React.useState([]);
  const [fetchingData, setFetchingData] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [isLoading, setIsFetching] = React.useState(false);

  const matchListName = () => {
    
    const payload = {
        "filterOption":{"keywords":"","status":'1'},
        "sortOption": {"timestamp_start":1},
        "forUpcoming": true,
        "pageSizeOption": { "skip":0, "limit":100}
    } 
    setIsFetching(true);
    ApiService.post(`v1/admin/getMatchList`, payload).then((response) => {
      
        if (response.status === 200) {
         
            setMatchList(response.data.body);

           
        } else {
   
          
            toast.error(response.data.message)
        }
        setIsFetching(false);
    }).catch((error) => {
      setMatchList([])
        toast.error(error.response.data.message);
        setIsFetching(false);
    });
  }

  const fetchOffer = () => {
    const payload = {
      "id": id
    }
    if (id) {
      ApiService.post(`v1/admin/getUserOfferById`, payload)
        .then((response) => {
          const responseBody = response?.data?.body || null;
          if (responseBody && typeof responseBody === 'object' && !Array.isArray(responseBody)) {
            setInput({
              ...input,
              ...responseBody
            })
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || "Error");
          navigate('/admin/offers', { replace: true });
        })
        .finally(() => setFetchingData(false))
    }
  }

  React.useEffect(() => {
    matchListName()
    handleChange()
    if (id) {
      setFetchingData(true);
      fetchOffer();
    }
  }, [id]);
  let startDate = new Date();
  let stdt = startDate.getFullYear()+'-'+ (startDate.getMonth()+1) +'-'+startDate.getDate();

  const handleSubmission = () => {
    setShowError(false);
    if(!route.includes('/admin/offers/edit')) {
    if (input.startDate > input.endDate) {
      toast.error("End date should be greater than Start date")
      return;
    }
    if ((input.linkedMatch !== null) && input.linkedContest === null ) {
      toast.error("please select Contest")
      return;
    }
    if (Date.parse(stdt) > Date.parse(input.startDate) ) {
      toast.error("Start date should be greater or equal to current date")
      return;
    }
  }
    if (input.bannerImage
      && input.endDate
      && input.htmlContents
      && input.name
      && input.shortDesc
      && input.startDate) {
      ApiService.post(id ? 'v1/admin/updateUserOffer' : `v1/admin/insertUserOffer`, {
        ...input,
        ...(id && { updatedOn: moment() })
      })
        .then((response) => {
          const responseMessage = response?.data?.message;
          toast.success(responseMessage || `User offer ${id ? 'updated' : 'created'}!`);
          navigate('/admin/offers', { replace: true });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || "Error");
        })
    } else {
      setShowError(true);
    }
  }


  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({ name: "Selected image", base64: reader.result });
    reader.onerror = error => reject(error);
  });

  const handleImage = (e) => {
    if (e.target.files.length) {
      let fileSize = parseFloat(e.target.files[0].size / (1024 * 1024)).toFixed(2);
      if (fileSize > 1) {
        toast.error('please select file having 1 or less than 1 mb')
        setInput({
          ...input, bannerImage: ''
        })
        return false
      } else {
        toBase64(e.target.files[0]).then(
          data => {
            setInput({
              ...input, bannerImage: data.base64
            })
          }
        );
      }
    }
  }

    const handleContentValue = (value) =>   setInput({
    ...input, htmlContents: value === "<p><br></p>" ? "" : value
  })

  if (fetchingData) {
    return (
      <div className='w-full h-full bg-white flex justify-center items-center'>
        <CircularProgress />
      </div>
    )
  }
  const handleChange = (matchId) => {
    
    const payload = {
         'matchId':matchId
    }
    ApiService.post(`v1/admin/contestListDropDown`, payload).then((response) => {
        if (response.status === 200) {
            setContestList(response.data.body);
        } else {
            toast.error(response.data.message)
        }
        setIsFetching(false);
    }).catch((error) => {
  
        toast.error(error.response.data.message);
        setIsFetching(false);
    });
 
};
  return (
    <div>
      <SectionTitle title="Offers" subtitle="Create / Edit Offer" />
      <Widget>
        <Grid>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  error={showError && !input.name}
                  helperText={showError && !input.name && "Required"}
                  label="Offer Name"
                  fullWidth
                  name='name'
                  value={input.name}
                  autoComplete="cc-exp"
                  variant="outlined"
                  onChange={(event) => {
                    setInput({
                      ...input, name: event.target.value
                    })
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={showError && !input.shortDesc}
                  helperText={showError && !input.shortDesc && "Required"}
                  label="Short Description"
                  fullWidth
                  name='shortDesc'
                  value={input.shortDesc}
                  variant="outlined"
                  onChange={(event) => {
                    setInput({
                      ...input, shortDesc: event.target.value
                    })
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={showError && !input.bannerImage}
                  helperText={showError && !input.bannerImage && "Required"}
                  label="Banner Image"
                  fullWidth
                  type="file"
                  name='bannerImage'
                  inputProps={{
                    accept: ".jpef, .png, .jpg",
                    src: input.bannerImage
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  onChange={handleImage}
                  required
                />
          
                {input.bannerImage && <div className="title text-grey-500">Select another image to update current image.</div>}
              </Grid>
              <Grid item xs={12} md={6}> {input.bannerImage &&<img src={input?.bannerImage} width={30} />}</Grid>

            
              <Grid item xs={12} md={6}>
                <TextField
                  error={showError && !input.startDate}
                  helperText={showError && !input.startDate && "Required"}
                  label="Start Date"
                  fullWidth
                  type="date"
                  name='startDate'
                  value={moment(input.startDate).format("YYYY-MM-DD")}
                  onChange={(event) => {
                    setInput({
                      ...input, startDate: event.target.value
                    })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  error={showError && !input.endDate}
                  helperText={showError && !input.endDate && "Required"}
                  label="End Date"
                  fullWidth
                  type="date"
                  name='endDate'
                  value={moment(input.endDate).format("YYYY-MM-DD")}
                  onChange={(event) => {
                    setInput({
                      ...input, endDate: event.target.value
                    })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {
                  (!input.matchDetails || !input.matchDetails.title) && 
              <TextField
                  select
                  label="Link Match"
                  name="linkedMatch"
                  fullWidth
                  value={input.linkedMatch || ""}
                  onChange={(e) =>handleChange(e.target.value)}
                >
                  {
                    matchList.map((matchOption) => (
                      <MenuItem
                        onClick={() => setInput({
                          ...input, linkedMatch: matchOption.match_id
                        })}
                        value={matchOption.match_id}
                        key={`${matchOption.match_id}`}>
                        {matchOption.title}
                      </MenuItem>
                    ))
                  }
                </TextField>
                }
                { (input.matchDetails && input.matchDetails.title && input.matchDetails.title!=='') && 
                                <TextField
                                className='input-label'
                                label="Link Match"
                                name="linkedMatch"
                                style={{ width: '100%' }}
                                    value={input.matchDetails?.title}>
                                </TextField>
                                } 
                                {
                  ( !input.contestDetails || !input.contestDetails.name) && 
                <TextField
                  select
                  label="Link Contest"
                  name="linkedContest"
                  fullWidth 
                  value={input.linkedContest || ""}
                  style={{marginTop:"4%"}}
                >
                  {
                    contestList.map((contestOption) => (
                      <MenuItem
                        onClick={(e) => setInput({
                          ...input, linkedContest: contestOption.id
                        })}
                        value={contestOption.id}
                        key={contestOption.id}>
                        {contestOption?.name}
                      </MenuItem>
                    ))
                  }
                </TextField>
                }
                { (input.contestDetails && input.contestDetails.name && input.contestDetails.name!=='') && 
                                <TextField
                                className='input-label'
                                label="Link Contest"
                                name="linkedContest"
                                style={{ width: '100%', marginTop:"4%" }}
                                    value={input.contestDetails?.name}>
                                </TextField>
                                } 
                
              </Grid>
              <Grid item xs={12} md={12}>
                 <CustomToolbarExample handleChange={(value) => handleContentValue(value)} value={input.htmlContents} />
                {showError && !input.htmlContents && <div className="title text-red-700 mt-2 mb-2">Required</div>}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={<Checkbox
                    color="primary"
                    name="isActive"
                    checked={input.isActive}
                    onChange={(event) => {
                      setInput({
                        ...input, isActive: event.target.checked
                      })
                    }}
                  />}
                  label="Active"
                />
              </Grid>
              <Grid item xs={12} md={6}
                className="flex flex-row justify-end gap-4"
              >
                <Button
                  variant="contained"
                  onClick={() => { navigate('/admin/offers', { replace: true }) }}
                  size="medium"
                  startIcon={<ArrowBack />}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSubmission}
                  size="medium" >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Widget>
    </div>
  )
}

