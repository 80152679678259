import React from 'react'
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material'
import { toast } from 'react-toastify';
import { ArrowBack } from '@mui/icons-material';
import SectionTitle from '../../../components/section-title'
import { CustomToolbarExample } from '../../../components/text-editor';
import Widget from '../../../components/widget'
import { useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../../api/ApiService';
import moment from 'moment';
import axios from 'axios';
import jwt_decode from "jwt-decode";



export default function CreateCashbackOffer() {
    const {  id } = useParams();
  
    
    const navigate = useNavigate();
 

    const [input, setInput] = React.useState({
        "promotionName": "",
        "percentageAmount": '',
        "maxCashback": "",
        "allowForNewUser": false,
        "allowForRegisteredUser": false,
        "maxValueOfPromotion": "",
        "startDate": "",
        "endDate": "",
        "totalAmountUtilized": 0,
        "isActive": false,
        "minimumFixAmount":0,
        "fixCashbackAmount":0,
        
    });
    
    const [fetchingData, setFetchingData] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [offerType, setOfferType] = React.useState(1);

    


    const fetchOffer = () => {
        const payload = {
            "id": id
        }
        if (id) {
            ApiService.post(`v1/admin/getCashbackOfferById`, payload)
                .then((response) => {
                    const responseBody = response?.data?.body || null;
                  
                        setInput({
                            ...input,
                            promotionName: responseBody.name,
                            percentageAmount: responseBody.percentageOfAmount,
                            maxCashback: responseBody.maxCashbackAmount,
                            allowForNewUser: responseBody.allowForNewUsers,
                            allowForRegisteredUser: responseBody.allowForAllUsers,
                            maxValueOfPromotion: responseBody.maxUtilizeValue,
                            startDate: responseBody.startDate,
                            endDate: responseBody.endDate,
                            isActive: responseBody.isActive,
                            totalAmountUtilized: responseBody.totalAmountUtilized,
                            minimumFixAmount: responseBody.minimumFixAmount,
                            fixCashbackAmount: responseBody.fixCashbackAmount
                            
                        })
                    
                        setOfferType(responseBody.offerType)
                        
                    
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message || "Error");
                     navigate('/admin/CashbackOffer', { replace: true });
                })
                .finally(() => setFetchingData(false))
        }
    }

    React.useEffect(() => {
    
        if (id) {
    
            setFetchingData(true);
            fetchOffer();
        }
    }, [id]);

    
    
    const handleSubmission = () => {
        setShowError(false);
        if (input.startDate > input.endDate) {
            toast.error("End date should be greater than Start date")
            return;
        }
        
        if (offerType === 2) {
           if(input.fixCashbackAmount < 3 ){
            toast.error("Fixed amount should be greater than 0")
            return;
        }
        if(input.fixCashbackAmount < 3 ){
            toast.error("Cashback amount should be greater than 0")
            return;
        }
        }
        if(offerType===1){  
        if (input.percentageAmount < 1 || input.percentageAmount > 100) {
            toast.error("Percentage  should be between 1 to 100")
            return;
        }
        if (input.maxValueOfPromotion <= 0 || input.maxCashback <= 0) {
            toast.error("Ammount shold be greater than 0 in MaxCashback and Maximum value of this promotion")
            return;
        }}
        
        

        if ((input.allowForNewUser === true || input.allowForRegisteredUser === true) === true
            && offerType=== 1 ? input.maxValueOfPromotion > 0: true
            && input.promotionName.length !== 0
            && input.startDate
            && offerType=== 1 ? input.maxCashback > 0 :true
            && offerType=== 1 ? input.percentageAmount:true
            
        ) {
            const token =jwt_decode(localStorage.getItem('token'))  
           const filterobj={
            "id":id,
            "name":input.promotionName,
            "startDate":input.startDate,
            "endDate":input.endDate,
            "maxCashbackAmount":input.maxCashback,
            "maxUtilizeValue":input.maxValueOfPromotion,
            "percentageOfAmount":input.percentageAmount,
            "allowForNewUsers":input.allowForNewUser,
            "allowForAllUsers":input.allowForRegisteredUser,
            "isActive":input.isActive,
            "totalAmountUtilized" : input.totalAmountUtilized,
            "offerType":offerType

            }
           
            if(offerType===2){ 
                filterobj.minimumFixAmount = parseInt(input.minimumFixAmount);
                filterobj.fixCashbackAmount=parseInt(input.fixCashbackAmount);

            }
            

            if(id) {
                filterobj.updatedBy= token.id;
                filterobj.id = id;

            }

            else {
            filterobj.createdBy= token.id;
        }
            
            ApiService.post(id?`v1/admin/updateCashbackOffer`:`v1/admin/addCashbackOffer`,filterobj)
                .then((response) => {
                    const responseMessage = response?.data?.message;
                    toast.success(responseMessage || `User offer ${id ? 'updated' : 'created'}!`);
                    navigate('/admin/CashbackOffer'); 
                    
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message || "Error");
                })
        } else {
            setShowError(true);
        }
    }

    if (fetchingData) {
        return (
            <div className='w-full h-full bg-white flex justify-center items-center'>
                <CircularProgress />
            </div>
        )
    }
  
    return (
        <div>
            <SectionTitle title="Cashback Offer" subtitle="Create / Cashback Offer" />
            <Widget>
                <Grid>
                    <form>
                        <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Promotion Type"
                                    size="small"
                                     value={offerType}
                                    style={{ width: '100%', marginTop: '10px', }}
                               
                                 onChange={(e) =>setOfferType(e.target.value)}
                                >
                                    <MenuItem  value={1}>Percentage</MenuItem>
                                    <MenuItem value={2}>Fixed</MenuItem>
                                    

                                </TextField>
                            </Grid>
                        <Grid item xs={12} md={6}>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField


                                    label="Promotion Name"
                                    fullWidth
                                    name='promotionName'
                                    value={input.promotionName}
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, promotionName: event.target.value
                                        })
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <FormControlLabel
                                    control={<Checkbox
                                        color="primary"
                                        name="isActive"
                                        checked={input.allowForNewUser}
                                        disabled={input.allowForRegisteredUser === true}

                                        onChange={(event) => {
                                            setInput({
                                                ...input, allowForNewUser: event.target.checked
                                            })
                                        }}
                                    />}
                                    label="Allow for new user"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        color="primary"
                                        name="isActive"
                                        disabled={input.allowForNewUser === true}
                                        checked={input.allowForRegisteredUser}

                                        onChange={(event) => {
                                            setInput({
                                                ...input, allowForRegisteredUser: event.target.checked
                                            })
                                        }}
                                    />}
                                    label="Allow for all registered user"
                                />


                            </Grid>
                            {/* <Grid item xs={12} md={6}> {input.bannerImage &&<img src={input?.bannerImage} width={30} />}</Grid> */}

                            <Grid item xs={12} md={6}>
                                <TextField
                                    error={showError && !input.startDate}
                                    helperText={showError && !input.startDate && "Required"}
                                    label="Start Date"
                                    fullWidth
                                    type="date"
                                    name='startDate'
                                    value={moment(input.startDate).format("YYYY-MM-DD")}
                                    onChange={(event) => {
                                        setInput({
                                            ...input, startDate: event.target.value
                                        })
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    error={showError && !input.endDate}
                                    helperText={showError && !input.endDate && "Required"}
                                    label="End Date"
                                    fullWidth
                                    type="date"
                                    name='endDate'
                                    value={moment(input.endDate).format("YYYY-MM-DD")}
                                    onChange={(event) => {
                                        setInput({
                                            ...input, endDate: event.target.value
                                        })
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required
                                />
                            </Grid>


                            {offerType === 1 &&  <Grid item xs={12} md={6}>
                                <TextField
                                    error={showError && !input.maxCashback}
                                    helperText={showError && !input.maxCashback && "Required"}
                                    label="Max cashback"
                                    fullWidth
                                    disabled={offerType === "2"}
                                    type="number"
                                    name='maxCashback'
                                    value={input.maxCashback}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, maxCashback: event.target.value
                                        })
                                    }}
                                    required
                                />
                            </Grid>}



                            {offerType === 1 && <Grid item xs={12} md={6}>
                                <TextField
                                    error={showError && !input.maxValueOfPromotion}
                                    helperText={showError && !input.maxValueOfPromotion && "Required"}
                                    label="Maximumu value of this promotion"
                                    fullWidth
                                    type="number"
                                    disabled={offerType === "2"}
                                    name='maxValueOfPromotion'
                                    value={input.maxValueOfPromotion}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, maxValueOfPromotion: event.target.value
                                        })
                                    }}
                                    required
                                />
                            </Grid>}
                        {offerType === 2 && <Grid item xs={12} md={6}>
                                <TextField
                                    error={showError && !input.minimumFixAmount}
                                    helperText={showError && !input.minimumFixAmount && "Required"}
                                    label="Fixed Amount"
                                    fullWidth
                                    type="number"
                                    name='minimumFixAmount'
                                    value={input.minimumFixAmount}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, minimumFixAmount: event.target.value
                                        })
                                    }}
                                    required
                                />
                            </Grid>}
                            {offerType === 2 && <Grid item xs={12} md={6}>
                                <TextField
                                    error={showError && !input.fixCashbackAmount}
                                    helperText={showError && !input.fixCashbackAmount && "Required"}
                                    label="Cash back amount"
                                    fullWidth
                                    type="number"
                                    name='fixCashbackAmount'
                                    value={input.fixCashbackAmount}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, fixCashbackAmount: event.target.value
                                        })
                                    }}
                                    required
                                />
                            </Grid>}
                            {offerType === 1 && <Grid item xs={12} md={6}>
                                <TextField
                                    error={showError && !input.percentageAmount}
                                    helperText={showError && !input.percentageAmount && "Required"}
                                    label="Percentage of amount to be add"
                                    fullWidth
                                    type='number'
                                    name='percentageAmount'
                                    value={input.percentageAmount}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, percentageAmount: event.target.value
                                        })
                                    }}
                                    required
                                />
                            </Grid>}





                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    control={<Checkbox
                                        color="primary"
                                        name="isActive"
                                        checked={input.isActive}
                                        onChange={(event) => {
                                            setInput({
                                                ...input, isActive: event.target.checked
                                            })
                                        }}
                                    />}
                                    label="Active"
                                />
                            </Grid>
                            <Grid item xs={12} md={12}
                                className="flex flex-row justify-end gap-4"
                            >
                                <Button
                                    variant="contained"
                                    onClick={() => { navigate('/admin/CashbackOffer', { replace: true }) }}
                                    size="medium"
                                    startIcon={<ArrowBack />}>
                                    Back
                                </Button>
                                {/* { ((input.totalAmountUtilized === 0))  && */}
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleSubmission}
                                    size="medium" >
                                    Submit
                                </Button>
{/* } */}
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Widget>
        </div>
    )
}

