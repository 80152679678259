import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { IconButton, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ApiService from '../../../api/ApiService';
import SectionTitle from '../../../components/section-title';
import Widget from '../../../components/widget';

import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { ExportToCsv } from 'export-to-csv';
import SearchIcon from '@mui/icons-material/Search';
import SketchExample from './ColorPicker';
import { options, setSorting } from '../helper/helper';
import excelIcon from '../../../images/excel.png';
const Teams = () => {
    const [teamList, setTeamList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setIsFetching] = useState(false);
    const [labelSearch, setLabelSearch] = React.useState('');
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const [color, setColor] = useState(null);
    const [startColor, setStartColor] = useState(null);
    const [EndColor, setEndColor] = useState(null);
    const processDataForCSV =(csvData)=>{

        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            
               delete csvRow.thumb_url;
               
        }
        return csvData;
    
    }
    const getTeamList = (currentIndex, search, sort, size,csv) => {
      let payload = {}
        if (csv) {
            payload = {
                "filterOption": labelSearch,
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size }
            }

        } else {
         
        }
         payload = {
            "filterOption": search || labelSearch,
            "sortOption": sort || sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getAllTeams`, payload).then((response) => {
            if (response.status === 200) {
                if (csv) {
                    setIsFetching(false);
                    options.filename = 'Teams'
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(processDataForCSV(response.data.body));
                } else {
                    setIsFetching(false);
                    setTeamList(response.data.body);
                }
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const uploadColor = (id, oldColor,flgStartColor,flgEndColor) => {
        if ((color === null || color === undefined) && (oldColor === null || oldColor === undefined)) {
            toast.error('please select color')
             return;
        }
        const payload = {
            tid: id,
            teamColor: color ? color : oldColor,
            flgStartColor:startColor?startColor:flgStartColor,
            flgEndColor:EndColor?EndColor:flgEndColor
        }        
        ApiService.post(`v1/admin/updateTeamColor`, payload).then((response) => {
            if (response.status === 200) {
                setTeamList(response.data.body);
                toast.success(response.data.message)
                getTeamList()
                setColor(null);
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }  


    const columns = [
        { field: 'title', headerName: `Name`, flex: 1 },
        {
            field: 'abbr', headerName: 'Short Name', flex: 1
        },
        {
            field: 'country', headerName: 'Country Code', flex: 1
        },
        // {
        //     field: 'alt_name', headerName: 'Alternative Name', flex: 1
        // },
        // {
        //     field: 'type', headerName: 'Type', flex: 1
        // },
        {
            field: 'thumb_url', headerName: 'Flag', flex: 1,
            renderCell: (params) => {
                if (params.row.thumb_url) {
                    return <img src={params.row.thumb_url} width={30} />
                }
            }
        },
        {
            field: 'flgStartColor', headerName: 'Start Color', flex: 1,
            renderCell: (params) => {
               
                return <>
                 <SketchExample data={params.row.flgStartColor} setcolor={setStartColor} />
                </>
            }
        }, {
            field: 'flgEndColor', headerName: 'End Color', flex: 1,
            renderCell: (params) => {
               
                return <>
                 <SketchExample data={params.row.flgEndColor} setcolor={setEndColor} />
                </>
            }
        },
        
        {
            field: 'teamColor', headerName: 'Enter Color', flex: 1,
            renderCell: (params) => {
               
                return <>
                 <SketchExample data={params.row.teamColor} setcolor={setColor} />
                </>
            }
        },
        {
            field: 'action', headerName: 'Action', flex: 1, width: 50,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Add Color">
                        <IconButton onClick={() => uploadColor(params.row.tid, params.row.teamColor,params.row.flgStartColor,params.row.flgEndColor)}>
                            <SaveIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        },
    ];
    const handleSearch = () => {
        getTeamList(0, '');
    }
    useEffect(() => {
        getTeamList(0, '');
    }, [])
    const downloadList = () => {
        getTeamList(0, '', '', 100000,' ');
    }
    return (
        <>
            <SectionTitle title="Teams" subtitle="Teams"></SectionTitle>
            <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', paddingLeft: '1%' }}>
                        <TextField id="standard-basic" label="Keywords" variant="standard" style={{ width: '30%' }}
                            onChange={(e) => {
                                setLabelSearch(e.target.value);
                            }} />
                                <img src={excelIcon} width='30px' height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: '18px 5px 0 0',
                            cursor:'pointer',
                            margin:15
                            
                        }}  onClick={() => downloadList()}/>    
                        <Button variant="contained"
                            style={{  margin: '1%' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<SearchIcon />}
                            onClick={() => handleSearch()}>
                            Search
                        </Button>
                    
                        {/* <Button variant="contained"
                            style={{ float: 'right', margin: '1%' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<img src={excelIcon} width='30px' height='30px' />}
                           >
                           
                        </Button> */}
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%', height: '100px !important' }}
                            loading={isLoading}
                            rows={teamList}
                            pagination
                            pageSize={pageSize}
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getTeamList(0, '', sort);
                                setSortOption(sort)
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getTeamList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
    )
}
export default Teams