import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ApiService from '../../../api/ApiService';
import SectionTitle from '../../../components/section-title';
import Widget from '../../../components/widget';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ExportToCsv } from 'export-to-csv';
import { options, setSorting } from '../helper/helper';
import excelIcon from '../../../images/excel.png';
import MenuItem from '@mui/material/MenuItem';
const PlayerManager = () => {
    const [pageSize, setPageSize] = useState(10);
    const [playerList, setPlayerList] = useState([]);
    const [isLoading, setIsFetching] = useState(false);
    const [fileObject, SetFileObject] = useState(null);
    const [labelSearch, setLabelSearch] = useState({"keyword":"","country":""});
    const [total, setTotal] = useState(null)
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const [countryList, setCountryList] = useState(null);
    const processDataForCSV =(csvData)=>{

        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            if(Array.isArray(csvRow.team)) {
               csvRow.Title = csvRow.team[0].title;
               delete csvRow.team;
               delete csvRow.playerImages;
            }
                         
        }
        return csvData;
    
    }
    const getPlayerList = (currentIndex, search, sort, size,csv) => {
          let payload = {}
      
        if (csv) {
            payload = {
                "filterOption": labelSearch,
                "sortOption": sort||sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size }
            }

        } else {
         
        }
         payload = {
            "filterOption": labelSearch || search,
            "sortOption":  sort||sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getPlayerData`, payload).then((response) => {
            if (response.status === 200) {
                if (csv) {
                    setIsFetching(false);
                    options.filename = 'player'
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(processDataForCSV(response.data.body.data));
                } else{
                    setIsFetching(false);
                    setPlayerList(response.data.body.data);
                    setTotal(response.data.body.recordCount)
                }
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const getCountryList = (currentIndex, search, sort, size) => {
   
   
      ApiService.post(`v1/admin/getPlayersCountry`, '').then((response) => {
      
          if (response.status === 200) {
              
             setCountryList(response.data.body)
         
          } else {
              toast.error(response.data.message)
          }
          
      }).catch((error) => {
          toast.error(error.response.data.message);

      });
  }
    const setImage = (e) => {
        if (e.target.files.length) {
            let fileSize = parseFloat(e.target.files[0].size / (1024 * 1024)).toFixed(2);
            if (fileSize > 1) {
                toast.error('please select file having 1 or less than 1 mb')
                SetFileObject(null)
                return false
            } else {
                toBase64(e.target.files[0]).then(
                    data => SetFileObject(data)
                );
            }
        }

    }
    const uploadImage = (params) => {
        if (fileObject) {
            const payload = {
                pid: params?.pid,
                name: params?.title,
                imageBase64: fileObject
            }
            ApiService.post(`v1/admin/insertPlayerImages`, payload).then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message)
                    getPlayerList(0, '');
                    SetFileObject(null);
                } else {
                    SetFileObject(null);
                    toast.error(response.data.message)
                }
                setIsFetching(false);
            }).catch((error) => {
                SetFileObject(null);
                toast.error(error.response.data.message);
                setIsFetching(false);
            })
        } else {
            toast.error('Please select File')
            return false
        }
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const columns = [
        { field: 'title', headerName: `Name`, flex: 1},
        {
            field: 'nationality', headerName: 'Nationality', flex: 1
        },
        {
            field: 'playing_role', headerName: 'Type', flex: 1
        },
        {
            field: 'team',
            valueGetter: (params) => {
                if (params.row.team.length > 0) {
                    let teams = ''
                    for (let i = 0; i < params.row.team.length; i++)
                        teams = teams + (i === 0 ? "" : ", ") + params.row.team[i].title ;
                    return teams;
                }
                return "";
            }, headerName: 'Team', flex: 1,
            sortable: false
        },
        {
            field: 'playerImages', headerName: 'Image', flex: 1,
            renderCell: (params) => {
                if (params.row.playerImages[0]) {
                    return <img src={params?.row?.playerImages[0]?.imageBase64} width={30} />
                }
            },
            sortable: false
        },
        {
            field: '', headerName: 'Upload Image', flex: 1,
            renderCell: (params) => {
                if (params.row.id) {
                    return <input type='file' onChange={(e) => setImage(e)} accept="image/webp,image/png, image/gif, image/jpeg" />
                }
            },
            sortable: false
        },
        {
            field: 'action', headerName: 'Action', flex: 1, width: 50,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Upload">
                        <IconButton
                            onClick={() => uploadImage(params.row)}  >
                            <DriveFolderUploadIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
            sortable: false
        },
    ];

    useEffect(() => {
        getPlayerList(0, '');
        getCountryList();
    }, [])
    const downloadList = () => {
        getPlayerList(0, '', '', 100000,' ');
    }
    return (
        <>
            <SectionTitle title="Players" subtitle="Player Manager"></SectionTitle>
            <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', padding: '1%' }}>
                        <TextField id="standard-basic" label="Player name" variant="standard" style={{ width: '30%' }}
                            onChange={(e) => {
                                setLabelSearch({...labelSearch,'keyword':e.target.value})
                            }
                            } />
                     <TextField
                            id="outlined-select-currency"
                            select
                            label="Filter"
                            size="small"
                            value={labelSearch.country!=undefined?labelSearch.country:0}
                            style={{ width: '20%', marginTop: '12px', marginLeft: '30px' }}
                               onChange={(e) => {
                                setLabelSearch({...labelSearch,"country":e.target.value})
                            }}
                        >
                               <MenuItem value={"All"||0}>All</MenuItem>
                                    {countryList!==null && countryList.map((option,index) => (
                                        <MenuItem  value={countryList[index]}>
                                            {countryList[index]}
                                        </MenuItem>
                                    ))}


                        </TextField>
                            <img src={excelIcon} width='30px' height='30px' style={{
                            display: 'inline-flex',  
                            float: 'right',
                            margin: '18px 5px 0 0',
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                       
                        <Button variant="contained"
                            style={{  margin: '1%' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<SearchIcon />}
                            onClick={() => getPlayerList(0, '')}
                        >
                            Search
                        </Button>
                       
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%', height: '100px !important' }}
                            rowCount={total}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={playerList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getPlayerList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getPlayerList(0, '', sort);
                                setSortOption(sort);
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getPlayerList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
    )
}
export default PlayerManager