import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../components/section-title';
import MasterTable from '../../../components/MasterTable/MasterTable';
import Datepicker from '../../../components/datepicker'
import Widget from '../../../components/widget';
import ApiService from '../../../api/ApiService';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import { Button, Grid, TextField } from '@mui/material';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import {   IconButton, Tooltip } from '@mui/material';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import {
    FaDollarSign,
    FaRegAddressCard
  } from 'react-icons/fa'
  import SettlementRequestDialog from '../../../components/dialogs/SettlementRequest';
import { setSorting } from '../helper/helper';
import { DataGrid } from '@mui/x-data-grid';
const UsersPaymentSettlement = () => {
    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setDate(d.getDate() - 15);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const navigate = useNavigate();
    const [usersPaymentSettlement, setUsersPaymentSettlementList] = useState([]);
    const [labelSearch, setLabelSearch] = useState('');
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const [amountData, setAmountData] = useState('');
    const [startDate, setStartDate] = useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [pageSize, setPageSize] = useState(10);
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MMM-DD'));
    const [isLoading, setIsFetching] = useState(false);
    const [total, setTotal] = useState(null)
    const [requestDialog, setRequestDialog] = React.useState(false);
    const [reason, setReason] = useState(null)
    const [data, setData] = React.useState(null);
    const [index, setIndex] = React.useState(null);
    const openRequestDialog = (value) => {
        setData(value);
        setRequestDialog(true);
    }
    const closeRequestDialog = () => {
        setRequestDialog(false);
    }


   


    const getUsersPaymentSettlementList = (currentIndex, search, sort, size) => {
        setIndex(currentIndex)
        
        const payload = {
            "filterOption": {
                "keyword": labelSearch || search,
                "startDate": startDate,
                "endDate": endDate
            },
            "sortOption": sort || sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : 10 }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getAdminMannualPayouts`, payload).then((response) => {
            if (response.status === 200) { 
                setUsersPaymentSettlementList(response.data.body.data);
                setTotal(response.data.body.recordCount)
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const acceptSettlementRequest = (id) => { 
        const loginuser = JSON.parse(localStorage.getItem('user'));
         const payload = {
             "id":id,
             "settledBy":loginuser?.id,
             "remarks":reason
          
         }
             ApiService.post(`v1/admin/settleManualPayout`, payload).then((response) => {
            
             if (response.status === 200) {
                 toast.success(response.data.message);
                 closeRequestDialog();
                 getUsersPaymentSettlementList(index, '')
             } else {
                 toast.error(response.data.message)
             }
             setIsFetching(false);
         }).catch((error) => {
             toast.error(error.response.data.message);
             setIsFetching(false);
         });
     }
    const columns = [
        {
            field: 'Users.name', headerName: `Name`, flex: 1,
            renderCell: (params) => {
                return params?.row?.Users[0]?.name
            }
        },
        {
            field: 'Users.email', headerName: 'Email', flex: 1,
            renderCell: (params) => {
                return params?.row?.Users[0]?.email
            },
        },
        {
            field: 'amount', headerName: 'Amount', flex: 1,
            renderCell: (params) => {
                if(params.row.amount !== null &&params.row.amount !== undefined ){
                return <div style={{ color: params.row.isCredit === false ? "red" : "green" }}>
                    ₹ {params.row.amount.toLocaleString()}
                    </div>
                }
            },



        },
       
        {
            field: 'settlementRemark', headerName: 'Note', flex: 1
        },
        {
            field: 'hasSettled', headerName: 'Has settled', flex: 1,
            renderCell: (params) => {
                return params.row.hasSettled === true ?"True":"False" 
            },
        },
        {
            field: 'createdOn', headerName: 'Created At', flex: 1,
            renderCell: (params) => {
                return moment(params.row.createdOn).format('MM/DD/YYYY');
            },
        },
        
        {field: 'action', headerName: 'Action', flex: 1,
            
        renderCell: (params) => (
            
            <div>
               { params.row.hasSettled === false && <Tooltip title="Settle">
                    <Button   style={{ background: 'rgb(227 112 14)', color: 'white' }} onClick={() => { openRequestDialog(params.row) }} >
                 Settle
                    </Button>
                </Tooltip>}
               
            </div>
        )
    },

    ];
    const handleSearch = () => {
        getUsersPaymentSettlementList(0, '');
    }
    useEffect(() => {
        getUsersPaymentSettlementList(0, '')
    }, [])
    return (
        <>
            <SectionTitle title='Users Payment Settlement' subtitle='Users Payment Settlement'></SectionTitle>
            <Widget>
          
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '1%', padding: '1%' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }}>
                            <Grid item xs={1} sm={4} md={2}>
                                <TextField id="standard-basic" label="Search" variant="standard" style={{ width: '100%' }} onChange={(e) => {
                                    setLabelSearch(e.target.value)
                                }} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={startDate} onChange={(e)=>setStartDate(e.format('YYYY-MMM-DD'))}  label={'Start Date'} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={endDate} onChange={(e)=>setEndDate(e.format('YYYY-MMM-DD'))}  label={'End Date'} />
                            </Grid>
                            

                            <Grid item xs={1} sm={4} md={2}>
                                <Button variant="contained"
                                    style={{ marginTop: '17px' }}
                                    className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                    startIcon={<SearchIcon />}
                                    onClick={() => handleSearch()}
                                >
                                    Search
                                </Button>
                            </Grid>
                         
                        </Grid>

                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                            <DataGrid
                            style={{ width: '100%', height: '100px !important' }}
                            loading={isLoading}
                            rows={usersPaymentSettlement}
                            rowCount={total}
                            pagination
                            pageSize={pageSize}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getUsersPaymentSettlementList(skip, labelSearch)
                            }}
                            sortingMode="server"
                            paginationMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getUsersPaymentSettlementList(0, '', sort);
                                setSortOption(sort);
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getUsersPaymentSettlementList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                        </div>
                    {/* <MasterTable isLoading={isLoading} list={usersPaymentSettlement} total={total} getList={getUsersPaymentSettlementList} labelSearch={labelSearch} columns={columns} /> */}
                </Paper>
                <SettlementRequestDialog 
                 open={requestDialog} 
                 data={data}
                 handleClose={closeRequestDialog}
                 setReason={setReason}
                 acceptSettlementRequest={acceptSettlementRequest}
                 />
            </Widget>
        </>
    )
}
export default UsersPaymentSettlement