import * as React from 'react';
import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import Widget from '../../../components/widget';
import ApiService from '../../../api/ApiService';
import SectionTitle from '../../../components/section-title';
import { setSorting } from '../helper/helper';

export default function FetchEmailList() {
    const [pageSize, setPageSize] = React.useState(10);
    const [emailList, setEmailList] = React.useState([]);
    const [isLoading, setIsFetching] = React.useState(false);
    const [totalRecord, setTotalRecord] = React.useState(null);
    const [sortOption, setSortOption] = React.useState({name : 1});
    const [labelSearch, setLabelSearch] = useState('');
    const navigate = useNavigate();

    const getEmailList = (currentIndex, search, sort, size) => {
        
        const payload = {
            "filterOption": search,
            "sortOption": sort || sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size? size : pageSize }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getEmailTemplate`, payload).then((response) => {
            if (response.status === 200) {
                setEmailList(response.data.body.data);
                setTotalRecord(response.data.body.recordCount)
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const columns = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'subject', headerName: 'Subject', flex: 1 },       
        { field: 'availablePlaceholder', headerName: 'Available Placeholder', flex: 1 },
        {
            field: 'action', headerName: 'Action', flex: 1,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Edit">
                        <IconButton
                            onClick={() =>
                                (navigate(`/admin/edit-email/${params.id}`))}  >
                            <ModeEditIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        },
    ];
    React.useEffect(() => {
        getEmailList(0, '')
    }, [])
    return (
        <>
            <SectionTitle title="Email" subtitle="Manage Emails"></SectionTitle>
            <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '10px' }}>
                        <TextField id="standard-basic" label="Search" variant="standard" style={{ width: '30%' }}
                            onChange={(e) => (getEmailList(0, e.target.value))} />
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            style={{ width: '100%' }}
                            rowCount={totalRecord}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={emailList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getEmailList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getEmailList(0, '', sort)
                                setSortOption(sort);
                            }}
                            paginationMode="server"
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getEmailList(0, labelSearch , '',newPageSize)
                            }}
                            rowsPerPageOptions={[10,20, 50, 100]}
                            columns={columns}

                        />
                    </div>
                </Paper>

            </Widget>
        </>
    );
}