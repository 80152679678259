import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Button, Checkbox, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ApiService from '../../../api/ApiService';
import SectionTitle from '../../../components/section-title';
import Widget from '../../../components/widget';
import { ExportToCsv } from 'export-to-csv';
import { options, setSorting } from '../helper/helper';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
const ManagePlayer = () => {
    const [pageSize, setPageSize] = useState(20);
    const [playerList, setPlayerList] = useState([]);
    const [isLoading, setIsFetching] = useState(false);
    const [teamAname, setteamAname] = useState([])
    const [teamBname, setteamBname] = useState([])
    const [allAPlayersLength, setallAPlayersLength] = useState(true);
    const [allBPlayersLength, setallBPlayersLength] = useState(true);
    const [allAPlayers, setallAPlayers] = useState([]);
    const [allBPlayers, setallBPlayers] = useState([]);
    
    const params = useParams();
    const navigate = useNavigate();
    const getPlayerList = () => {
        
        const payload = {
            "matchId": parseInt(params.id)
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getAllMatchPlayersByTeam`, payload).then((response) => {
            if (response.status === 200) {
                     setIsFetching(false);
                    setPlayerList(response.data.body.matchDetails);
                    setteamAname(response.data.body.matchDetails[0].teamA.teamDetails?.name);
                    setteamBname(response.data.body.matchDetails[0].teamB.teamDetails?.name);
                    // setTotal(response.data.body.recordCount)
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    
    const addedForMatch = () => {
        
        if(allAPlayers.length < 10 && allBPlayers.length < 10){
            toast.error("all Players length should be 11")
            return;
        }
        const user = JSON.parse(localStorage.getItem('user'));
        let dataA=[];
        let dataB=[];
        for (let i = 0; i < allAPlayers.length; i++) {

            dataA.push({player_id:allAPlayers[i]})
        }
        for (let i = 0; i < allBPlayers.length; i++) {

            dataB.push({player_id:allBPlayers[i]})
        }

        const data = {
            "matchId": parseInt(params.id),
            "teamA":{
                "teamId": playerList[0]?.teamA?.teamDetails.team_id, "players":dataA
            },
            "teamB":{
                "teamId": playerList[0]?.teamB?.teamDetails.team_id, "players":dataB
            },
            "adminId":user.id
        }
        
        ApiService.post(`v1/admin/manuallyAnnounceMatch`, data).then((response) => {
            if (response.status === 200) {
                    toast.success(response.data.message);
                    navigate(`/admin/match-list`);
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    
    const addAPlayer =(pid)=>{
        var index = allAPlayers.indexOf(pid);
        if(index===-1){
            allAPlayers.push(pid)
        } else if(index!==-1) {
            allAPlayers.splice(index, 1)
        }
        setallAPlayersLength(!allAPlayersLength)
    }
    const addBPlayer =(pid)=>{
        var index = allBPlayers.indexOf(pid);
        if(index===-1){
            allBPlayers.push(pid)
        } else if(index!==-1) {
            allBPlayers.splice(index, 1)
        }
        setallBPlayersLength(!allBPlayersLength)
    }
    
    const columns = [
        {
            field: 'name',
            renderCell: (params) => {
                    return params.row?.name;  
            }, 
            headerName: teamAname, flex: 1
        },
        {
            field: 'role',
            renderCell: (params) => {
                    return params.row?.role;  
            }, headerName: 'Role', flex: 1
        },
        {
        field: 'disable_match1', headerName: `Is Announced?`, flex: 1,
        renderCell: (params) => {
        
return(
    <>
            <Checkbox
                checked={allAPlayers.filter((id) => id == params.row?.pid).length>0}
                disabled={allAPlayers.length > 10 && (allAPlayers.filter((id) => id == params.row?.pid).length===0)}
                onChange={() => addAPlayer(params.row?.pid, params.row?.teamid)}
                name="checkedB"
                color="primary"
            />
</>
)
}
    },
    ];
    const columns1 = [
        {
            field: 'name',
            renderCell: (params) => {
                    return params.row?.name;  
            }, 
            headerName: teamBname,
            flex: 1
        },
        {
            field: 'role',
            renderCell: (params) => {
                    return params.row?.role;  
            }, headerName: 'Role', flex: 1
        },
        {
        field: 'disable_match1', headerName: `Is Announced?`, flex: 1,
        renderCell: (params) => {
return(
    <>
            <Checkbox
                checked={allBPlayers.filter((id) => id == params.row?.pid).length>0}
                disabled={allBPlayers.length > 10 && (allBPlayers.filter((id) => id == params.row?.pid).length===0)}
                onChange={() => addBPlayer(params.row?.pid, params.row?.teamid)}
                name="checkedB"
                color="primary"
            />
</>
)
}
    },
    ];

    useEffect(() => {
        getPlayerList();
    }, [])
    
    return (
        <>
            <SectionTitle title="Announce" subtitle="Announced a Match"></SectionTitle>
            <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', padding: '1%' }}>
                        
                <Grid container spacing={2}>
                    <Grid item xs={4} md={4}>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            Match Title : {playerList[0]?.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <Typography gutterBottom variant="subtitle1" component="div">
                        Start Time: {moment(playerList[0]?.startDate).format('MMMM Do YYYY, h:mm:ss a')}
                        </Typography>
                    </Grid>
                    <Button variant="contained"
                                style={{
                                    display: 'inline-flex',
                                    float: 'right',
                                    margin: '10px 5px 0 0',
                                    cursor:'pointer',
                                    margin:15
                                }}
                                className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                onClick={() => addedForMatch()}
                            >
                                Save
                            </Button>
                            {/* </div> */}
                            </Grid>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '98%' ,flexDirection:"row",display:'flex',margin:10  }}>
                    <div style={{ minHeight: 700, height: 600, width: '100%' ,flexDirection:'column',display:'flex',margin:10  }}>
                        <text>Selected Players: {allAPlayers.length}</text>
                        <DataGrid
                            style={{ width: '100%' ,margin:5  }}
                            getRowId={(row) => row?.pid}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={playerList.length !== 0 && playerList[0]?.teamA?.players}
                            columns={columns}
                        />
                        </div>
                    <div style={{ minHeight: 700, height: 600, width: '100%' ,flexDirection:'column',display:'flex',margin:10  }}>
                        <text>Selected Players: {allBPlayers.length}</text>
                        <DataGrid
                            style={{ width: '100%' ,margin:5 }}
                            getRowId={(row) => row?.pid}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={playerList.length !== 0 && playerList[0]?.teamB?.players}
                            columns={columns1}
                        />
                        </div>
                    </div>
                    
                </Paper>
            </Widget>
        </>
    )
}
export default ManagePlayer