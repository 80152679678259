import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import { Box, IconButton } from "@mui/material";
import {
    PreviewOutlined,
    Add,
    ModeEdit,
    Close,
    Check,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { Button, MenuItem } from "@mui/material";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Widget from "../../../components/widget";
import ApiService from "../../../api/ApiService";
import SearchIcon from "@mui/icons-material/Search";
import SectionTitle from "../../../components/section-title";
import PanRequestDialog from "../../../components/dialogs/DetailDialog";
import RejectPanRequestDialog from "../../../components/dialogs/RejectPanRequestDialog";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { ExportToCsv } from "export-to-csv";
import { options, setSorting } from "../helper/helper";
import excelIcon from "../../../images/excel.png";
import Datepicker from "../../../components/datepicker";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Verified } from "@mui/icons-material";

const PanRequest = () => {
    let date = new Date();
    const Datefind = () => {
        var a = moment(date).format("MMM DD, YYYY ");
        var d = new Date(a);

        return d.setDate(d.getDate() - 30);
    };

    let finaldate = new Date(Datefind()).toString("MM, DD, YYYY");
    const [requestList, setRequestList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setIsFetching] = useState(false);
    const [labelSearch, setLabelSearch] = useState("");
    const [requestDialog, setRequestDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState(null);
    const [panImage, setPanImage] = useState(null);
    const [data, setData] = useState(null);
    const [totalRecord, setTotalRecord] = useState(null);
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const [startDate, setStartDate] = useState(
        moment(finaldate).format("YYYY-MMM-DD")
    );
    const [endDate, setEndDate] = useState(
        moment(new Date()).format("YYYY-MMM-DD")
    );
    const [requestStatusType, setRequestStatusType] = React.useState("Pending");

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const openRequestDialog = (value) => {
        setData(value);
        setRequestDialog(true);
    };
    const closeRequestDialog = () => {
        setRequestDialog(false);
    };
    const processDataForCSV = (csvData) => {
        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];

            delete csvRow.statusDetails;
            delete csvRow.id;
        }
        return csvData;
    };
    const getPanRequestList = (currentIndex, search, sort, size, csv) => {
        let payload = {};
        if (csv) {
            payload = {
                filterOption: labelSearch || search,
                sortOption: sort || sortOption,
                pageSizeOption: {
                    skip: parseInt(currentIndex),
                    limit: size ? size : pageSize,
                },
            };
        } else {
        }
        payload = {
            filterOption: {
                keywords: labelSearch || search,
                startDate: startDate,
                endDate: endDate,
                status: requestStatusType,
            },
            sortOption: sort || sortOption,
            pageSizeOption: {
                skip: parseInt(currentIndex),
                limit: size ? size : pageSize,
            },
        };
        setIsFetching(true);
        ApiService.post(`v1/admin/getUsersPANRequest`, payload)
            .then((response) => {
                if (response.status === 200) {
                    if (csv) {
                        setIsFetching(false);
                        options.filename = "Pan requests";
                        const csvExporter = new ExportToCsv(options);
                        csvExporter.generateCsv(processDataForCSV(response.data.body.data));
                    } else {
                        setIsFetching(false);
                        setRequestList(response.data.body.data);
                        setTotalRecord(response.data.body.recordCount);
                    }
                } else {
                    toast.error(response.data.message);
                }
                setIsFetching(false);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                setIsFetching(false);
            });
    };
    const getPanImage = (id) => {
        const payload = {
            id: id,
        };
        ApiService.post(`v1/admin/getUserPANRequestById`, payload)
            .then((response) => {
                if (response.status === 200) {
                    setPanImage(response.data.body.panImage);
                    handleClickOpen();
                } else {
                    toast.error(response.data.message);
                }
                setIsFetching(false);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                setIsFetching(false);
            });
    };
    const approveRejectRequest = (id, status) => {
        const payload = {
            id: id,
            status: status,
            reason: reason,
        };
        ApiService.post(`v1/admin/approveUserPANRequest`, payload)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                    closeRequestDialog();
                    getPanRequestList(0, "");
                } else {
                    toast.error(response.data.message);
                }
                setIsFetching(false);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                setIsFetching(false);
            });
    };
    const verifyPanOnCashfree = (id) => {
        const payload = {
            id: id,
        };

        ApiService.post(`v1/admin/verifyPanOnCashfree`, payload)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    toast.success(response.data.body.status);
                    closeRequestDialog();
                    getPanRequestList(0, "");
                } else {
                    toast.error(response.data.body.status);
                }
                setIsFetching(false);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                setIsFetching(false);
            });
    };
    const columns = [
        { field: "name", headerName: `Name`, flex: 1 },
        {
            field: "panNumber",
            headerName: "Pan Number",
            flex: 0.7,
        },
        {
            field: 'mobile', headerName: `mobile`, flex: 1,
            renderCell: (params) => { return params.row.Users[0]?.mobile; }
        },
        {
            field: "dob",
            headerName: "DOB",
            flex: 0.6,
            valueGetter: (params) => {
                if (params.row.dob) {
                    return moment(params.row.dob).format("MM/DD/YYYY");
                }
                return "";
            },
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0.5,
        },
        {
            field: "createdOn",
            headerName: "Created At",
            flex: 0.6,
            valueGetter: (params) => {
                if (params.row.createdOn) {
                    return moment(params.row.createdOn).format("MM/DD/YYYY");
                }
                return "";
            },
        },
        {
            headerName: "Cashfree Status",
            flex: 0.7,
            valueGetter: (params) => {
                console.log(
                    params.row.status,
                    params.row.validatedOnCashfree,
                    params.row.validatedOnCashfree
                );
                return params.row.validatedOnCashfree
                    ? "Matched"
                    : params.row.validatedOnCashfree === null ||
                        params.row.validatedOnCashfree === undefined
                        ? "Not tested"
                        : "Unmatched";
            },
        },

        {
            field: "type",
            headerName: "Action",
            flex: 2,
            renderCell: (params) => (
                <div>
                    <Tooltip title="View Pan Details">
                        <IconButton
                            style={{
                                background: "#1976d2",
                                color: "white",
                                height: "29px",
                                width: "29px",
                                margin: "0 10px",
                            }}
                            onClick={() => getPanImage(params.row.id)}
                        >
                            <PreviewOutlined />
                        </IconButton>
                    </Tooltip>
                    {params.row.status === "Pending" ? (
                        <>
                            <Tooltip title="Approve Request">
                                <IconButton
                                    style={{
                                        background: "rgb(89 187 89)",
                                        height: "28px",
                                        width: "28px",
                                        color: "white",
                                        margin: "0 10px",
                                    }}
                                    onClick={() =>
                                        approveRejectRequest(params.row.id, "Approved", "")
                                    }
                                >
                                    <Check style={{}} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Reject Request">
                                <IconButton
                                    style={{
                                        background: "rgb(227 71 46)",
                                        height: "28px",
                                        width: "28px",
                                        color: "white",
                                        margin: "0 10px",
                                    }}
                                    onClick={() => {
                                        openRequestDialog(params.row);
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        </>
                    ) : null}
                    {params.row.status === "Pending" &&
                        (params.row.validatedOnCashfree === undefined ||
                            params.row.validatedOnCashfree === null) && (
                            <Tooltip title="Check on cashfree">
                                <Button
                                    variant="outlined"
                                    style={{
                                        background: "rgb(89 187 89)",
                                        color: "white",
                                        margin: "0 10px",
                                    }}
                                    onClick={() => verifyPanOnCashfree(params.row.id)}
                                >
                                    Chek on Cashfree
                                </Button>
                            </Tooltip>
                        )}
                </div>
            ),
        },
    ];
    const handleSearch = () => {
        getPanRequestList(0, "");
    };
    useEffect(() => {
        getPanRequestList(0, "");
    }, []);
    const downloadList = () => {
        getPanRequestList(0, "", "", 100000, " ");
    };
    return (
        <>
            <SectionTitle title="Pan Request" subtitle="Pan Request"></SectionTitle>
            <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: "100%", margin: "1%", padding: "1%" }}>
                        <Grid container spacing={{ xs: 2, md: 2 }}>
                            <Grid item xs={1} sm={4} md={2}>
                                <TextField
                                    id="standard-basic"
                                    label="Search by Name"
                                    variant="standard"
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        setLabelSearch(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                <TextField
                                    id="outlined-select-request-status"
                                    select
                                    label="Filter"
                                    value={requestStatusType}
                                    onChange={(e) => setRequestStatusType(e.target.value)}
                                    size="small"
                                    style={{ width: "100%", marginTop: "15px" }}
                                >
                                    <MenuItem value={"Approved"}>Approved</MenuItem>
                                    <MenuItem value={"Rejected"}>Rejected</MenuItem>
                                    <MenuItem value={"Pending"}>Pending</MenuItem>
                                    <MenuItem value={"All"}>All</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                <Datepicker
                                    Value={startDate}
                                    onChange={(e) => setStartDate(e.format("YYYY-MMM-DD"))}
                                    label={"Start Date"}
                                />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                <Datepicker
                                    Value={endDate}
                                    onChange={(e) => setEndDate(e.format("YYYY-MMM-DD"))}
                                    label={"End Date"}
                                />
                            </Grid>

                            <Grid item xs={1} sm={4} md={2}>
                                <Button
                                    variant="contained"
                                    style={{ float: "right", margin: "1%", marginTop: "7%" }}
                                    className="[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]"
                                    startIcon={<SearchIcon />}
                                    onClick={() => getPanRequestList(0, "")}
                                >
                                    Search
                                </Button>
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                <img
                                    src={excelIcon}
                                    width="30px"
                                    height="30px"
                                    style={{
                                        display: "inline-flex",
                                        float: "right",
                                        margin: 15,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => downloadList()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: "100%" }}>
                        <DataGrid
                            style={{ width: "100%", height: "100px !important" }}
                            loading={isLoading}
                            rows={requestList}
                            rowCount={totalRecord}
                            pagination
                            pageSize={pageSize}
                            onPageChange={(index) => {
                                let skip = pageSize * index;
                                getPanRequestList(skip, labelSearch);
                            }}
                            sortingMode="server"
                            paginationMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getPanRequestList(0, "", sort);
                                setSortOption(sort);
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getPanRequestList(0, labelSearch, "", newPageSize);
                            }}
                            columns={columns}
                            getRowClassName={(params) =>
                                params.row.Users[0]?.hasAadharCardKYCByPassed === true ? 'hasAadharCardKYCByPassedBackColor' : ''
                            }
                        />
                    </div>
                </Paper>
            </Widget>
            <PanRequestDialog
                open={open}
                handleClose={handleClose}
                image={panImage}
            />
            <RejectPanRequestDialog
                open={requestDialog}
                data={data}
                handleClose={closeRequestDialog}
                rejectRequest={approveRejectRequest}
                setReason={(value) => setReason(value)}
            />
        </>
    );
};
export default PanRequest;
