export const setSorting=(sort)=>{
    let sorting = {}
    if (sort?.length > 0) {
        sort.map((s) => {
            let sortField = s.field;
            if (s.sort === 'asc') {
                sorting[sortField] = 1
            } else {
                sorting[sortField] = -1
            }
        })
    } else {
        sorting = {
            createdOn: -1
        }
    }
    return sorting;
}

export const options = {
    filename:'',
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: '',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
};


export const getDateDiff=(dateDiffInDay)=>{     

    return (new Date()).setDate(new Date().getDate() + dateDiffInDay);
   }
