import React, { useEffect, useState } from 'react'
import Widget1 from '../components/dashboard/widget-1'
import Section from '../components/dashboard/section'
import { Grid, Icon, IconButton, TextField } from '@mui/material';
import SectionTitle from '../components/dashboard/section-title'
import { FiActivity, FiUsers, FiExternalLink, FiClock } from 'react-icons/fi'
import { Bar1 } from '../components/dashboard/bar-chart'
import { Donut1 } from '../components/dashboard/donut-chart'
import { Line1 } from '../components/dashboard/line-chart'
import Dropdown1 from '../components/widgets/dropdown-1'
import Markets from '../components/dashboard/markets'
import { List } from '../components/dashboard/list'
import Tasks from '../components/tasks'
import { Timeline1 } from '../components/timelines'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ApiService from '../api/ApiService'
import moment from 'moment';
import Widget from '../components/widget';
import Datepicker from '../components/datepicker'
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { hasPermission } from '../Constants/CheckHasPermission';
import { DataGrid } from '@mui/x-data-grid';
import { setSorting } from './admin/helper/helper';

import {
    DASHBOARD
} from '../Constants/PermissionConstant';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    Legend
} from 'recharts'

const Index = () => {
    const navigate = useNavigate()
    const [data, setData] = useState();
    const [aadharFailed, setaAdharFailed] = useState([]);

    const [day, setday] = useState(2);
    const [balanceData, setBalanceData] = useState();
    const [usersVisitsData, setUsersVisitsData] = useState();
    const [isLoading, setIsFetching] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [sortOption, setSortOption] = useState({ updatedOn: -1 });
    const [listCount, setListCount] = React.useState(null);
    const getDashboardStatistics = async (date) => {
        const payload = {
            "Date": date ? date : moment(new Date().setDate(new Date().getDay() - 7)).format('YYYY ,MM, DD')
        }

        ApiService.post(`v1/admin/getDashboardStatistics`, payload).then((response) => {
            if (response.status === 200) {
                setData(response.data.body);

            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }
    const getAllAadharcardFailed = async (skip, newPageSize, date) => {
        setIsFetching(true)
        const payload = {
            "startDate": date ? date : handleMenuItem(day) ? handleMenuItem(day) : moment(new Date().setDate(new Date().getDay() - 7)).format('YYYY ,MM, DD'),
            "endDate": moment(new Date()).format('DD-MMM-YYYY'),
            "pageSizeOption": {
                "limit": newPageSize ? newPageSize : 10,
                "skip": skip || 0
            }
        }
        ApiService.post(`v1/admin/getAllaADharCardPendingFailed`, payload).then((response) => {
            if (response.status === 200) {
                setaAdharFailed(response.data.body.data);
                setListCount(response.data.body.recordCount);
                setIsFetching(false)
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {

            setIsFetching(false)
            toast.error(error.response.data.message);
        });

    }
    const getSystemBalanceHealthCheck = async () => {

        ApiService.post(`v1/admin/getSystemBalanceHealth`).then((response) => {
            if (response.status === 200) {

                setBalanceData(response.data.body);

            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    const getUserVisits = async () => {

        ApiService.post(`v1/admin/usersVisits`).then((response) => {
            if (response.status === 200) {
                setUsersVisitsData(response.data.body);
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }



    var apiLastAccess = moment(data?.appHealth?.apiLastAccessDate).format('MMM DD, YYYY HH:mm:ss');
    var webSiteLastAccess = moment(data?.appHealth?.webSiteLastAccessDate).format('MMM DD, YYYY HH:mm:ss');
    var pointCalcLastAccessDate = moment(data?.appHealth?.pointCalcLastAccessDate).format('MMM DD, YYYY HH:mm:ss');
    var entitySportsAPIAccessDate = moment(data?.appHealth?.entitySportsAPIAccessDate).format('MMM DD, YYYY HH:mm:ss');
    let adate = new Date().getMinutes() - new Date(apiLastAccess).getMinutes();
    let wdate = new Date().getMinutes() - new Date(webSiteLastAccess).getMinutes();
    let pdate = new Date().getMinutes() - new Date(pointCalcLastAccessDate).getMinutes();
    let edate = new Date().getMinutes() - new Date(entitySportsAPIAccessDate).getMinutes();

    useEffect(() => {
        if (window.location.href.indexOf('login=true') > 0) {
            window.location.href = '/admin/dashboard';
            return (<></>);
        }
        else {
            getDashboardStatistics();
            getSystemBalanceHealthCheck();
            getUserVisits();
            getAllAadharcardFailed()
        }

        if (Math.abs(adate) < 5 && Math.abs(wdate) < 5 && Math.abs(pdate) < 5 && Math.abs(edate) < 5) {

            toast.error(data?.appHealth?.apiURL)
        }
    }, []);



    let colors = [
        { dataKey: 'visits', stroke: '#2196f3' }
    ]


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleMenuItem = (value) => {
        switch (value) {
            case 0:
                getDashboardStatistics('0')
                setday(value)
                handleClose()
                getAllAadharcardFailed()
                break;
            case 1:
                getDashboardStatistics(moment(new Date()).format('YYYY ,MM, DD'));
                setday(value)
                handleClose()
                getAllAadharcardFailed('', '', moment(new Date()).format('YYYY ,MM, DD'))
                return moment(new Date()).format('YYYY ,MM, DD')
                break;
            case 2:
                getDashboardStatistics(moment(new Date().setDate(new Date().getDay() - 7)).format('YYYY ,MM, DD'))
                getAllAadharcardFailed('', '', moment(new Date().setDate(new Date().getDay() - 7)).format('YYYY ,MM, DD'))

                setday(value)
                handleClose()

                return moment(new Date().setDate(new Date().getDay() - 7)).format('YYYY ,MM, DD')
                break;
            case 3:
                getDashboardStatistics(moment(new Date().setDate(new Date().getDay() - 15)).format('YYYY ,MM, DD'))
                getAllAadharcardFailed('', '', moment(new Date().setDate(new Date().getDay() - 15)).format('YYYY ,MM, DD'))

                setday(value)
                handleClose()

                return moment(new Date().setDate(new Date().getDay() - 15)).format('YYYY ,MM, DD')
                break;
            case 4:
                getDashboardStatistics(moment(new Date().setMonth(new Date().getMonth() - 1)).format('YYYY ,MM, DD'))
                getAllAadharcardFailed('', '', moment(new Date().setMonth(new Date().getMonth() - 1)).format('YYYY ,MM, DD'))

                setday(value)
                handleClose()

                return moment(new Date().setMonth(new Date().getMonth() - 1)).format('YYYY ,MM, DD')
                break;
            case 5:
                getDashboardStatistics(moment(new Date().setMonth(new Date().getMonth() - 3)).format('YYYY ,MM, DD'))
                getAllAadharcardFailed('', '', moment(new Date().setMonth(new Date().getMonth() - 3)).format('YYYY ,MM, DD'))

                setday(value)
                handleClose()

                return moment(new Date().setMonth(new Date().getMonth() - 3)).format('YYYY ,MM, DD')
                break;
            default:
                break;
        }
        handleClose()
    }
    const fields = [
        {
            name: 'Date',

        },
        {
            name: 'Winning Posted Correctly?',

        },
        {
            name: 'Total Amount Added to Wallet',

        },
        {
            name: 'Total Contest Amount',
        },
        {
            name: 'Matches Completed',
        }
    ]
    const columns = [
        {
            field: 'name', headerName: `Name`, flex: 1,
            renderCell: (params) => (
                <div>{params?.row?.userKYC[0].name}</div>
            )
        },
        {
            field: 'email', headerName: `Email`, flex: 1,
            renderCell: (params) => (
                <div>{params?.row?.userKYC[0].email}</div>
            )
        },
        {
            field: 'mobile', headerName: `Mobile`, flex: 1,
            renderCell: (params) => (
                <div>{params?.row?.userKYC[0].mobile}</div>
            )
        },
        {
            field: 'aadharNumber', headerName: "AadharNumber", flex: 1
        },
        {
            field: 'createdOn', headerName: `CreatedOn`, flex: 1,
            renderCell: (params) => (
                moment(params?.row?.createdOn).format('MMM DD, YYYY HH:mm:ss')
            )
        },
        {
            field: 'status', headerName: "Status", flex: 1
        },



    ];
    const onRefresh = () => {
        ApiService.post(`v1/admin/checkAndUpdateEntitySports`,).then((response) => {
            if (response.status === 200) {
                window.location.reload()
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }
    return (
        <>
            <SectionTitle title="Overview" subtitle="Dashboard" right={<div className="flex flex-row ">
                <div className="text-xs uppercase font-dark text-black-500" style={{ marginTop: "8px" }}>{day === 1 ? "Today" : day === 2 ? "1 week" : day === 3 ? "15 days" : day === 4 ? "1 month" : day === 5 ? "3 month" : "ALL"}</div>

                <FilterAltIcon style={{ color: "#0047AB", cursor: "hand" }} onClick={handleClick} />

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    value={'2'}
                    onClose={handleClose}
                    onClick={(e) => handleMenuItem(e.target.value)}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem value={'0'}>All</MenuItem>
                    <MenuItem value={'1'}>Today</MenuItem>
                    <MenuItem value={'2'}>1 week</MenuItem>
                    <MenuItem value={'3'}>15 days</MenuItem>
                    <MenuItem value={'4'}>1 month</MenuItem>
                    <MenuItem value={'5'}>3 month</MenuItem>
                </Menu>

            </div>} />

            <Widget description={<span>System health</span>}>
                <div className="flex flex-col lg:flex-row w-full lg:space-x-1">
                    <div className=" lg:w-1/5">
                        <div className={`form-element true`}>
                            <div style={{ color: Math.abs(adate) < 5 ? "darkgreen" : "red" }}>API : {data?.appHealth?.apiURL}</div>

                            <div style={{ color: Math.abs(adate) < 5 ? "darkgreen" : "red" }}>{moment(data?.appHealth?.apiLastAccessDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                        </div>
                    </div>
                    <div className=" lg:w-1/4">
                        <div className={`form-element true`}>
                            <div style={{ color: Math.abs(wdate) < 5 ? "darkgreen" : "red" }}>WebSite: {data?.appHealth?.webSiteURL}</div>

                            <div style={{ color: Math.abs(wdate) < 5 ? "darkgreen" : "red" }}>{moment(data?.appHealth?.webSiteLastAccessDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                        </div>
                    </div>
                    <div className=" lg:w-1/4 ">
                        <div className={`form-element true`}>
                            <div style={{ color: Math.abs(pdate) < 5 ? "darkgreen" : "red" }}>Point calculator : {moment(data?.appHealth?.pointCalcLastAccessDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                            <div style={{ color: Math.abs(edate) < 5 ? "darkgreen" : "red" }}>EntitySports : {moment(data?.appHealth?.entitySportsAPIAccessDate).format('YYYY-MM-DD HH:mm:ss')} &nbsp;&nbsp; <RotateLeftIcon style={{ cursor: 'pointer' }} onClick={() => onRefresh()} /></div>

                        </div>
                    </div>

                </div>
            </Widget>
            <Widget description={<span>Balance health</span>}>


                <table className="table">
                    <thead>
                        <tr>
                            {fields.map((field, i) => (
                                <th style={{ textAlign: "center" }}>{field.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>

                        {balanceData && balanceData.map((field, i) => (<tr  >

                            <td style={{ textAlign: "center" }}>{moment(field.executionDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td style={{ textAlign: "center" }}>{field.matchCompleted.length > 0 ? field.isWinningPostedCorrectly === true ? "YES" : "NO" : "-"}</td>
                            <td style={{ textAlign: "center" }}>{field.totalAmountAddedToUsers}</td>
                            <td style={{ textAlign: "center" }}>{field.totalContestAmount}</td>
                            <td style={{ textAlign: "center" }}>{field.matchCompleted.length}</td>


                        </tr>
                        ))}
                    </tbody>
                </table>


            </Widget>

            <Widget description={<span>KYC Failed</span>}>
                <div style={{ minHeight: 500, height: 600, width: '100%' }}>
                    <DataGrid
                        style={{ width: '100%' }}
                        disableColumnMenu
                        loading={isLoading}
                        disableColumnFilter
                        rows={aadharFailed}
                        disableColumnSelector
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        columns={columns}
                        pageSize={pageSize}
                        onPageChange={(index) => {
                            let skip = pageSize * index;
                            getAllAadharcardFailed(skip)
                        }}
                        pagination
                        sortingMode="server"
                        onSortModelChange={(modal) => {
                            const sort = setSorting(modal);
                            setSortOption(sort);
                            getAllAadharcardFailed(0, sort);
                        }}
                        disableSelectionOnClick
                        paginationMode="server"
                        rowCount={listCount}
                        onPageSizeChange={(newPageSize) => {
                            setPageSize(newPageSize);
                            getAllAadharcardFailed(0, newPageSize);
                        }}
                        getRowId={(row) => row._id}
                    />
                </div>
            </Widget>

            <Widget description={<span>User Visits</span>}>

                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={usersVisitsData}
                            margin={{
                                top: 10,
                                right: 10,
                                left: 10,
                                bottom: 10
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" axisLine={false} tickLine={false} />
                            <YAxis axisLine={false} tickLine={false} width={30} />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend verticalAlign="top" height={36} />
                            {colors.map((color, i) => (
                                <Line
                                    type="monotone"
                                    dataKey={color.dataKey}
                                    stroke={color.stroke}
                                    strokeWidth={2}
                                    activeDot={{ r: 8 }}
                                />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </div>

            </Widget>



            {hasPermission(DASHBOARD) === true && <><div className="flex flex-col lg:flex-row w-full lg:space-x-12 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
                {/*widget*/}
                <div className="w-full lg:w-1/4">
                    <Widget1
                        title="Users"
                        description={data?.users}
                        right={
                            <FiUsers size={24} className="stroke-current text-grey-500" />
                        }
                    />
                </div>
                <div className="w-full lg:w-1/4">
                    <Widget1
                        title="User Team"
                        description={data?.userTeam}
                        right={
                            <FiUsers size={24} className="stroke-current text-grey-500" />
                        }
                    />
                </div>
                <div className="w-full lg:w-1/4">
                    <Widget1
                        title="User Contest"
                        description={data?.userContest}
                        right={
                            <FiUsers size={24} className="stroke-current text-grey-500" />
                        }
                    />
                </div>
            </div>
                <div className="flex flex-col lg:flex-row w-full lg:space-x-12 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
                    <div className="w-full lg:w-1/4">
                        <Widget1
                            title="Total Winning Amount"
                            description={data?.totalWinningAmount}
                            right={
                                <FiUsers size={24} className="stroke-current text-grey-500" />
                            }
                        />
                    </div>
                    <div className="w-full lg:w-1/4">
                        <Widget1
                            title="Total Deposite Amount"
                            description={data?.totalDepositeAmount}
                            right={
                                <FiUsers size={24} className="stroke-current text-grey-500" />
                            }
                        />
                    </div>
                    <div className="w-full lg:w-1/4">
                        <Widget1
                            title="Total Withdraw Amount"
                            description={data?.totalWithdrawAmount}
                            right={
                                <FiUsers size={24} className="stroke-current text-grey-500" />
                            }
                        />
                    </div>
                </div></>}
        </>
    )
}


const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        let { name, visits } = { ...payload[0].payload }
        return (
            <div className="bg-white text-grey-900 dark:bg-grey-800 dark:text-white shadow-lg rounded-lg p-2 text-xs">
                <div className="font-bold">{name}</div>
                <div>
                    <span className="font-bold">Visits:</span>{' '}
                    <span className="font-normal">{visits}</span>
                </div>
            </div>
        )
    }
    return null
}

export default Index
