import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ApiService from '../../../api/ApiService';
import Widget from '../../../components/widget';

const Settings = () => {
    const [isLoading, setIsFetching] = useState(false);
    const [input, setInput] = useState({
        bonusPercentage: 0,
        minDepositeAmountForBonus: 0,
        newUserBonus: 0,
        referrerBonus: 0,
    })
    const handleSubmit = () => {
        const payload = {
            bonusPercentage: input.bonusPercentage,
            minDepositeAmountForBonus: input.minDepositeAmountForBonus,
            newUserBonus: input.newUserBonus,
            referrerBonus: input.referrerBonus,
        }
        ApiService.post(`v1/admin/insertUserBonus`, payload).then((response) => {
            if (response.status === 200) {
                setIsFetching(false);
                toast.success(response.data.message);
            } else {
                setIsFetching(false);
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            setIsFetching(false);
            toast.error(error)
        });
    }
    const getUserBonus = () => {
        setIsFetching(true);
        ApiService.post(`v1/admin/getUserBonus`).then((response) => {
            if (response.status === 200) {
                if (response.data.body.length > 0) {
                        const data = response.data.body[0];
                        setInput(data)
                }
            } else {
            }
            setIsFetching(false);
        }).catch((error) => {
            setIsFetching(false);
        });
    }
    useEffect(() => {
        getUserBonus();
    }, [])

    return (
        <>
            <form>
                <Widget title="Settings" description={<span>Deposit Bonus</span>}>
                    <div className="flex flex-col lg:flex-row w-full lg:space-x-1">
                        <div className=" lg:w-1/4">
                            <div className={`form-element true`}>
                                <div className="form-label">Bonus Percentage</div>
                                <input
                                    name='bonusPercentage'
                                    type='number'
                                    min={0}
                                    className="form-input form-input-valid"
                                    placeholder='Please enter bonus percentage'
                                    onChange={(event) => {
                                        setInput({
                                            ...input, bonusPercentage: event.target.value
                                        })
                                    }}
                                    value={input.bonusPercentage}
                                />
                                <div className="form-success"></div>
                            </div>
                        </div>
                        <div className=" lg:w-1/4">
                            <div className={`form-element true`}>
                                <div className="form-label">Minimum Deposit amount for bonus</div>
                                <input
                                    name='minDepositeAmountForBonus'
                                    type='number'
                                    min={0}
                                    className="form-input form-input-valid"
                                    placeholder='Please enter bonus percentage'
                                    onChange={(event) => {
                                        setInput({
                                            ...input, minDepositeAmountForBonus: event.target.value
                                        })
                                    }}
                                    value={input.minDepositeAmountForBonus}
                                />
                                <div className="form-success"></div>
                            </div>
                        </div>
                    </div>
                </Widget>
                <Widget title="Settings" description={<span> </span>}>
                    <div className="flex flex-col lg:flex-row w-full lg:space-x-1">
                        <div className=" lg:w-1/4">
                            <div className={`form-element true`}>
                                <div className="form-label">New User Bonus</div>
                                <input
                                    name='newUserBonus'
                                    type='number'
                                    min={0}
                                    className="form-input form-input-valid"
                                    placeholder='Please enter bonus percentage'
                                    onChange={(event) => {
                                        setInput({
                                            ...input, newUserBonus: event.target.value
                                        })
                                    }}
                                    value={input.newUserBonus}
                                />
                                <div className="form-success"></div>
                            </div>
                        </div>
                        <div className=" lg:w-1/4">
                            <div className={`form-element true`}>
                                <div className="form-label">Referrer Bonus </div>
                                <input
                                    name='referrerBonus'
                                    type='number'
                                    min={0}
                                    className="form-input form-input-valid"
                                    placeholder='Please enter bonus percentage'
                                    onChange={(event) => {
                                        setInput({
                                            ...input, referrerBonus: event.target.value
                                        })
                                    }}
                                    value={input.referrerBonus}
                                />
                                <div className="form-success"></div>
                            </div>
                        </div>
                    </div>
                </Widget>
            </form>
            <div className="flex flex-col lg:flex-row lg:flex-wrap items-start lg:items-center justify-start space-y-2 lg:space-y-0 lg:space-x-2">
                <button className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded" onClick={handleSubmit}>Submit</button>
            </div>
        </>
    );
}
export default Settings;