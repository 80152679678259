import * as React from 'react';
import { Button, Grid, MenuItem, TextField } from "@mui/material";
import ApiService from '../../../api/ApiService';
import SectionTitle from '../../../components/section-title';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';


function AddNotification() {
    const { id } = useParams();
    const [notificationTemplate, setNotificationTemplate] = React.useState([]);
    const [error, setError] = React.useState(false)
    const navigate = useNavigate();

    const [input, setInput] = React.useState({
        workFlowName: '',
        name: '',
        title: '',
        content: '',
    })

    const getNotificationTemplate = () => {
        ApiService.post("v1/admin/getNotificationTemplate").then((response) => {
            if (response.status === 200) {
                setNotificationTemplate(response.data.body);
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }

    const validation = () => {
        if (input.name === '' || input.content === '') {
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        if (!validation()) {
            toast.error('Please enter the details')
            return;
        }
        const payload = Object.assign(input);
        if (id) {
            ApiService.post(`v1/admin/updateNotificationTemplate/${id}`, payload).then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                    navigate('/admin/notification-list');
                } else {
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }
    const insertNotification = () => {
        if (!validation()) {
            toast.error('Please enter the details')
            return;
        }    
        const payload = Object.assign(input);
            ApiService.post(`v1/admin/insertNotificationTemplate`, payload).then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                    navigate('/admin/notification-list');
                } else {
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
    }


    React.useEffect(() => {
        getNotificationTemplate();
        if (id) {
            getDetails();
        }
        getNotificationCategory();
    }, [])
    const getDetails = () => {
        ApiService.post(`v1/admin/getNotificationTemplateById/${id}`).then((response) => {
            if (response.status === 200) {
                setInput(response.data.body);
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }
    const [NotificationCategory, setNotificationCategory] = React.useState([]);
    const getNotificationCategory = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const payload = {
            "adminId":user?.id
        }
        ApiService.post("v1/admin/getNotificationWorkflowList", payload).then((response) => {
            if (response.status === 200) {
                setNotificationCategory(response.data.body);
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }

    return (
        <div>
            <React.Fragment>
                <Grid style={{ margin: '50px' }}>
                    <form>
                        <SectionTitle title="NotificationTemplate" subtitle={`Update Notification Template`} />
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Notification workflow"
                                    style={{ width: '100%' }}
                                    value={input.workFlowName}
                                    onChange={(event) => {
                                        setInput({
                                            ...input, workFlowName: event.target.value
                                        })
                                    }}
                                    disabled={id}
                                >
                                    {NotificationCategory.map((e) => (
                                        <MenuItem value={e.name} key={e.id}>
                                            {e.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Name"
                                    fullWidth
                                    name='name'
                                    error={error}
                                    onBlur={validation}
                                    value={input.name}
                                    autoComplete="cc-exp"
                                    disabled={id}
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, name: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Title"
                                    fullWidth
                                    name='title'
                                    error={error}
                                    onBlur={validation}
                                    value={input.title}
                                    autoComplete="cc-exp"                                                                   
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, title: event.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Content"
                                    style={{ width: '100%' }}
                                    name='content'
                                    multiline
                                    rows={4}
                                    error={error}
                                    onBlur={validation}
                                    value={input.content}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setInput({
                                            ...input, content: event.target.value
                                        })
                                    }}
                                >
                                </TextField>
                            </Grid>
                        </Grid>
                        <div className="flex items-end justify-start space-x-4 p-4">
                        <Button
                            variant="contained"
                            className='btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded'
                            onClick={id ? handleSubmit : insertNotification}
                            size="medium" >
                            SUBMIT
                        </Button>
                        <Button 
                            className='btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded'
                            variant="contained"
                            onClick={() => { navigate(`/admin/notification-list`) }}
                            size="medium" 
                            startIcon={<ArrowBack />}>
                             BACK
                        </Button>
                        </div>
                    </form>
                </Grid>
            </React.Fragment>

        </div >
    );
}
export default AddNotification