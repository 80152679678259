import React from 'react'
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux'
import { store } from './store'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ToastContainer autoClose={2000} ></ToastContainer>
    <Provider store={store}>
      <App />
    </Provider>
  </>

)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
