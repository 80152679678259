import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../../components/section-title';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Widget from '../../../../components/widget';
import ApiService from '../../../../api/ApiService';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import { options, setSorting } from '../../helper/helper';
import excelIcon from '../../../../images/excel.png';
import Datepicker from '../../../../components/datepicker';
import MenuItem from '@mui/material/MenuItem';

const TDSReport = () => {
    // let newyear = new Date();
    // let finalyear = newyear.getFullYear();
    
    // let newmonth = new Date();
    // let finalmonth = newmonth.getMonth();
    
    // let years = [
    //     {nyear:finalyear, value:finalyear},
    //     {nyear:finalyear - 1, value:finalyear - 1}
    // ];
    // let months =[ 
    //     {name:"Jan",val:1},
    //     {name:"Feb",val:2} ,
    //     {name:"Mar",val:3} ,
    //     {name:"Aprl",val:4} ,
    //     {name:"May",val:5} ,
    //     {name:"June",val:6} ,
    //     {name:"July",val:7} ,
    //     {name:"Aug",val:8} ,
    //     {name:"Sep",val:9} ,
    //     {name:"Oct",val:10} ,
    //     {name:"Nov",val:11} ,
    //     {name:"Dec",val:12} 
    // ]
    // const [month, setmonth] = useState(finalmonth);
    // const [year, setyear] = useState(finalyear);

    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setDate(d.getDate() - 7);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const [startDate, setStartDate] = useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MMM-DD'));
    const [pageSize, setPageSize] = useState(10);
    const [tdsList, settdsList] = useState([]);
    const [isLoading, setIsFetching] = useState(false);
    const [labelSearch, setLabelSearch] = useState('');
    const [sortOption, setSortOption] = useState({ updatedOn: -1 });
    const [total, setTotal] = useState(null)
    const processDataForCSV =(csvData)=>{

        let allCSVRows=[];
        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            let row ={};
            row.serialNum =index+1;
            row.createdOn = csvRow.createdOn;
            row.name = csvRow.Users[0].name;
            row.userId = csvRow.userId;
            row.panNumber = csvRow.UserPANCard[0]?.panNumber;
            row.withdrawalfromwinning = csvRow.UserWithdrawalRequest[0]?.adminComment;
            row.TDSamount = csvRow.amount;
            row.amountcreditedtoUser = csvRow.UserWithdrawalRequest[0]?.amount;
            //  csvRow.panNumber = csvRow.UserPANCard[0]?.panNumber;
            //  csvRow.name = csvRow.Users[0].name;
            //  csvRow.contestantAmount = csvRow.amount;
            //  csvRow.amount = csvRow.UserWithdrawalRequest[0]?.amount;
            //  csvRow.adminComment = csvRow.UserWithdrawalRequest[0]?.adminComment
            //  delete csvRow.UserPANCard;
            //  delete csvRow.UserWithdrawalRequest;
            //  delete csvRow.Users;
            //  delete csvRow.tdsReferenceId;
             allCSVRows.push(row)
        }
        // return csvData;
        return allCSVRows;
    
    }
    const getTDSList = (currentIndex, search, sort, size,csv) => {
        let payload = {}
 
        const user = JSON.parse(localStorage.getItem('user'));
         if (csv) {
             payload = {
                "filterOption": {
                    'keyword': labelSearch || search,"startDate": startDate,
                    "endDate": endDate,
                    "adminId":user.id
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
            }
         } else {
         }
          payload = {
                "filterOption": {
                    'keyword': labelSearch || search,"startDate": startDate,
                    "endDate": endDate,
                    "adminId":user.id
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
            }
         setIsFetching(true);
         ApiService.post(`v1/admin/getTDSReportByUser`, payload).then((response) => {
             if (response.status === 200) {
                 if (csv) {
                     setIsFetching(false);
                     options.filename = 'TDS Report By User'
                     const csvExporter = new ExportToCsv(options);
                     csvExporter.generateCsv(processDataForCSV(response.data.body.data));
                 } else {
                     settdsList(response.data.body.data);
                     setTotal(response.data.body.recordCount);
                    //  .log("response.data.body",response.data.body.data,response.data.body.recordCount);
                     setIsFetching(false);
                 }
             } else {
                 setIsFetching(false);
                 toast.error(response.data.message)
             }
         }).catch((error) => {
             toast.error(error.response.data.message);
             setIsFetching(false);
         });
     }
     const columns = [
        {
            field: 'createdOn', headerName: 'Date', flex: 1,
            valueGetter: (params) => {
                    return moment(params.row.createdOn).format('MMM Do YYYY, h:mm:ss a');
            },
        },
        {
            field: 'Users.name', headerName: `Name`, flex: 1,
            renderCell: (params) => {
                return params.row.Users[0].name;
            }
        },
        {
            field: 'Users._id', headerName: `ID`, flex: 1,
            renderCell: (params) => {
                return params.row.userId;
            }
        },
        {
            field: 'UserPANCard', headerName: `Pan Card`, flex: 1,
            renderCell: (params) => {
                return params.row?.UserPANCard[0]?.panNumber;
            }
        },
        {
            field: 'UserWithdrawalRequest.adminComment', headerName: `Withdrawal from winnings`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row?.UserWithdrawalRequest[0]?.adminComment;
            }
        },
        {
            field: 'TDS', headerName: 'TDS', flex: 0.6,
            renderCell: (params) => (<div><text>30%</text></div>)
        },
        {
            field: 'amount', headerName: `TDS Amount`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row.amount;
            }
        },
        {
            field: 'UserWithdrawalRequest.amount', headerName: `Amount credited to contestants bank`, flex: 1,
            renderCell: (params) => {
                return'₹ ' +  params.row?.UserWithdrawalRequest[0]?.amount;
            }
        }, 
    ];

    const handleSearch = () => {
        
        var date1 = moment(startDate);
        var date2 = moment(endDate);
        var result =  date2.diff(date1, 'days');
        if ((result > 7) ) {
          toast.error("Diffrence between start date and end date should not be more than 7 days")
          return;
        }

        getTDSList(0, '');
    }
    useEffect(() => {
        getTDSList(0, '');
    }, [])
    const downloadList = () => {
        getTDSList(0, '', '', 100000,' ');
    }
    return (
        <>
         <SectionTitle title="TDS Report" subtitle="TDS REPORT BY USER"></SectionTitle>
         <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '10px' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }} >
                            <Grid item xs={1} sm={4} md={2}>
                        <TextField id="standard-basic" label="Search by Name" variant="standard" style={{ width: '100%' }}
                            onChange={(e) => {
                                setLabelSearch(e.target.value);
                            }} />
                            </Grid>
                        <Grid item xs={1} sm={4} md={2}>
                        <Datepicker Value={startDate} onChange={(e)=>setStartDate(e.format('YYYY-MMM-DD'))}  label={'Start Date'} />
                        </Grid>
                        <Grid item xs={1} sm={4} md={2}>
                        <Datepicker Value={endDate} onChange={(e)=>setEndDate(e.format('YYYY-MMM-DD'))}  label={'End Date'}/>
                        </Grid>
                        {/* <Grid item xs={1} sm={4} md={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="year"
                                    size="small"
                                    value={year}
                                    onChange={(e)=>setyear(e.target.value)}
                                    style={{ width: '100%', marginTop: '10px', }}
                                
                                > 
                                {years!==null && years.map((option1, index) => (
                                        <MenuItem 
                                          onClick={() =>setyear(option1.value)}
                                          key={option1.value}
                                          value={option1.value}>
                                            {option1.nyear}
                                        </MenuItem>
                                        
                                    ))}
                                </TextField></Grid>
                        <Grid item xs={1} sm={4} md={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Months"
                                    size="small"
                                    value={month}
                                    onChange={(e)=>setmonth(e.target.value)}
                                    style={{ width: '100%', marginTop: '10px', }}
                                
                                > 
                                {months!==null && months.map((option, index) => (
                                        <MenuItem 
                                          onClick={() =>setmonth(option.val)}
                                          key={option.val}
                                          value={option.val}>
                                            {option.name}
                                        </MenuItem>
                                        
                                    ))}
                                </TextField></Grid> */}
                            <Grid item xs={1} sm={4} md={2}>
                        <Button variant="contained"
                            style={{ marginTop: '7%' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<SearchIcon />}
                            onClick={() => handleSearch()}>
                            Search
                        </Button>
                        </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                      <img src={excelIcon} 
                                      alt='1'
                                      width='30px' 
                                      height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: 15,
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                        </Grid>
              </Grid>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            style={{ width: '100%', height: '100px !important' }}
                            rowCount={total}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={tdsList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getTDSList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                setSortOption(sort);
                                getTDSList(0, '', sort)
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getTDSList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
        )
    }
    
export default TDSReport