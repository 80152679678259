import React from 'react'
import mainLogo from './../css/public/images/btn-goggleplay.png';
import appstore from './../css/public/images/btn-appstore.png';
import icmatch1 from './../css/public/images/ic-match1.png';
import icmatch2 from './../css/public/images/ic-match2.png';
import icmatch3 from './../css/public/images/ic-match3.png';
import userimg1 from './../css/public/images/user_img1.jpg';
import userimg2 from './../css/public/images/user_img2.jpg';
import userimg3 from './../css/public/images/user_img3.jpg';
import logofoo from './../css/public/images/logofoot.png';
import { isMobile } from "react-device-detect";
const CommanFooter = () => {
    return (
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-md-5 text-left">
                        <div class="footer_logo"><a href="/"><img class="img-fluid" src={logofoo} alt="PickMonk" /></a></div>
                        <span class="text-company">About The Company</span>
                        <p>Pickmonk is a DFS, allows strategy-based sports game where you have to create a virtual team of real cricketers.</p>
                    </div>
                    <div class="col-md-4">
                        <ul class="footer_link d-flex flex-wrap">
                            <li> <a href="/content/about-us">About us</a> </li>
                            <li> <a href="/content/faqs">Help</a> </li>
                            <li> <a href="/content/how-to-play">How to Play</a> </li>
                            <li> <a href="/content/fair_play">Fair Play</a> </li>                          
                            <li> <a href="/content/contact-us">Contact Us</a> </li>
                            <li> <a href="/content/Privacy-Policy">Privacy Policy</a> </li>
                            <li> <a href="/content/Terms-and-Conditions">Terms & Conditions</a> </li>
                            {/* <li> <a href="/content/rules">Rules</a> </li> */}
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <div class="socail_link text-right"><span class="title_social w-100 d-block pb-2">CONNECT WITH US</span><a href="#" class="mx-1"><i class="fa fa-facebook"></i></a><a href="#" class="mx-1"><i class="fa fa-twitter"></i></a><a href="#" class="mx-1 mr-lg-4"><i class="fa fa-instagram"></i></a></div>
                    </div>
                </div>
            </div>
            <div class="copyright">
                <div class="container">
                    <div class="row">
                        <div class="copyright_text text-md-left col-md-5">© Copyright - Pickmonk India Private Limited. All Rights Reserved.</div>
                        {isMobile===false&&<div class="col-md-7 appbtn"><a href="https://play.google.com/store/apps/details?id=com.pickmonk"><img class="img-fluid" src={mainLogo} alt="" /></a><a href="https://apps.apple.com/us/app/pickmonk/id1561916915"><img class="img-fluid" src={appstore} alt="" /></a></div>}
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default CommanFooter