import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../../components/section-title'; 
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button'; 
import TextField from '@mui/material/TextField';
import Widget from '../../../../components/widget';
import ApiService from '../../../../api/ApiService';
import SearchIcon from '@mui/icons-material/Search'; 
import MenuItem from '@mui/material/MenuItem';

const RequestReport = () => {
    let newyear = new Date();
    let finalyear = newyear.getFullYear();
    
    let newmonth = new Date();
    let finalmonth = newmonth.getMonth();
    
    let years = [
        {nyear:finalyear, value:finalyear},
        {nyear:finalyear - 1, value:finalyear - 1}
    ];
    let months =[ 
        {name:"Jan",val:1},
        {name:"Feb",val:2} ,
        {name:"Mar",val:3} ,
        {name:"Aprl",val:4} ,
        {name:"May",val:5} ,
        {name:"June",val:6} ,
        {name:"July",val:7} ,
        {name:"Aug",val:8} ,
        {name:"Sep",val:9} ,
        {name:"Oct",val:10} ,
        {name:"Nov",val:11} ,
        {name:"Dec",val:12} 
    ]
    const [month, setmonth] = useState(finalmonth);
    const [year, setyear] = useState(finalyear);
    const [requestStatusType, setRequestStatusType] = useState("Earnings");
    
    const saveReports = () => {
        let payload = {}
 
        const user = JSON.parse(localStorage.getItem('user'));
          payload = {
                "filterOption": {
                    "month":month,
                    "year":year,
                    'name':requestStatusType
                },
                "requestedBy":user.id
            }
         ApiService.post(`v1/admin/reportRequested`, payload).then((response) => {
             if (response.status === 200) {
                     toast.success(response.data.body.msg)
             } else {
                 console.log("response.data.body.msg", response.data.body.msg);
                 toast.error(response.data.body.msg)
             }
         }).catch((error) => {
             toast.error(error.response.data.body.msg);
         });
     }
     
    return (
        <>
         <SectionTitle title="Request Report" subtitle="REQUEST REPORT"></SectionTitle>
         <Widget>
                    <Grid style={{ width: '100%', margin: '10px' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }} >
                            <Grid item xs={1} sm={4} md={2}>
                            <TextField
                                id="outlined-select-request-status"
                                select
                                label="Report Name"
                                size="small"
                                value={requestStatusType}
                                onChange={(e) => setRequestStatusType(e.target.value)}
                                style={{ width: '100%', marginTop: '10px'}}
                                >
                                <MenuItem value={'Earnings'}>Earnings</MenuItem>
                                <MenuItem value={'TDS by User'}>TDS by User</MenuItem>
                                <MenuItem value={'TDS by Month'}>TDS by Month</MenuItem>
                                <MenuItem value={'GST by user'}>GST by user</MenuItem>
                                <MenuItem value={'Cashback given'}>Cashback given</MenuItem>
                            </TextField>
                            </Grid>
         <Grid item xs={1} sm={4} md={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="year"
                                    size="small"
                                    value={year}
                                    onChange={(e)=>setyear(e.target.value)}
                                    style={{ width: '100%', marginTop: '10px', }}
                                
                                > 
                                {years!==null && years.map((option1, index) => (
                                        <MenuItem 
                                          onClick={() =>setyear(option1.value)}
                                          key={option1.value}
                                          value={option1.value}>
                                            {option1.nyear}
                                        </MenuItem>
                                        
                                    ))}
                                </TextField></Grid>
                        <Grid item xs={1} sm={4} md={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Month"
                                    size="small"
                                    value={month}
                                    onChange={(e)=>setmonth(e.target.value)}
                                    style={{ width: '100%', marginTop: '10px', }}
                                
                                > 
                                {months!==null && months.map((option, index) => (
                                        <MenuItem 
                                          onClick={() =>setmonth(option.val)}
                                          key={option.val}
                                          value={option.val}>
                                            {option.name}
                                        </MenuItem>
                                        
                                    ))}
                                </TextField></Grid>
                            <Grid item xs={1} sm={4} md={2}>
                        <Button variant="contained"
                            style={{  marginTop: '4%' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<SearchIcon />}
                            onClick={() => saveReports()}>
                            Request
                        </Button>
                        </Grid>
                        </Grid></Grid>
                        </Widget>
        </>
        )
    }
    
export default RequestReport
         