import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import SectionTitle from '../../../components/section-title';
import Widget from '../../../components/widget'
import ApiService from '../../../api/ApiService';
import { toast } from 'react-toastify';
import { useParams,useNavigate } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import moment from 'moment';
import { ArrowBack } from '@mui/icons-material';


const ManageMatch = () => {
    const params = useParams();
    const [matchList, setMatchList] = useState([]);
    const [matchData, setMatchData] = useState(null);
    const [extendTime, setExtendTime] = useState(0);
    const [isLoading, setIsFetching] = useState(false);
    const [isTeamCreated, setIsTeamCreated] = useState(null);
    const navigate = useNavigate();

    const extendStartTime = () => {
        const data = {
            matchId: params.id,
            addMinutes: extendTime
        }
        ApiService.post(`v1/admin/extendMatchStartTime`, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.body.msg);
                getMatchPlaying();
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const getMatchPlaying = () => {
        const data = {
            matchId: parseInt(params.id),
        }
        ApiService.post(`v1/admin/getMatchPlayingEleven`, data).then((response) => {
            if (response.status === 200) {
                setIsTeamCreated(response.data.body.isTeamCreated);
                setMatchList(response.data.body.sqauds);
                setMatchData(response.data.body.matchDetails);
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const disableForMatch = (value, playerId, type) => {
        const data = {
            'matchId': parseInt(params.id),
            'playerId': playerId
        }
        if (type === 'compitition') {
            Object.assign(data, { 'disableForCompitition': value });
        } else {
            Object.assign(data, { 'disableForMatch': value });
        }
        ApiService.post(`v1/admin/updateMatchPlayerAvailability`, data).then((response) => {
            if (response.status === 200) {
                setMatchList(response.data.body);
                getMatchPlaying();
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    useEffect(() => {
        getMatchPlaying();
    }, [])
    const columns = [
        {
            field: 'name', headerName: `Player Name`,
            flex: 1,
            valueGetter: (params) => {
                return params?.row?.Player[0]?.title;
            }
        },
        { field: 'fantasyPlayerRating', headerName: `Fantasy Score`, flex: 1 },
        { field: 'role_str', headerName: `role`, flex: 1 },
        {
            field: 'teamName', headerName: `Team Name`, flex: 1,
            valueGetter: (params) => {
                return params.row.Team[0]?.title
            },
        },
        {
            field: 'image', headerName: `Player Image`, flex: 1,
            renderCell: (params) => {
                if (params.row.playerThumb) {
                    return <img src={params?.row?.playerThumb} width={30} />
                }
            },
        },
        {
            field: 'disable_match', headerName: `Disable for this match`, flex: 1,
            renderCell: (params) => (
                <Checkbox
                    checked={params?.row?.isExcludedFromMatch}
                    disabled={isTeamCreated === false ? false : true}
                    onChange={(e) => disableForMatch(e.target.checked, params.row.Player[0].pid, 'match')}
                    name="checkedB"
                    color="primary"
                />
            )
        },
        {
            field: 'disable_series', headerName: `Disable for series`, flex: 1,
            renderCell: (params) => (
                <Checkbox
                    checked={params?.row?.isExcludedFromSeries}
                    onChange={(e) => disableForMatch(e.target.checked, params.row.Player[0].pid, 'compitition')}
                    name="checkedB"
                    color="primary"
                />
            )
        },
    ];
    return (
        <>
            <SectionTitle title="Manage Match" subtitle={matchData?.competition[0]?.title}></SectionTitle>
            <Widget>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            Match Title : {matchData?.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            Current match start time :{
                                moment.unix(matchData?.timestamp_start).format('YYYY-MM-DD HH:mm:ss')
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={2}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Extend Start Time"
                            size="small"
                            style={{ width: '100%', marginTop: '3px' }}
                            onChange={(e) => setExtendTime(e.target.value)}
                        >
                            <MenuItem value={3}>3 minutes</MenuItem>
                            <MenuItem value={5}>5 minutes</MenuItem>
                            <MenuItem value={10}>10 minutes</MenuItem>
                            <MenuItem value={15}>15 minutes</MenuItem>
                            <MenuItem value={30}>30 minutes</MenuItem>
                            <MenuItem value={45}>45 minutes</MenuItem>
                            <MenuItem value={60}>60 minutes</MenuItem>
                            <MenuItem value={90}>90 minutes</MenuItem>
                            <MenuItem value={120}>120 minutes</MenuItem>
                            <MenuItem value={180}>180 minutes</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={2}>
                        <Button variant="contained"
                            style={{ margin: '1%', marginLeft: 0 }}
                            color="success"
                            className='[btn btn-default text-white btn-rounded]'
                            onClick={() => extendStartTime()}
                        >
                            Add into start time
                        </Button>
                    </Grid>
                    <div className="flex items-start justify-start space-x-4 p-4">
                    <Button 
                            className='btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded'
                            variant="contained"
                            style={{  marginLeft: 285 }}
                            onClick={() => { navigate(`/admin/match-list`) }}
                            size="medium" 
                            startIcon={<ArrowBack />}>
                             BACK
                        </Button>
                        </div>
                </Grid>
            </Widget>
            <Widget>
                <Paper elevation={2}>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%' }}
                            disableColumnMenu
                            loading={isLoading}
                            rows={matchList || []}
                            disableColumnFilter
                            disableColumnSelector
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            columns={columns}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
    )
}
export default ManageMatch