export const CONTESTPERMISSION='cont129300';
export const MATCHPERMISSION='match9992993';
export const MANAGEUSERS='part2030392X';
export const TEAMS='team9392sess2';
export const SERIES='series0293921SD';
export const ALLOWSTATEMENTS='settlment0398283';
export const SENDNOTIFICATION='sendNoti92828282';
export const GSTREPORTS='gstrpt939145921';
export const MANAGESTATICCONTENTS='staticContents33223432432';
export const ADMINISTRATIVE='administrative324234326567';
export const MANAGEUSERPAYMENTS="userPayments3924811334";
export const DASHBOARD="dashboard3449929291293";