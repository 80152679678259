import React, { useState } from 'react'
import Datetime from 'react-datetime'
import './styles.css'

const Datepicker = (props) => {
  const { label, onChange,Value } = props;
  const [value, setValue] = useState(null)
  
  return (
    <div className="form-element">
      <span className="text-sm text-default">
        <span>{label}</span>
      </span>
      <Datetime
        value={Value}
        dateFormat="DD-MM-YYYY"
        timeFormat={false}
        input={true}
        inputProps={{
          className: 'form-input',
          placeholder: 'Select date'
        }}
        viewMode={'days'}
        onChange={onChange}
        closeOnSelect={true}
      />
    </div>
  )
}

export default Datepicker
