import React from 'react'
import {
  FiToggleLeft,
  FiCalendar,
  FiStar,
  FiDroplet,
  FiClock,
  FiCopy,
  FiUser,
  FiPieChart,
  FiMap,
  FiHelpCircle,
  FiShoppingCart,
  FiSettings
} from 'react-icons/fi'
import {
  FaDollarSign,
  FaRegAddressCard, FaPercentage,FaRegMoneyBillAlt,FaUserCircle
} from 'react-icons/fa'
import {
  IoIosNotifications
} from 'react-icons/io'
import reactCSS from 'reactcss'
import { MdMailOutline, MdContentCopy,MdPayment,MdOutlinePayments } from "react-icons/md";
import { AiOutlineDashboard, AiTwotoneSetting, AiOutlineFile, AiOutlineUserSwitch } from "react-icons/ai";
import { BsCardList, BsArrowsFullscreen, BsPeople, } from "react-icons/bs";
import { RiWallet2Line, RiCoupon3Line, RiUserSettingsFill, RiPercentFill } from "react-icons/ri";
import { hasPermission } from '../Constants/CheckHasPermission';
import ThreePIcon from '@mui/icons-material/ThreeP';
import {
  CONTESTPERMISSION,
  MATCHPERMISSION,
  MANAGEUSERS,
  TEAMS,
  SERIES,
  ALLOWSTATEMENTS,
  SENDNOTIFICATION,
  GSTREPORTS,
  MANAGESTATICCONTENTS,
  ADMINISTRATIVE,
  MANAGEUSERPAYMENTS
} from '../Constants/PermissionConstant';
import { GiCricketBat } from 'react-icons/gi'
const styles = reactCSS({
  'default': {
    icon: {
      zIndex: 10,
      width: 40,
      padding: 0,
      margin: 0,
      paddingRight: 5,
      marginLeft: -8,
      justifyContent: 'flex-start',
      alignSelf: 'center'
    },
  },
});
const initialState = [
  {
    title: 'Pickmonk',
    items: [
      {
        url: '/admin/dashboard',
        icon: <AiOutlineDashboard size={20} />,
        title: 'Dashboard',
        items: [],
        permission: true,
      },


      {
        url: 'admin/contest-list',
        icon: <BsArrowsFullscreen size={20} />,
        title: 'Contest',
        items: [
        ],
        permission: hasPermission(CONTESTPERMISSION)
      },

      {
        url: '/',
        icon: <GiCricketBat size={20} />,
        title: ' Match Management',
        permission: hasPermission(MATCHPERMISSION)
        ,
        items: [
          {
            url: '/admin/teams',
            icon: <BsCardList size={20} style={styles.icon} />,
            title: 'Teams',
            items: [],
            permission: hasPermission(TEAMS)
          },
          {
              url: '/admin/contestcategory-list',
            icon: <BsCardList size={20} style={styles.icon} />,
            title: 'Contest category',
            items: [],
            permission: hasPermission(TEAMS)
          },
          {
            url: '/admin/UpdateTeamData',
            icon: <BsCardList size={20} style={styles.icon} />,
            title: 'Update Teams',
            items: [],
            permission: hasPermission(TEAMS)
          },
          {
            url: '/admin/series-list',
            icon: <BsCardList size={20} style={styles.icon} />,
            title: 'Series',
            items: [],
            permission: hasPermission(SERIES)
          },
          {
            url: '/admin/match-list',
            icon: <BsCardList size={20} style={styles.icon} />,
            title: 'Matches',
            items: [],
            permission: hasPermission(MATCHPERMISSION)
          },
          {
            url: '/admin/player-manager',
            icon: <BsCardList size={20} style={styles.icon} />,
            title: 'Player Manager',
            items: [],
            permission: hasPermission(CONTESTPERMISSION)
          },
        ],

      },

      {
        url: '/',
        icon: <FaDollarSign size={20} />,
        title: ' User Payments',

        items: [
          {
            url: '/admin/getAllBank',
            icon: <FaDollarSign size={20} style={styles.icon} />,
            title: 'All Banks',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 6
            },
            items: [
            ],
            permission: true,
          },
          {
            url: '/admin/walletManager',
            icon: <FaDollarSign size={20} style={styles.icon} />,
            title: 'Wallet Manager',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 6
            },
            items: [
            ],
            permission: true,
          },
          {
            url: '/admin/pan-request',
            icon: <FaRegAddressCard size={20} style={styles.icon} />,
            title: 'Pan Request',
            items: [],
            permission: true,
          },
          {
            url: '/admin/transaction',
            icon: <AiTwotoneSetting size={20} style={styles.icon} />,
            title: 'Transaction',
            items: [
            ],
            permission: true,
          },
        ],
        permission:  hasPermission(MANAGEUSERPAYMENTS),
      },

      {
        url: '/',
        icon: <MdContentCopy size={20} />,
        title: ' Site Contents',
        items: [
          {
            url: '/admin/static-pages',
            icon: <FiCalendar size={20} style={styles.icon} />,
            title: 'Static Pages',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 6
            },
            items: [
            ],
            permission: true,
          },
          {
            url: '/admin/offers',
            icon: <RiCoupon3Line size={20} style={styles.icon} />,
            title: 'Offers',
            items: [
            ],
            permission: true,
          },
          {
            url: '/admin/email-list',
            icon: <MdMailOutline size={20} style={styles.icon} />,
            title: 'Email Templates',
            items: [
            ],
            permission: true,
          },
          {
            url: '/admin/notification-list',
            icon: <IoIosNotifications size={20} style={styles.icon} />,
            title: 'Notification Templates',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 2
            },
            items: [
            ],
            permission: true,
          },
        ],
        permission: hasPermission(MANAGESTATICCONTENTS),
      },
      {
        url: '/',
        icon: <RiUserSettingsFill size={20} />,
        title: ' Administrative',
        items: [
          // {
          //   url: '/admin/send-notification',
          //   icon: <IoIosNotifications size={20} style={styles.icon} />,
          //   title: 'Send Notification',
          //   badge: {
          //     color: 'bg-indigo-500 text-white',
          //     text: 2
          //   },
          //   items: [
          //   ], permission: hasPermission(SENDNOTIFICATION) ,
          // },
          {
            url: '/admin/participant-list',
            icon: <BsPeople size={20} style={styles.icon} />,
            title: 'Participants',
            items: [], permission: hasPermission(MANAGEUSERS)
          },
          {
            url: '/admin/withdraw-request',
            icon: <AiTwotoneSetting size={20} style={styles.icon} />,
            title: 'Withdraw Request',
            items: [
            ], permission: true,
          },
          {
            url: '/admin/sub-admin',
            icon: <FiDroplet size={20} style={styles.icon} />,
            title: 'Manage Admins',
            items: [
            ], permission: true,
          },
          {
            url: '/admin/gst-list',
            icon: <RiWallet2Line size={20} style={styles.icon} />,
            title: 'View GST Report',
            items: [
            ],
            permission: hasPermission(GSTREPORTS)
          },
          {
            url: '/admin/userPayment-settlement',
            icon: <IoIosNotifications size={20} style={styles.icon} />,
            title: 'Settlement',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 2
            },
            items: [
            ],
            permission: hasPermission(ALLOWSTATEMENTS)
          },
          {
            url: '/admin/remove-UserAccount',
            icon: <IoIosNotifications size={20} style={styles.icon} />,
            title: 'Remove request',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 2
            },
            items: [
            ], permission: true,
          },
          {
            url: '/admin/User-Enquires',
            icon: <ThreePIcon size={20} style={styles.icon} />,
            title: 'User enquires',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 2
            },
            items: [
            ], permission: true,
          },
          {
            url: '/admin/TDS',
            icon: <FaPercentage size={20} style={styles.icon} />,
            title: 'TDS',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 2
            },
            items: [
            ], permission: true,
          },
          {
            url: '/admin/EntityToken',
            icon: <FaRegMoneyBillAlt size={20} style={styles.icon} />,
            title: 'Entity Token',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 2
            },
            items: [
            ], permission: true,
          },
          {
            url: '/admin/CashbackOffer',
            icon: <RiCoupon3Line size={20} style={styles.icon} />,
            title: 'Cashback Offer',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 2
            },
            items: [
            ], permission: true,
          },

          {
            url: '/admin/settings',
            icon: <FiSettings size={20} style={styles.icon} />,
            title: 'Setting',
            items: [], permission: true,
          }
        ],
        permission: hasPermission(ADMINISTRATIVE),
      },
      {
        url: '/',
        icon: <AiOutlineFile size={20} />,
        title: 'Reports',
        items: [
          {
            url: '/admin/TDSReport',
            icon: <RiPercentFill size={20} style={styles.icon} />,
            title: 'TDS by User',
            items: [
            ], permission: true,
          },
          {
            url: '/admin/TDSReportByMonth',
            icon: <AiOutlineUserSwitch size={20} style={styles.icon} />,
            title: 'TDS by Month',
            items: [
            ], permission: true,
          },
          {
            url: '/admin/newGSTReport',
            icon: <RiWallet2Line size={20} style={styles.icon} />,
            title: 'GST by User',
            items: [
            ], permission: true,
          },
          {
            url: '/admin/EarningReport',
            icon: <FaRegMoneyBillAlt size={20} style={styles.icon} />,
            title: 'Earnings',
            items: [
            ], permission: true,
          },
          {
            url: '/admin/CashBackReport',
            icon: <BsCardList size={20} style={styles.icon} />,
            title: 'CashBack Given',
            items: [],
            permission: true
          },
          {
            url: '/admin/UserContestReport',
            icon: <FaUserCircle size={20} style={styles.icon} />,
            title: 'New User Contest',
            items: [
            ], permission: true,
          },
          {
            url: '/admin/PayInReport',
            icon: <MdPayment size={20} style={styles.icon} />,
            title: 'PayIn Report',
            items: [
            ], permission: true,
          },
          {
            url: '/admin/PayOutReport',
            icon: <FaRegMoneyBillAlt size={20} style={styles.icon} />,
            title: 'PayOut Report',
            items: [
            ], permission: true,
          },
          {
            url: '/admin/RequestReport',
            icon: <FaRegMoneyBillAlt size={20} style={styles.icon} />,
            title: 'Request Report',
            items: [
            ], permission: true,
          },
        ],
        permission: hasPermission(GSTREPORTS),
      },


      
      {
        url: '/',
        icon: <IoIosNotifications size={20} />,
        title: 'Notification',
        items: [
          {
            url: '/admin/send-notification',
            icon: <IoIosNotifications size={20} style={styles.icon} />,
            title: 'Send Notification',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 2
            },
            items: [
            ], permission: hasPermission(SENDNOTIFICATION) ,
          },{
            url: '/admin/NotificationLog',
            icon: <IoIosNotifications size={20} style={styles.icon} />,
            title: 'Notification Log',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 2
            },
            items: [
            ], permission: hasPermission(SENDNOTIFICATION) ,
          },
          {
              url: '/admin/Whatsapp',
            icon: <IoIosNotifications size={20} style={styles.icon} />,
              title: 'Whatsapp Notifications',
            badge: {
              color: 'bg-indigo-500 text-white',
              text: 2
            },
            items: [
            ], permission: hasPermission(SENDNOTIFICATION) ,
          },
        ],
        permission: hasPermission(SENDNOTIFICATION),
      },



    ]
  },
]

export default function navigation(state = initialState, action) {
  switch (action.type) {
    default:
      if (window.location.href.toLowerCase().indexOf("/admin/") > 0) {
        return state;
      }
      return [];
  }
}
