import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DeleteAdminDialog(props) {
    const { open, handleClose, acceptDeleteRequest,  data } = props;
    const [required, setRequired] = React.useState(null)

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Remove Admin</DialogTitle>
                <DialogContent><DialogContentText>Are You sure ,You wan't to delete this Admin ?</DialogContentText></DialogContent>
                <DialogActions>
                <Button variant="contained"
                        style={{ float: 'left', marginTop: '2%', marginRight: '1%', }}
                        color="success"
                        fullWidth
                        className='[btn btn-default text-white btn-rounded]'
                        onClick={() =>acceptDeleteRequest(data.id)}
                        >Yes</Button>
                    <Button
                        variant="contained"
                        style={{ float: 'right', marginTop: '2%', marginRight: '1%' }}
                        color="error"
                        fullWidth
                        className='[btn btn-default text-white btn-rounded]'
                        onClick={handleClose}> Cancel </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}