import React from 'react';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Grid, IconButton, Paper, TextField, Tooltip } from '@mui/material';
import { PreviewOutlined, Add, ModeEdit } from '@mui/icons-material';
import { toast } from 'react-toastify';
import SectionTitle from '../../../components/section-title';
import Widget from '../../../components/widget';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import ApiService from '../../../api/ApiService';
import { setSorting } from '../helper/helper';

export default function StaticPage() {
  const [pageSize, setPageSize] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [listCount, setListCount] = React.useState(null);
  const [isLoading, setIsFetching] = React.useState(false);
  const [sortOption, setSortOption] = React.useState({createdOn: -1});
  const [labelSearch, setLabelSearch] = useState('');
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const getStaticPages = (currentIndex, search, sort, size) => {
    const payload = {
      "filterOption": search,
      "sortOption": sort || sortOption,
      "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size? size : pageSize }
    }
    setIsFetching(true);
    ApiService.post(`v1/admin/getSiteStaticContents`, payload).then((response) => {
      if (response.status === 200) {
        setIsFetching(false);
        setRows(response.data.body.data);
        setListCount(response.data.body.recordCount)
      } else {
          toast.error(response.data.message)
      }
      setIsFetching(false);
  }).catch((error) => {
      toast.error(error.response.data.message);
      setIsFetching(false);
  });
}

  React.useEffect(() => {
    getStaticPages(0, '');
  }, [])

  const columns = [
    { field: 'pageName', headerName: 'Page Name', flex: 1 },
    {
      field: 'updatedOn',
      valueGetter: ({ value }) => value && moment(value).format("MMMM DD, YYYY h:mm A"),
      headerName: 'Updated On',
      flex: 1
    },
    {
      field: 'isActive',
      headerName: 'Active',
      valueGetter: ({ value }) => value ? 'Active' : 'Not active',
      flex: 1
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      type: 'actions',
      headerAlign: "left",
      align: "left",
      getActions: ({ row }) => [
        <Tooltip title="View Page">
          <Link
            to={`/content/${row?.pageName}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton>
              <PreviewOutlined />
            </IconButton>
          </Link>
        </Tooltip>,
        <Tooltip title="Edit">
          <IconButton
            onClick={() =>
              (navigate(`/admin/static-pages/edit/${row.id}`))
            }
          >
            <ModeEdit />
          </IconButton>
        </Tooltip>
      ]
    }
  ];

  return (
    <div>
      <SectionTitle title="Static Pages" subtitle="Manage Pages" />
      <Widget>
        <Paper elevation={2}>
          <Grid style={{ width: '100%', margin: '1%', padding: '1%' }}>
              <TextField id="standard-basic" label="Search" variant="standard" style={{ width: '30%' }}
              onChange={(e) => (getStaticPages(0, e.target.value))} />

              <Button variant="contained"
                style={{ float: 'right', margin: '0 1%' }}
                className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                startIcon={<Add />}
                onClick={() => (navigate('/admin/static-pages/create'))}
              >
                Create Page
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ minHeight: 700, height: 600, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  loading={isLoading}
                  pageSize={pageSize}
                  onPageChange={(index) => {
                    let skip =pageSize*index;
                    getStaticPages(skip, labelSearch)
                  }}
                  pagination
                  sortingMode="server"
                  onSortModelChange={(modal) => {
                      const sort = setSorting(modal);
                      getStaticPages(0, '', sort);
                      setSortOption(sort);
                  }}
                  paginationMode="server"
                  onPageSizeChange={(newPageSize) => {
                      setPageSize(newPageSize);
                      getStaticPages(0, labelSearch , '',newPageSize)
                  }}
                  rowsPerPageOptions={[ 10,20, 50, 100]}
                  disableColumnMenu
                  disableColumnFilter
                  disableColumnSelector
                  disableSelectionOnClick
                  rowCount={listCount}
                  getRowId={(row) => row.id}
                />
              </Box>
            </Grid>
        </Paper>
      </Widget>
    </div>
  )
}