import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Widget from '../../../components/widget';
import Search from '@mui/icons-material/Search';
import ApiService from '../../../api/ApiService';
import IconButton from '@mui/material/IconButton';
import ModeEdit from '@mui/icons-material/ModeEdit';
import SectionTitle from '../../../components/section-title';
import { options, setSorting } from '../helper/helper';
import RemoveCircleOutlineOutlined from '@mui/icons-material/RemoveCircleOutlineOutlined';

export default function UserOffer() {
  const [pageSize, setPageSize] = React.useState(10);
  const [labelSearch, setLabelSearch] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [listCount, setListCount] = React.useState(null);
  const [isLoading, setIsFetching] = React.useState(false);
  const navigate = useNavigate();
  const [sortOption, setSortOption] = useState({ createdOn: -1 });

  const getOffers = (currentIndex, search , sort, size) => {
    
    const payload = {
      "filterOption": {
                    'keyword': labelSearch || search,
                },
      "sortOption": sort || sortOption,
      "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
    }
    setIsFetching(true);
    ApiService.post(`v1/admin/getUserOffer`, payload).then((response) => {
      if (response.status === 200) {
        setRows(response.data.body.data);
        setListCount(response.data.body.recordCount);
        setIsFetching(false);
      } else {
        toast.error(response.data.message)
      }
      setIsFetching(false);
    }).catch((error) => {
      toast.error(error.response.data.message);
      setIsFetching(false);
    });
  }

  const deleteOffer = (id) => {
    const payload = {
      "id": id
    }
    if (id) {
      ApiService.post(`v1/admin/deleteUserOffer`, payload)
        .then((response) => {
          const responseMessage = response?.data?.message
          toast.success(responseMessage || 'User Offer Deleted Successfully!');
          getOffers(0);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || "Error");
        })
    }
  }

  React.useEffect(() => {
    getOffers(0, '');
  }, [])
  const handleSearch = () => {
    getOffers(0, '');
}

  const columns = [
    { field: 'name', headerName: 'Offer Name', flex: 1 },
    { field: 'shortDesc', headerName: 'Description', flex: 1},
    {
      field: 'startDate',
      valueGetter: ({ value }) => value && moment(value).format("MMMM DD, YYYY h:mm A"),
      headerName: 'Start Date',
      flex: 1
    },
    {
      field: 'endDate',
      valueGetter: ({ value }) => value && moment(value).format("MMMM DD, YYYY h:mm A"),
      headerName: 'End Date',
      flex: 1,
    },
    {
      field: 'updatedOn',
      valueGetter: ({ value }) => value && moment(value).format("MMMM DD, YYYY h:mm A"),
      headerName: 'Updated On',
      flex: 1,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      valueGetter: ({ value }) => value ? 'Active' : 'Not active',
      flex: 0.5,
      sortable: false
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1.5,
      type: 'actions',
      headerAlign: "left",
      align: "left",
      getActions: ({ row }) => [
        <Tooltip title="Edit">
          <IconButton
            onClick={() =>
              (navigate(`/admin/offers/edit/${row.id}`))
            }
          >
            <ModeEdit />
          </IconButton>
        </Tooltip>,
        <Tooltip title="Remove offer">
          <IconButton
            onClick={() => (deleteOffer(row.id))}
          >
            <RemoveCircleOutlineOutlined />
          </IconButton>
        </Tooltip>
      ]
    }
  ];

  return (
    <div>
      <SectionTitle title="Offers" subtitle="Manage Offers" />
      <Widget>
        <Paper elevation={2}>
          <Grid style={{ width: '100%', margin: '1%', padding: '1%' }}>
            <TextField
              id="search-offers"
              label="Search"
              variant="standard"
              style={{ width: '30%' }}
              onChange={(e) => {setLabelSearch(e.target.value)} }
            />
           <Button
              style={{  margin: '1%' }}
              className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
              variant="contained"
              endIcon={<Search />}
              onClick={() => handleSearch()}
            >Search</Button>
            <Button variant="contained"
              style={{  margin: '1%' }}
              className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
              startIcon={<Add />}
              onClick={() => (navigate('/admin/offers/create'))}
            >
              Create Offer
            </Button>
            
          </Grid>
          <Box sx={{ minHeight: 700, height: 600, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={isLoading}
              pageSize={pageSize}
              rowsPerPageOptions={[10 ,20, 50, 100]}
              onPageChange={(index) => { 
                let skip =pageSize*index;
                getOffers(skip, labelSearch) }}
              pagination
              sortingMode="server"
              onSortModelChange={(modal) => {
                      const sort = setSorting(modal);
                      setSortOption(sort);
                      getOffers(0, '', sort)}}
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              disableSelectionOnClick
              paginationMode="server"
              rowCount={listCount}
              onPageSizeChange={(newPageSize) => {
                setPageSize(newPageSize);
                getOffers( 0, labelSearch, '', newPageSize );
              }}
              getRowId={(row) => row.id}
            />
          </Box>
        </Paper>
      </Widget>
    </div>
  )
}