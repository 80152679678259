import React, { useEffect } from 'react'
import MobileDetect from 'mobile-detect';

const Appstore = () => {
  
    let osName = 'AndroidOS';
    var md = new MobileDetect(window.navigator.userAgent);
    osName = md.os();

    console.log('OS is ', osName);
        
     

    useEffect(() => {

        if (osName && osName.indexOf('Android')>=0) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.pickmonk';
            
        }
        else {
            window.location.href = 'https://apps.apple.com/in/app/pickmonk/id1561916915';             
        }
         
    }, []);

    return (<><view>{osName}</view></>)
}

export default Appstore
