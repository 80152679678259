import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../components/section-title';
import MasterTable from '../../../components/MasterTable/MasterTable';
import Datepicker from '../../../components/datepicker'
import Widget from '../../../components/widget';
import ApiService from '../../../api/ApiService';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import {   IconButton, Tooltip } from '@mui/material';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import {
    FaDollarSign,
    FaRegAddressCard
  } from 'react-icons/fa'
  import DeleteRequestDialog from '../../../components/dialogs/DeleteAccount';
import { DataGrid } from '@mui/x-data-grid';
import { setSorting } from '../helper/helper';
const RemoveUserAccount = () => {
    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setDate(d.getDate() - 15);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const navigate = useNavigate();
    const [removeUserAccountList, setRemoveUserAccountList] = useState([]);
    const [labelSearch, setLabelSearch] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState({ keyword: "", startDate:moment(finaldate).format('YYYY-MMM-DD'), endDate:moment(new Date()).format('YYYY-MMM-DD'), status: '' });
    const [startDate, setStartDate] = useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MMM-DD'));
    const [isLoading, setIsFetching] = useState(false);
    const [total, setTotal] = useState(null)
    const [requestStatusType, setRequestStatusType] = React.useState('Pending');
    const [requestDialog, setRequestDialog] = React.useState(false);
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const [reason, setReason] = useState(null)
    const [data, setData] = React.useState(null);
    const [index, setIndex] = React.useState(null);
    const openRequestDialog = (value) => {
        setData(value);
        setRequestDialog(true);
    }
    const closeRequestDialog = () => {
        setRequestDialog(false);
    }


   


    const getRemoveUserAccountList = (currentIndex, search, sort, size) => {
        setIndex(currentIndex)
        
        const payload = {
            "filterOption": {
                'keyword': labelSearch || search,
                "startDate": startDate,
                "endDate": endDate,'status': requestStatusType
            },
            "sortOption": sort || sortOption,
            "pageSizeOption":{"skip": parseInt(currentIndex), "limit": size ? size : pageSize}
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getUsersAccountRemoveRequests`, payload).then((response) => {
            if (response.status === 200) {
               
                setRemoveUserAccountList(response.data.body.data);
                setTotal(response.data.body.recordCount)
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const acceptRemoveRequest = (id) => { 
       
         const payload = {
             "id":id,
          
         }
             ApiService.post(`v1/admin/removeUserAccountPermanenty`, payload).then((response) => {
                 
             if (response.status === 200) {
                 toast.success(response.data.message);
                 closeRequestDialog();
                 getRemoveUserAccountList(index, '')
             } else {
                 toast.error(response.data.message)
             }
             setIsFetching(false);
         }).catch((error) => {
             toast.error(error.response.data.message);
             setIsFetching(false);
         });
     }
    const columns = [
        {
            field: 'name', headerName: `Name`, flex: 1,
            renderCell: (params) => {
                return params?.row?.Users[0]?.name
            }
        },
        {
            field: 'mobile', headerName: `Mobile`, flex: 1,
            renderCell: (params) => {
                return params?.row?.Users[0]?.mobile
            }
        },
        {
            field: 'status', headerName: 'Status', flex: 1,
        
        },
     
       
        {
            field: 'requestDate', headerName: 'Created At', flex: 1,
            renderCell: (params) => {
                return moment(params.row.requestDate).format('MM/DD/YYYY');
            },
        },
        
        {field: 'action', headerName: 'Action', flex: 1,
            
        renderCell: (params) => (
       
            <div>
               { params.row.status === "Pending" && <Tooltip title="Remove account">
                    <Button   style={{ background:  'rgb(227 112 14)', color: 'white' }} onClick={() => { openRequestDialog(params.row) }} >
                 Remove
                    </Button>
                </Tooltip>}
               
            </div>
        )
    },

    ];
    const handleSearch = () => {
        getRemoveUserAccountList(0, '');
    }
    useEffect(() => {
        getRemoveUserAccountList(0, '')
    }, [])
    return (
        <>
            <SectionTitle title='Remove User Account' subtitle='Remove User Account'></SectionTitle>
            <Widget>
          
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '1%', padding: '1%' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }}>
                            <Grid item xs={1} sm={4} md={2}>
                                <TextField id="standard-basic" label="Search" variant="standard" style={{ width: '100%' }} onChange={(e) => {
                                    setLabelSearch(e.target.value)
                                }} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={startDate} onChange={(e)=>setStartDate(e.format('YYYY-MMM-DD'))}  label={'Start Date'} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                            <Datepicker Value={endDate} onChange={(e)=>setEndDate(e.format('YYYY-MMM-DD'))}  label={'End Date'} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                <TextField
                                    id="outlined-select-request-status"
                                    style={{ marginTop: '17px', width: '90%' }}
                                    select
                                    label="Filter"
                                    value={requestStatusType}
                                    onChange={(e) => setRequestStatusType(e.target.value)}
                                    size="small" >
                                   <MenuItem value={'All'}>All</MenuItem>
                                    <MenuItem value={'Pending'}>Pending</MenuItem>
                                    <MenuItem value={'Removed'}>Removed</MenuItem>
                               
                                </TextField>
                            </Grid>

                            <Grid item xs={1} sm={4} md={2}>
                                <Button variant="contained"
                                    style={{ marginTop: '17px' }}
                                    className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                    startIcon={<SearchIcon />}
                                    onClick={() => handleSearch()}
                                >
                                    Search
                                </Button>
                            </Grid>
                        
                        </Grid>

                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                            <DataGrid
                            style={{ width: '100%', height: '100px !important' }}
                            loading={isLoading}
                            rows={removeUserAccountList}
                            rowCount={total}
                            pagination
                            pageSize={pageSize}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getRemoveUserAccountList(skip, labelSearch)
                            }}
                            sortingMode="server"
                            paginationMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getRemoveUserAccountList(0, '', sort);
                                setSortOption(sort);
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getRemoveUserAccountList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                        </div>
                    {/* <MasterTable isLoading={isLoading} list={removeUserAccountList} total={total} getList={getRemoveUserAccountList} labelSearch={labelSearch} columns={columns} /> */}
                </Paper>
                <DeleteRequestDialog 
                 open={requestDialog} 
                 data={data}
                 handleClose={closeRequestDialog}
                 setReason={setReason}
                 acceptDeleteRequest={acceptRemoveRequest}
                 />
            </Widget>
        </>
    )
}
export default RemoveUserAccount