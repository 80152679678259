import React, { useEffect, useState } from "react";
import SectionTitle from "../components/section-title";
import Widget from "../components/widget";
import Paper from "@mui/material/Paper";
import { toast } from 'react-toastify';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ApiService from '../api/ApiService';

const EntityToken = () => {
    const [esToken, setesToken] = useState("");
    const [isLoading, setIsFetching] = useState(false);

    const addedForMatch = () => {

        if (esToken === '') {
            toast.error('Please enter EnterSports Token');
            return;
        }

        const user = JSON.parse(localStorage.getItem('user'));
        const data = {
            "esToken": esToken,
            "adminId": user.id
        };



        ApiService.post(`v1/admin/updateAndCheckESToken`, data).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    };

    return (
        <>
            <SectionTitle title="EntitySports Token" subtitle="EntitySports Token"></SectionTitle>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        id="standard-basic"
                        label="Enter EntitySports token"
                        variant="outlined"
                        style={{ width: "50%", marginTop: 20 }}
                        onChange={(e) => setesToken(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Button
                variant="contained"
                style={{ marginTop: "15px" }}
                className="[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]"
                onClick={() => addedForMatch()}
            >
                Save
            </Button>
        </>
    );
};

export default EntityToken;
