import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import ApiService from '../../api/ApiService';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SectionTitle from '../../components/section-title';
import Widget from '../../components/widget'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useNavigate } from 'react-router-dom';

export default function FetchContestCategoryList() {
    const [pageSize, setPageSize] = React.useState(10);
    const [contestcategoryList, setContestCategoryList] = React.useState([]);
    const [isLoading, setIsFetching] = React.useState(false);
    const navigate = useNavigate();

    const getContestCategoryList = (currentIndex, search, sort) => {
        let sorting = {}
        if (sort) {
            sort.map((s) => {
                let sortField = s.field;
                if (s.sort === 'asc') {
                    sorting[sortField] = 1
                } else {
                    sorting[sortField] = -1
                }
            })
        } else {
            sorting = {
                createdOn: -1
            }
        }
        const payload = {
            "filterOption": {
                
            },
            "sortOption": sorting,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": pageSize }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getContestCategory`, payload).then((response) => {
            if (response.status === 200) {
                setContestCategoryList(response.data.body);
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const columns = [
        { field: 'name', headerName: `Name`, flex: 1 },
        { field: 'description', headerName: 'Description', flex: 1 },
        {
            field: 'action', headerName: 'Action', flex: 1,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Edit">
                        <IconButton
                            onClick={() =>
                                (navigate(`/admin/edit-contestcategory/${params.id}`))}  >
                            <ModeEditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View Details">
                        <IconButton>
                            <PreviewOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove ContestCategory">
                        <IconButton>
                            <RemoveCircleOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        },
    ];
    React.useEffect(() => {
        getContestCategoryList(0, '')
    }, [])
    return (
        <>
            <SectionTitle title="ContestCategory" subtitle="Manage Contest Category"></SectionTitle>
            <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', padding: '10px' }}>
                        <TextField id="standard-basic" label="Search" variant="standard" style={{ width: '30%' }}
                            onChange={(e) => (getContestCategoryList(0, e.target.value))} />
                        <Button variant="contained"
                            style={{ float: 'right', margin: '10px' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<AddIcon />}
                            onClick={() => (navigate('/admin/create-contestcategory'))}
                        >
                            Create Contest Category
                        </Button>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%' }}
                            rowCount={100}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={contestcategoryList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getContestCategoryList(skip)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => getContestCategoryList(0, '', modal)}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            onPageSizeChange={(newPageSize) => { setPageSize(newPageSize); getContestCategoryList() }}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            columns={columns}

                        />
                    </div>
                </Paper>

            </Widget>
        </>
    );
}