import React from 'react';
import image from '../../../../src/images/user_img1.jpg';
const ImageRedirect = () => {
    window.open(`http://localhost:3000/${image}`, '_blank')
    return (
        <>
            {/* <img src={`http://localhost:3000/${image}`} /> */}

            {/* <span onClick={()=>window.open(`http://localhost:3000/${image}`, '_blank')}>Hello</span> */}
        </>
    )
}
export default ImageRedirect