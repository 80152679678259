import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField } from "@mui/material";
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { CustomToolbarExample } from '../../../components/text-editor';
import { useNavigate } from 'react-router-dom'
import ApiService from '../../../api/ApiService';
import SectionTitle from '../../../components/section-title';
import { FiArrowLeft } from 'react-icons/fi';
import { ArrowBack } from '@mui/icons-material';


function AddEmail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [emailTemplate, setEmailTemplate] = useState([]);
    const [error, setError] = useState(false)
    const [name, setName] = useState(false)
    const [subject, setSubject] = useState(false)
    const [body, setBody] = useState('')
    const [availablePlaceholder, setAvailablePlaceholder] = useState(false);

    let finalBody = "";
    const formatAndSetBodyContent = (newVal) => {
        finalBody = newVal;
    }
    const getEmailTemplate = () => {
        ApiService.post("v1/admin/getEmailTemplate").then((response) => {
            if (response.status === 200) {
                setEmailTemplate(response.data.body);
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }

    const validation = () => {
        if (subject === '' || body === '') {
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        if (!validation()) {
            toast.error('Please enter the details')
            return;
        }
        if (id) {
            const payload = {
                id: id,
                subject: subject,
                body: body,
                name: name,
                availablePlaceholder: availablePlaceholder
            };
            ApiService.post(`v1/admin/updateEmailTemplate`, payload).then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message);
                    navigate('/admin/email-list');
                } else {
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    }
    useEffect(() => {
        if (id) {
            getDetails();
        }
    }, [])
    const getDetails = () => {
        ApiService.post(`v1/admin/getEmailTemplateById/${id}`).then((response) => {
            if (response.status === 200) {
                setName(response.data.body.name);
                setSubject(response.data.body.subject);
                setBody(response.data.body.body);
                setAvailablePlaceholder(response.data.body.availablePlaceholder);
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }

    return (
        <div>
            <React.Fragment>
                <Grid style={{ margin: '50px' }}>
                    <form>
                        <SectionTitle title="EmailTemplate" subtitle="Create Email Template" />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Name"
                                    fullWidth
                                    name='name'
                                    error={error}
                                    onBlur={validation}
                                    value={name}
                                    disabled={true}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Subject"
                                    style={{ width: '100%' }}
                                    name='subject'
                                    multiline
                                    rows={1}
                                    error={error}
                                    onBlur={validation}
                                    value={subject}
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    onChange={(e) => setSubject(e.target.value)}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>                                
                                <CustomToolbarExample handleChange={(value) => setBody(value)} value={body} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Available Place Holder"
                                    fullWidth
                                    autoComplete="cc-exp"
                                    variant="outlined"
                                    type='string'
                                    value={availablePlaceholder}
                                    name='availablePlaceholder'
                                    disabled={true}
                                    inputProps={{ min: 0 }}
                                    onChange={(e) => setAvailablePlaceholder(e.target.value)}
                                />
                            </Grid>

                        </Grid>
                        <div className="flex items-end justify-start space-x-4 p-4">
                        <Button
                            variant="contained"
                            className='btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded'
                            style={{ marginTop: "15px" }}
                            color="success"
                            onClick={handleSubmit} size="medium" >
                            SUBMIT
                        </Button>
                        <Button className='btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded'
                            variant="contained"
                            onClick={() => { navigate(`/admin/email-list`) }}
                            size="medium" 
                            startIcon={<ArrowBack />}>
                            {/* <FiArrowLeft className="stroke-current" /> */}
                             BACK
                        </Button>
                        </div>
                    </form>
                </Grid>
            </React.Fragment>

        </div >
    );  
}
export default AddEmail