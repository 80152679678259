import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function SubmitAmount(props) {
    const { open, handleClose, addBalanceRequest, setAmount, data, setdiscription, setpassword } = props;
    const [requiredAmount, setRequiredAmount] = React.useState(null)
    const [requiredDiscription, setRequiredDiscription] = React.useState(null)
    const [requiredPassword, setRequiredPassword] = React.useState(null)
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Balance</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Amount"
                        type="Amount"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            setAmount(e.target.value);
                            setRequiredAmount(e.target.value);
                        }}
                        error={requiredAmount === null ? true : false}
                    />
                    <TextField
                        autoFocus

                        margin="dense"
                        id="name"
                        label="Discription"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            setdiscription(e.target.value);
                            setRequiredDiscription(e.target.value);
                        }}
                        error={requiredDiscription === null ? true : false}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Password"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            setpassword(e.target.value);
                            setRequiredPassword(e.target.value);
                        }}
                        error={requiredPassword === null ? true : false}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                        style={{ float: 'right', marginTop: '2%', marginRight: '1%' }}
                        color="success"
                        className='[btn btn-default text-white btn-rounded]'
                        onClick={() =>(requiredAmount!==null && requiredDiscription!==null && requiredPassword!==null) === true && addBalanceRequest(data.id)}>Submit</Button>
                    <Button
                        variant="contained"
                        style={{ float: 'right', marginTop: '2%', marginRight: '1%' }}
                        color="error"
                        className='[btn btn-default text-white btn-rounded]'
                        onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}