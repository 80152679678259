import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Widget from '../../../components/widget';
import ApiService from '../../../api/ApiService';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import SectionTitle from '../../../components/section-title';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ExportToCsv } from 'export-to-csv';
import { options, setSorting } from '../helper/helper';
import moment from 'moment';
import excelIcon from '../../../images/excel.png';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton, Tooltip } from '@mui/material';
const WalletManager = () => {
    const [pageSize, setPageSize] = useState(10);
    const [walletList, setWalletList] = useState([]);
    const [isLoading, setIsFetching] = useState(false);
    const [labelSearch, setLabelSearch] = useState('');
    const [sortOption, setSortOption] = useState({ updatedOn: -1 });
    const [total, setTotal] = useState(null)
    const [isloading, setIsloading] = useState(false);


    const processDataForCSV =(csvData)=>{

        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            if(Array.isArray(csvRow.Users)) {
               csvRow.Name = csvRow.Users[0].name;
               csvRow.Email = csvRow.Users[0].email;
               delete csvRow.Users;
            }             
        }
        return csvData;
    
    }
    const getWalletList = (currentIndex, search, sort, size,csv) => {
       let payload = {}

        if (csv) {
            payload = {
                "filterOption": {
                    'keyword': labelSearch || search,
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size }
            }
        } else {
        
        }
         payload = {
            "filterOption": {
                'keyword': labelSearch || search,
            },
                "sortOption": sort || sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getUsersWallet`, payload).then((response) => {
            if (response.status === 200) {
                if (csv) {
                    setIsFetching(false);
                    options.filename = 'Wallet manager'
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(processDataForCSV(response.data.body.data));
                } else {
                    setWalletList(response.data.body.data);
                    setTotal(response.data.body.recordCount);
                    setIsFetching(false);
                }
            } else {
                setIsFetching(false);
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    
    const handleRefresh = (walletId) => {
        if (walletId){
        setIsloading(false)
        } else {
            setIsloading(true)
        }
        const user = JSON.parse(localStorage.getItem('user'));
        let payload = {}
        if (walletId) {
            payload = {"id" :user.id, "walletId": walletId};
        } else {
        payload = {"id" :user.id};
        }
        ApiService.post(`v1/admin/updateAllUserWallet`, payload).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message);
                setIsloading(false)
                getWalletList(0, '');
            } else {
                setIsloading(false)
                toast.error(response.data.message)
            }
        }).catch((error) => {
            setIsloading(false)
            toast.error(error.response.data.message);
        });

    }

    const columns = [
        {
            field: 'Users.name', headerName: `Name`, flex: 1,
            renderCell: (params) => {
                return params.row.Users[0].name;
            }
        },
        {
            field: 'Users.email', headerName: `Email`, flex: 1,
            renderCell: (params) => {
                return params.row.Users[0].email;
            }
        },
        { field: 'deposite', headerName: 'Deposit Amount', flex: 1,
        renderCell: (params) => {
            return '₹ ' + params.row?.deposite;
        } },
        {
            field: 'bonus', headerName: 'Bonus Amount', flex: 1,
            renderCell: (params) => {
                return '₹ ' + params.row?.bonus;
            }
        },
        {
            field: 'totalBalance', headerName: 'Total Balance', flex: 1,
            renderCell: (params) => {
                return '₹ ' + params.row?.totalBalance;
            }
        },
        {
            field: 'winning', headerName: 'Winning', flex: 1,
            renderCell: (params) => {
                return '₹ ' + params.row?.winning;
            }
        },
        {
            field: 'updatedOn', headerName: 'Created At', flex: 1,
            renderCell: (params) => {
                return moment(params.row.updatedOn).format('MMM DD, YYYY HH:MM:SS');
            },
        },
        {
            field: 'action', headerName: 'Action', flex: 1,
            renderCell: ({ row }) => (
                <div>
                        <Tooltip title="Refresh">
                            <IconButton onClick={() => (handleRefresh(row.id))}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                </div>
            )
        },
        // {
        //     field: 'updatedOn', headerName: 'Updated On', flex: 1,
        // },
    ];
    const handleSearch = () => {
        getWalletList(0, '');
    }
    useEffect(() => {
        getWalletList(0, '');
    }, [])
    const downloadList = () => {
        getWalletList(0, '', '', 100000,' ');
    }
    return (
        <>
            <SectionTitle title="Wallet Manager" subtitle="Wallet Manager"></SectionTitle>
            <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', paddingLeft: '1%' }}>
                        <TextField id="standard-basic" label="Search by Name" variant="standard" style={{ width: '30%' }}
                            onChange={(e) => {
                                setLabelSearch(e.target.value);
                            }} />
                                      <img src={excelIcon} 
                                      alt='1'
                                      width='30px' 
                                      height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: 15,
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                        <Button variant="contained"
                            style={{  margin: '1%' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<SearchIcon />}
                            onClick={() => handleSearch()}>
                            Search
                        </Button>
              
                        <Button
                            variant="contained"
                            style={{ marginTop: "0px" }}
                            onClick={() => handleRefresh()}
                            loading={true}
                            startIcon={<RefreshIcon />}
                            size="medium" >
                            Refresh   
                            {isloading && 
                            <CircularProgress size={18} style={{ color: "white", marginLeft: 10 }}>
                            </CircularProgress>}
                        </Button >
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%', height: '100px !important' }}
                            rowCount={total}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={walletList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getWalletList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                setSortOption(sort);
                                getWalletList(0, '', sort)
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getWalletList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
    )
}
export default WalletManager