import React, { useEffect, useState } from 'react';
import SectionTitle from '../components/section-title';
import Widget from '../components/widget';
import { Button, Grid, TextField } from '@mui/material';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { useParams,useSearchParams } from 'react-router-dom';
import Layout from '../layouts/centered'

const FacebookStatus = () => {
 
    const [searchParams] = useSearchParams();
     const urlId = searchParams.get('user');
 

    let date= new Date();
    const Datefind=()=>{

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setMonth(d.getMonth() - 3);
       }
      
    let finaldate= new Date(Datefind()).toString("MM, DD, YYYY");
    const { user } = useParams();
    
    return (
        <>
        
            <Layout>
            
            <div className='items-center justify-center' style={{ backgroundColor: '#faf7f9' }}>
            <SectionTitle title='Facebook Status' subtitle='Facebook Status'></SectionTitle>
            <Widget>
                <Widget>
                    <Grid container spacing={2} >
                        <Grid item xs={4} md={4}>
                            <Typography gutterBottom variant="subtitle1" component="div">
                               CurrentUrlId : {urlId}
                            </Typography>
                        </Grid>
                     
                    </Grid>
                </Widget>
              
            </Widget></div>
        </Layout>
        </>
    )
}
export default FacebookStatus