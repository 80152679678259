import React, { useEffect } from 'react';
import ApiService from '../api/ApiService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import userImage from '../images/user_img1.jpg';

const ProcessImage = () => {
    const teamName = useParams();
    const getStaticPage = () => {
        ApiService.post(`v1/public/getUserProfPicToProcess`, teamName).then((response) => {
            if (response.status === 200) {
                
            } else {
                toast.error(response.data.message);
            }
        }).catch((error) => {
            toast.error(error);
        });
    }
    const base64ToImage = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    useEffect(() => {
        getStaticPage();
    }, [])
    return (
        <div>Proces</div>
    )
}
export default ProcessImage