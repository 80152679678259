import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Button, Grid, IconButton, TextField, Tooltip, Autocomplete, Checkbox, } from '@mui/material';
import ApiService from '../../api/ApiService';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SectionTitle from '../../components/section-title';
import Widget from '../../components/widget'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import { set } from 'nprogress';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ExportToCsv } from 'export-to-csv';
import { options, setSorting } from './helper/helper';
import excelIcon from '../../images/excel.png';
import ContestRemoveDialog from '../../components/dialogs/ContestRemove';
export default function FetchContestList() {
    const navigate = useNavigate();
    const [pageSize, setPageSize] = React.useState(10);
    const [contestTemplate, setContestTemplate] = React.useState([]);
    const [contestList, setContestList] = React.useState([]);
    const [labelSearch, setLabelSearch] = React.useState({"keyword":"","matchId":0});
    const [listCount, setListCount] = React.useState(null);
    const [isLoading, setIsFetching] = React.useState(false);
    const [match, setMatch] = React.useState([]);
    const [index, setIndex] = React.useState(0);
    const [upcomming, setUpcomming] = React.useState("1");
    const [matches, setMatches] = React.useState("0");
    const [requestDialog, setRequestDialog] = React.useState(false);
    const [reason, setReason] = useState(null)
    const [data, setData] = React.useState(null);
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const processDataForCSV =(csvData)=>{

        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
                delete csvRow.createdBy;
                delete csvRow.matchDetails;
                delete csvRow.contestCategory;
        }
        return csvData;
    
    }
  const openRequestDialog = (value) => {
    setData(value);
    setRequestDialog(true);
}
const closeRequestDialog = () => {
    setRequestDialog(false);
}
    const getContestList = (currentIndex, search, sort, size, csv) => {
        setIndex(currentIndex)
        labelSearch.status=upcomming;
        let payload = {}
        if (csv) {
            payload = {
                "filterOption": labelSearch,
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size }
            }
        } else {
           
        }
  
         payload = {
            "filterOption": labelSearch,
                "sortOption": sort || sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/ContestList`, payload).then((response) => {
          
            if (response.status === 200) {
                if (csv) {
                    setIsFetching(false);
                    options.filename = 'Contest list'
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(processDataForCSV(response.data.body.data));
                } else {
                    setIsFetching(false);
                    setContestList(response.data.body.data);
                    setListCount(response.data.body.recordCount)
                }
            } else {
       
              
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            setContestList([])
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }
    const getContestTemplate = () => {
        ApiService.post("v1/admin/getContestTemplates").then((response) => {
            if (response.status === 200) {
                setContestTemplate(response.data.body);
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }
    const deleteContest = (id) => {
        ApiService.post("v1/admin/DeleteContest", `id=${id}`).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message);
                getContestList(0, '');
                closeRequestDialog(false)
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }
    const columns = [
        { field: 'name', headerName: `Contest Name`, flex: 1,
        renderCell: (params) => {
                
                  
                  let textColor="black"
                  if((params.row?.isPrivate === true && params.row?.isChallenge === true)){
                    textColor="orange"
                  }else if((params.row?.isPrivate === true && params.row?.isChallenge === false)){
                    textColor = 'blue'
                  }
                        return (
    
                         <div  style={{
               
                            color:textColor,
                            padding:5,
                            borderRadius:8
                        }}>{params.row?.name}</div>
                        )
                       
            
            } },
        {
            renderCell: (params) => {
                if (params.row.matchDetails.length > 0) {
                  
                        return (
                          <TextField
                            value={params.row.matchDetails[0].title}
                            InputProps={{ disableUnderline: true, style: {fontSize: 14} }}
                            multiline
                            variant="standard"
                          />
                          
                        )
                       
                }
                return "";
            }, headerName: 'Match', flex: 2,sortable:false
        },
        {
            field: 'date_start',
            valueGetter: (params) => {
                if (params.row.matchDetails.length > 0) {
                    return moment(params.row.matchDetails[0].date_start).format('MMMM Do YYYY, h:mm:ss a');
                }
                return "";
            },
            headerName: 'Match Date', flex: 1,sortable:false
        },
        {
            field: 'contest_category',
            valueGetter: (params) => {
                if (params.row.contestCategory.length > 0) {
                    return params.row.contestCategory[0].name;
                }
                return "";
            }, headerName: 'Contest Category', flex: 1
        },
        {
            field: 'winningAmount',
            valueGetter: (params) => {
                if (params?.row?.matchDetails?.length > 0) {
                    return '₹ ' + params?.row?.winningAmount?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                }
                return "";
            },
            headerName: 'Winning Amount', flex: 1
        },
        { field: 'minEntry', headerName: 'Min Entry', flex: 1 },
        { field: 'maxEntry', headerName: 'Max Entry', flex: 1 },
        { field: 'entryFee', headerName: 'Entry Fee', flex: 1,
        renderCell: (params) => {
            return '₹ ' +params?.row?.entryFee
        } },
        {
            field: 'createdBy',
            valueGetter: (params) => {
                if (params.row.createdBy.length > 0) {
                    return params.row.createdBy[0].name;
                }
                return "";
            }, headerName: 'Created By', flex: 1
        },
        {
            field: 'createdOn',
            valueGetter: (params) => {
                if (params.row.matchDetails.length > 0) {
                    return moment(params.row.createdOn).format('MMMM Do YYYY, h:mm:ss a');;
                }
                return "";
            },
            headerName: 'Created At', flex: 1
        },
        {
            field: 'action', headerName: 'Action', flex: 1,
            renderCell: (params) => (
                <div>
                    <Tooltip title="View Details">
                        <IconButton
                            onClick={() =>
                                (navigate(`/admin/view-contest/${params.id}`))}
                        >
                            <PreviewOutlinedIcon />

                        </IconButton>
                    </Tooltip>
                    {params.row.hasTeam === true ? null :
                        <Tooltip title="Edit">
                            <IconButton
                                onClick={() =>
                                    (navigate(`/admin/edit-contest/${params.id}`))}  >
                                <ModeEditIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    {params.row.hasTeam === true ? null :
                        <Tooltip title="Remove Contest">
                            <IconButton onClick={() => (openRequestDialog(params.row))}>
                                <RemoveCircleOutlineOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </div>
            )
        },
    ];
    const downloadList = () => {
        getContestList(0, '', '', 100000, ' ');
    }
    const handleSearch = () => {
        getContestList(0, '');
    }

    const delectContestTemplete = (id) => {
        ApiService.post("v1/admin/deleteContestTemplate", `id=${id}`).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message);
                getContestTemplate();
                getContestList(0, '');
            } else {
                toast.error('Something Went Wrong')
            }
        });
    }
    React.useEffect(() => {
        getContestList(0, '');
        getContestTemplate();
        handleChange("","1")
    }, [])  
    const handleChange = (keywords,event) => {
        setUpcomming(event);
        setMatches('0')
        setLabelSearch({"keyword":labelSearch.keyword,"matchId":'0'});
        let startDate = new Date();
        let endDate =  new Date();
        if(event==='1') {            
            endDate.setDate(endDate.getDate() + 90);
        }
        if(event==='2') {
            startDate.setDate(startDate.getDate() - 180);
        }
        let stdt = startDate.getFullYear()+'-'+ (startDate.getMonth()+1) +'-'+startDate.getDate();
        let endt = endDate.getFullYear()+'-'+(endDate.getMonth()+1)+'-'+endDate.getDate();
        const payload = {
            "filterOption":{"keywords":'',"status":event, "fromDate": stdt,
            "toDate": endt} ,
            "sortOption":{ "timestamp_start":1},
            "pageSizeOption": { "skip":0, "limit":  1000 }
        }
        
        ApiService.post(`v1/admin/getMatchData`, payload).then((response) => {
            if (response.status === 200) {
                setMatch(response.data.body.data);
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
      
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
     
    };
   
    return (
        <>
            <SectionTitle title="Contest" subtitle="Manage Contests"></SectionTitle>
            <Widget>
                <Paper elevation={3}>
                    <Grid style={{ padding: '1%' }}>
                        <Grid container spacing={{ xs: 2, md: 5 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                            <Grid item xs={3} sm={4} md={2}>
                                <TextField id="standard-basic" label="Search by Contest name" 
                                    variant="standard" style={{ width: '100%' }}
                                    onChange={(e) => {
                                        setLabelSearch({"keyword":e.target.value});
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} sm={4} md={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Filter"
                                    size="small"
                                    value={upcomming}
                                    style={{ width: '100%', marginTop: '10px', }}
                               
                                   onChange={(e) =>handleChange(labelSearch.keyword,e.target.value)}
                                >
                                    <MenuItem  value={'1'}>Upcomming</MenuItem>
                                    <MenuItem value={'2'}>Completed</MenuItem>
                                    <MenuItem value={'0'}>All</MenuItem>

                                </TextField>
                            </Grid>
                            <Grid item xs={3} sm={4} md={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Matches"
                                    size="small"
                                    value={matches}
                                    onChange={(e)=>setLabelSearch({"keyword":labelSearch.keyword,"matchId":e.target.value})}
                                    style={{ width: '100%', marginTop: '10px', }}
                                
                                >
                                    <MenuItem selected={matches} value={'0'}>All</MenuItem>
                                    {match!==null && match.map((option, index) => (
                                        <MenuItem 
                                          onClick={() =>setMatches(option.match_id)}
                                          key={option.match_id}
                                          value={option.match_id}>
                                            {option.title}
                                        </MenuItem>
                                        
                                    ))}

                                </TextField>
                            </Grid>

                            <Grid item xs={3} sm={4} md={2}>
                                <Button variant="contained"
                                    style={{ marginTop: '10px', }}
                                    className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                    startIcon={<SearchIcon />}
                                    onClick={() => handleSearch()}>
                                    Search
                                </Button>
                            </Grid>
                            <Grid item xs={3} sm={4} md={2}>
                            <img src={excelIcon} width='30px' height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: '18px 5px 0 0',
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                        </Grid>
                        </Grid>
                        <Grid container style={{ alignItems: "end", paddingTop:"5px" }} spacing={{ xs: 2, md: 5 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                            <Grid item xs={4} sm={2} md={4}>
                                <Autocomplete
                                    id="checkboxes-tags-demo"
                                    options={contestTemplate}
                                    style={{ }}
                                    disableCloseOnSelect
                                    onChange={(event, value) => (navigate(`/admin/create-contest?template=${value.id}`))}
                                    getOptionLabel={(option) => option.templateName}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="flex-end">
                                                <Grid>
                                                    {option.templateName}
                                                </Grid>
                                                <Grid>
                                                    <CloseIcon
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            delectContestTemplete(option.id)
                                                        }}
                                                        style={{ height: '17px', color: 'red' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Create Using Template" placeholder="Create Using Template" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} sm={4} md={2}>
                                <Button variant="contained"
                                    style={{  margin: '1%' }}
                                    className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                    startIcon={<AddIcon />}
                                    onClick={() => (navigate('/admin/create-contest'))}>
                                    Create Contest
                                </Button>
                            </Grid> 
                            <Grid item xs={3} sm={4} md={3}>
                                <Button variant="contained"
                                    style={{  margin: '1%', marginLeft: '0%' }}
                                    className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                    startIcon={<AddIcon />}
                                    onClick={() => (navigate('/admin/create-contestCategory'))} >
                                    Contest Category
                                </Button>
                                
                            </Grid>
                           
                        </Grid>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%' }}
                            rowHeight={70}
                            rowCount={listCount}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={contestList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getContestList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                            const sort = setSorting(modal);
                            getContestList(0, '', sort);
                            setSortOption(sort);
                        }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize)
                                getContestList(0, labelSearch, '', newPageSize);
                            }}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            columns={columns}

                        />
                    </div>
                </Paper>
                <ContestRemoveDialog 
                 open={requestDialog} 
                 data={data}
                 handleClose={closeRequestDialog}
                 setReason={setReason}
                 acceptDeleteRequest={deleteContest}
                 />
            </Widget>
        </>
    );
}