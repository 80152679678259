import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Index from './pages/index'
import Dashboard from './pages/dashboard'
import ContactUs1 from './pages/contact-us-1'
import TextEditor from './pages/text-editor'
import Landing from './pages/landing'
import FetchContestList from './pages/admin/contest-list';
import WhatshAppNotification from './pages/admin/whatshAppNotification'
import AddContest from './pages/admin/add-contest';
import AddContestCategory from './pages/admin/add-contestcategory';
import FetchContestCategoryList from './pages/admin/contestcategory-list';
import FetchSeriesList from './pages/admin/matches/series-list';
import FetchMatchList from './pages/admin/matches/match-list';
import FetchParticipantList from './pages/admin/matches/participant-list';
import AdminLogin from './pages/admin/login'
import Home from './pages/home'
import ProfilePic from './pages/profile-pic';
import PlayerManager from './pages/admin/PlayerManager/PlayerManager'
import Teams from './pages/admin/Teams/Teams'
import UpdateTeamData from './pages/admin/Teams/UpdateTeamData'
import PanRequest from './pages/admin/PanRequest/PanRequest'
import Settings from './pages/admin/Setting/Setting'
import GetAllBanks from './pages/admin/GetAllBanks/GetAllBanks'
import FetchEmailList from './pages/admin/EmailTemplate/email-list'
import AddEmail from './pages/admin/EmailTemplate/add-email'
import FetchNotificationList from './pages/admin/Notification/notification-list'
import AddNotification from './pages/admin/Notification/add-notification'
import WithdrawRequest from './pages/admin/WithdrawRequest/WithdrawRequest'
import UserOffer from './pages/admin/UserOffer/UserOffer'
import CashbackOffer from './pages/admin/CashbackOffer/cashbackOffer'
import CreateOffer from './pages/admin/UserOffer/create-offer'
import CreateCashbackOffer from './pages/admin/CashbackOffer/create-cashbackOffer'
import StaticPage from './pages/admin/StaticPage/StaticPage'
import CreatePage from './pages/admin/StaticPage/create-page'
import ContentPage from './pages/content-page'
import Transaction from './pages/admin/Transaction/Transaction'
import Gst from './pages/admin/Gst/Gst'
import ImageRedirect from './pages/admin/ImageRedirect/ImageRedirect'
import SendNotification from './pages/admin/SendNotification/SendNotification'
import ManageMatch from './pages/admin/matches/ManageMatch';
import ManagePlayer from './pages/admin/matches/ManagePlayer';
import ProcessImage from './pages/ProcessImage'
import WalletManager from './pages/admin/WalletManager/WalletManager'
import GSTReport from './pages/admin/Reports/GSTReport/GSTReport'
import NewGSTReport from './pages/admin/Reports/GSTReport/newGSTReport'
import NotificationLog from './pages/admin/SendNotification/NotificationLog'
import TDSReport from './pages/admin/Reports/TDSReport/TDSReport'
import TDSReportByMonth from './pages/admin/Reports/TDSReport/TDSReportByMonth'
import EarningReport from './pages/admin/Reports/Earnings/EarningReport'
import CashBackReport from './pages/admin/Reports/CashBack/CashBackReport'
import UserContestReport from './pages/admin/Reports/Contest/UserContestReport'
import RequestReport from './pages/admin/Reports/RequestReport/RequestReport'
import PayInReport from './pages/admin/Reports/PayIn-Out/PayInReport'
import PayOutReport from './pages/admin/Reports/PayIn-Out/PayOutReport'
import UserTransaction from './pages/admin/matches/UserTranactions';
import UsersPaymentSettlement from './pages/admin/Settlement/usersPaymentSettlement';
import RemoveUserAccount from './pages/admin/RemoveAccount/removeUserAccount';
import UserEnquires from './pages/admin/Enquires/UserEnquires';
import TDS from './pages/admin/TDS/TDS';
import EntityToken from './pages/EntityToken'
import TDSbyline from './pages/admin/WithdrawRequest/TDSbyline';
import FacebookStatus from './pages/FacebookStatus';
import ChangePassword from './pages/admin/ChangePassword/ChangePassword';
import Appstore from './pages/appstore'
import DigiLockerHandler from './pages/digilockerHandler';
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import SubAdminList from './pages/admin/SubAdmin/SubAdminList'
import { SubAdminForm } from './pages/admin/SubAdmin/SubAdminForm'
import jwt_decode from "jwt-decode";
 
const AppRoutes = () => {
    const navigate = useNavigate()

    const reload = () => window.location.reload();


    const token = localStorage.getItem('token');
    function isLogin(name) {
        const currentURL = window.location.href;
        if (token && currentURL.toLowerCase().indexOf('/admin/') > 0) {
            return <Navigate to="/admin/dashboard" />
        }
        if (currentURL.toLowerCase().indexOf('/admin/') > 0) {
            return <Navigate to="/admin/" />
        }
        else {
            return name;
        }
        
    }
    function redirect(name) {
        const currentURL = window.location.href;
        if (!token && currentURL.toLowerCase().indexOf('/admin/') > 0) {
            return <Navigate to="/admin" />;
        } else {
        
            if (token) {
                var decoded = jwt_decode(token);
                let h=moment(decoded.expiry).format('MMM DD, YYYY HH:mm:ss')
                if (new Date(h).getTime() < Date.now()) {
                    localStorage.clear();
                    navigate('/admin');
                }
            }
            return name;
        }
    }

    return (
        <Routes>
            <Route path="/.well-known/apple-app-site-association" onEnter={reload} />
            <Route path="/admin/contest-list" element={redirect(<FetchContestList />)} />
            <Route path="/admin/Whatsapp" element={redirect(<WhatshAppNotification />)} />
            <Route path="/admin/notification-list" element={redirect(<FetchNotificationList />)} />
            <Route path="/admin/email-list" element={redirect(<FetchEmailList />)} />
            <Route path="/admin/contestcategory-list" element={redirect(<FetchContestCategoryList />)} />
            <Route path="/admin/series-list" element={redirect(<FetchSeriesList />)} />
            <Route path="/admin/match-list" element={redirect(<FetchMatchList />)} />
            <Route path="/admin/manage-match/:id" element={redirect(<ManageMatch />)} />
            <Route path="/admin/announce-player/:id" element={redirect(<ManagePlayer />)} />
            <Route path="/admin/TDSbyline/:id" element={redirect(<TDSbyline />)} />
            <Route path="/admin/participant-list" element={redirect(<FetchParticipantList />)} />
            <Route path="/admin/create-contest" element={redirect(<AddContest />)} />
            <Route path="/admin/edit-contest/:id" element={redirect(<AddContest />)} />
            <Route path="/admin/view-contest/:id" element={redirect(<AddContest />)} />
            <Route path="/admin/create-notification" element={redirect(<AddNotification />)} />
            <Route path="/admin/add-notification" element={redirect(<AddNotification />)} />
            <Route path="/admin/edit-notification/:id" element={redirect(<AddNotification />)} />
            <Route path="/admin/create-contestcategory" element={redirect(<AddContestCategory />)} />
            <Route path="/admin/edit-contestcategory/:id" element={redirect(<AddContestCategory />)} />
            <Route path="/admin/send-notification" element={redirect(<SendNotification />)} />
            <Route path='/admin/withdraw-request' element={redirect(<WithdrawRequest />)} />
            <Route path='/admin/player-manager' element={redirect(<PlayerManager />)} />
            <Route path="/landing" element={Landing} />
            <Route path='/' element={isLogin(<Home />)} />
            <Route path="/contact-us-1" element={ContactUs1} />
            <Route path='/profile-pic' element={<ProfilePic />} />
            <Route path="/admin" element={isLogin(<AdminLogin />)} />
            <Route path="/admin" element={isLogin(<Appstore />)} />
            
            <Route path="/admin/" element={isLogin(<AdminLogin />)} />
            <Route path='/admin/teams' element={redirect(<Teams />)} />
            <Route path='/admin/UpdateTeamData' element={redirect(<UpdateTeamData />)} />
            <Route path="/admin/gst-list" element={redirect(<Gst />)} />
            <Route path="/content/:pageName" element={<ContentPage />} />
            <Route path='/admin/offers' element={redirect(<UserOffer />)} />
            <Route path='/admin/CashbackOffer' element={redirect(<CashbackOffer />)} />
            <Route path='/admin/settings' element={redirect(<Settings />)} />
            <Route path='/admin/dashboard' element={redirect(<Dashboard />)} />
            <Route path="/player/image" element={redirect(<ImageRedirect />)} />
            <Route path="/admin/create-email" element={redirect(<AddEmail />)} />
            <Route path='/admin/pan-request' element={redirect(<PanRequest />)} />
            <Route path='/admin/getAllBank' element={redirect(<GetAllBanks />)} />
            <Route path='/admin/static-pages' element={redirect(<StaticPage />)} />
            <Route path='/admin/transaction' element={redirect(<Transaction />)} />
            <Route path="/admin/edit-email/:id" element={redirect(<AddEmail />)} />
            <Route path='/admin/offers/create' element={redirect(<CreateOffer />)} />
            <Route path='/admin/create-cashbackOffer' element={redirect(<CreateCashbackOffer />)} />
            <Route path='/admin/create-cashbackOffer/edit/:id' element={redirect(<CreateCashbackOffer />)} />
            <Route path='/admin/walletManager' element={redirect(<WalletManager />)} />
            <Route path='/admin/RequestReport' element={redirect(<RequestReport />)} />
            <Route path='/admin/GSTReport' element={redirect(<GSTReport />)} />
            <Route path='/admin/NewGSTReport' element={redirect(<NewGSTReport />)} />
            <Route path='/admin/NotificationLog' element={redirect(<NotificationLog />)} />
            <Route path='/admin/TDSReport' element={redirect(<TDSReport />)} />
            <Route path='/admin/TDSReportByMonth' element={redirect(<TDSReportByMonth />)} />
            <Route path='/admin/EarningReport' element={redirect(<EarningReport />)} />
            <Route path='/admin/CashBackReport' element={redirect(<CashBackReport />)} />
            <Route path='/admin/UserContestReport' element={redirect(<UserContestReport />)} />
            <Route path='/admin/PayInReport' element={redirect(<PayInReport />)} />
            <Route path='/admin/PayOutReport' element={redirect(<PayOutReport />)} />
            <Route path='/admin/offers/edit/:id' element={redirect(<CreateOffer />)} />
            <Route path='/admin/static-pages/create' element={redirect(<CreatePage />)} />
            <Route path='/admin/static-pages/edit/:id' element={redirect(<CreatePage />)} />
            <Route path='/admin/withdraw-request' element={redirect(<WithdrawRequest />)} />
            <Route path="/generateprofpic/teamName=:teamName" element={redirect(<ProcessImage />)} />
            <Route path="/text-editor" element={<TextEditor />} />
            <Route path="/admin/view-details/:id" element={redirect(<UserTransaction />)} />
            <Route path='/admin/userPayment-settlement' element={redirect(<UsersPaymentSettlement />)} />
            <Route path='/admin/remove-UserAccount' element={redirect(<RemoveUserAccount />)} />
            <Route path='/admin/User-Enquires' element={redirect(<UserEnquires />)} />
            <Route path='/admin/TDS' element={redirect(<TDS />)} />
            <Route path='/admin/EntityToken' element={redirect(<EntityToken />)} />
            <Route path='/facebook-status' element={redirect(<FacebookStatus />)} />
            <Route path='/digilocker-handler' element={redirect(<DigiLockerHandler />)} />
            <Route path='/admin/change-password' element={redirect(<ChangePassword />)} />
            <Route path="/admin/sub-admin" element={redirect(<SubAdminList />)} />
            <Route path="/admin/sub-admin/add" element={redirect(<SubAdminForm />)} />
            <Route path="/admin/sub-admin/edit/:id" element={redirect(<SubAdminForm />)} />
        </Routes>
    )
}
export default AppRoutes
