import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../components/section-title';
import MasterTable from '../../../components/MasterTable/MasterTable';
import Datepicker from '../../../components/datepicker'
import Widget from '../../../components/widget';
import ApiService from '../../../api/ApiService';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import { Button, Grid, TextField } from '@mui/material';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { ExportToCsv } from 'export-to-csv';
import { options, setSorting } from '../helper/helper';
import excelIcon from '../../../images/excel.png';
const Transaction = () => {
    let date = new Date();
    const Datefind = () => {

        var a = moment(date).format('MMM DD, YYYY ')
        var d = new Date(a);

        return d.setDate(d.getDate() - 5);
    }

    let finaldate = new Date(Datefind()).toString("MM, DD, YYYY");
    const navigate = useNavigate();
    const [transactionList, setTransactionList] = useState([]);
    const [labelSearch, setLabelSearch] = useState('');
    const [amountData, setAmountData] = useState('');
    const [search, setSearch] = useState({ "includeAll":false, keyword: "", startDate: moment(finaldate).format('YYYY-MMM-DD'), endDate: moment(new Date()).format('YYYY-MMM-DD'), transactionType: '0' });
    const [enddate, setEnddate] = React.useState(moment(new Date()).format('YYYY-MMM-DD'));
    const [startdate, setStartdate] = React.useState(moment(finaldate).format('YYYY-MMM-DD'));
    const [isLoading, setIsFetching] = useState(false);
    const [total, setTotal] = useState(null)
    const [pageSize, setPageSize] = useState(10);
    const [sortOption, setSortOption] = useState({ "startDate": -1 });
      const processDataForCSV =(csvData)=>{

        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
       
            
            if(Array.isArray(csvRow.Users)) {
             
            
            delete csvRow.Users;
            delete csvRow.id;
             
            }             
        }
        return csvData;
    
    }
    const getTransactionList = (currentIndex, value, sort, size,csv) => {
        let payload = {}

        if (csv) {
            payload = {
                "filterOption": {
                    'keyword': labelSearch,
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size }
            }
        } else {

        }
        
        if (search.transactionType && search.transactionType === "200") {
            search.includeAll = true;
            search.transactionType = "0";
        }
        else {
            search.includeAll = false;
        }

        payload = {
            "filterOption": search,
            "sortOption": sort || sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
        }

        setIsFetching(true);
        ApiService.post(`v1/admin/getAllTransactions`, payload).then((response) => {
            if (response.status === 200) {
                if (csv) {
                    setIsFetching(false);
                    options.filename = 'Transaction list'
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(processDataForCSV(response.data.body.data.transactions));
                } else {
                    setTransactionList(response.data.body.data);
                    setTotal(response.data.body.recordCount)
                    setIsFetching(false);
                }
            } else {
                setIsFetching(false);
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }

    const columns = [
        {
            field: 'Users.name', headerName: `Name`, flex: 1,
            renderCell: (params) => {
                return params?.row?.Users[0]?.name
            }
        },
        {
            field: 'Users.email', headerName: 'Email', flex: 1,
            renderCell: (params) => {
                return params?.row?.Users[0]?.email
            },
        },
        {
            field: 'amount', headerName: 'Amount', flex: 1,
            renderCell: (params) => {
                if (params.row.amount !== null && params.row.amount !== undefined) {
                    return <div style={{ color: params.row.isCredit === false ? "red" : "green" }}>
                        ₹ {params.row.amount.toLocaleString()}
                    </div>
                }
            },



        },
        {
            field: 'isPaymentSuccess', headerName: 'Success', flex: 1,
            renderCell: (params) => {
                if (params.row.isPaymentSuccess === true) {
                    return <div style={{ color: "green" }}>Yes</div>
                }
                return <div style={{ color: "red" }}>No</div>
            },
        },
        {
            field: 'transactionNote', headerName: 'Note', flex: 1
        },
        {
            field: 'startDate', headerName: 'Created At', flex: 1,
            renderCell: (params) => {
                return moment(params.row.startDate).format('MMM DD, YYYY HH:MM:SS');
            },
        },
    ];
    const handleSearch = () => {
        getTransactionList(0, '');
    }
    useEffect(() => {
        getTransactionList(0, '')
    }, [])
    const downloadList = () => {
        getTransactionList(0, '', '', 100000,' ');
    }
    return (
        <>
            <SectionTitle title='Transaction' subtitle='Transaction'></SectionTitle>
            <Widget>
                <Widget>
                    <Grid container spacing={2} >
                        <Grid item xs={4} md={4}>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Total Deposite Amount :  ₹ {transactionList?.TotalDepositeAmount !== undefined && transactionList?.TotalDepositeAmount !== null && transactionList?.TotalDepositeAmount.toLocaleString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Total Winning Amount :  ₹ {transactionList?.TotalWinningAmount !== undefined && transactionList?.TotalWinningAmount !== null && transactionList?.TotalWinningAmount.toLocaleString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Total Withdrawal Amount :  ₹ {transactionList?.TotalWithdrawalAmount !== undefined && transactionList?.TotalWithdrawalAmount !== null && transactionList?.TotalWithdrawalAmount.toLocaleString()}
                            </Typography>
                        </Grid>
                    </Grid>
                </Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '1%', padding: '1%' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }}>
                            <Grid item xs={1} sm={4} md={2}>
                                <TextField id="standard-basic" label="Search by Name" variant="standard" style={{ width: '100%' }} onChange={(e) => {
                                    setSearch({
                                        ...search, keyword: e.target.value
                                    })
                                }} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                <Datepicker Value={startdate} onClick={(e) => setStartdate(e.format('YYYY-MMM-DD'))} label={'Start Date'} onChange={(e) => setSearch({
                                    ...search, startDate: e.format('YYYY-MMM-DD')
                                })} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                <Datepicker Value={enddate} onClick={(e) => setEnddate(e.format('YYYY-MMM-DD'))} label={'End Date'} onChange={(e) => setSearch({
                                    ...search, endDate: e.format('YYYY-MMM-DD')
                                })} />
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                <TextField
                                    style={{ marginTop: '17px', width: '90%' }}
                                    select
                                    onChange={(e) => setSearch({
                                        ...search, transactionType: e.target.value
                                    })}
                                    SelectProps={{
                                        native: true
                                    }}
                                    size="small" >
                                    <option value={200}>Included all</option>
                                    <option selected value={0}>All</option>
                                    <option value={1}>Amount added</option>
                                    <option value={2}>Withdrawal</option>
                                    <option value={4}>Winning</option>
                                    <option value={5}>Coupon Code</option>
                                    <option value={8}>Bonus Received</option>
                                    <option value={10}>Contest Joined</option>
                                    {/* <option value={24}>Refund</option> */}
                                    <option value={100}>TDS Only</option>
                                </TextField>
                            </Grid>

                            <Grid item xs={1} sm={4} md={2}>
                                <Button variant="contained"
                                    style={{ marginTop: '17px' }}
                                    className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                                    startIcon={<SearchIcon />}
                                    onClick={() => handleSearch()}
                                >
                                    Search
                                </Button>
                            </Grid>
                            <Grid item xs={1} sm={4} md={2}>
                                
                                <img src={excelIcon}
                                width='30px'
                                height='30px' style={{
                                    display: 'inline-flex',
                                    float: 'right',
                                    margin: 15,
                                    cursor: 'pointer'
                                }} onClick={() => downloadList()} />
                            </Grid>
                                  
                        </Grid>

                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%', height: '100px !important' }}
                            rowCount={total || 100}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={transactionList?.transactions || []}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getTransactionList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                setSortOption(sort);
                                getTransactionList(0, '', sort)
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getTransactionList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                    </div>
                    {/* <MasterTable isLoading={isLoading} list={transactionList?.transactions} total={total} getList={getTransactionList} labelSearch={labelSearch} columns={columns} /> */}
                </Paper>
            </Widget>
        </>
    )
}
export default Transaction