import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../../components/section-title';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Widget from '../../../../components/widget';
import ApiService from '../../../../api/ApiService';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import { options, setSorting } from '../../helper/helper';
import excelIcon from '../../../../images/excel.png';
import Datepicker from '../../../../components/datepicker';
import MenuItem from '@mui/material/MenuItem';

const PayOutReport = () => {
    let date= new Date();
    
    const [currentDate, setcurrentDate] = useState(moment(new Date()).format('YYYY-MMM-DD'));
    const [pageSize, setPageSize] = useState(10);
    const [EarningList, setEarningList] = useState([]);
    const [isLoading, setIsFetching] = useState(false);
    const [labelSearch, setLabelSearch] = useState('');
    const [sortOption, setSortOption] = useState({ startDate: -1 });
    const [total, setTotal] = useState(null)
    const processDataForCSV =(csvData)=>{

        let allCSVRows=[];
        for (let index = 0; index < csvData.length; index++) {
            let csvRow = csvData[index];
            let row ={};
            row.serialNum =index+1;
            row.createdOn = csvRow.createdOn;
            row.transactionId = csvRow.transactionId;
            row.Usersname = csvRow.Users[0].name;
            row.PanNumber = csvRow.UserPANCard[0].panNumber;
            row.amount = csvRow.amount;
            allCSVRows.push(row)
        }
        // return csvData;
        return allCSVRows;
    
    }
    const getPayOutList = (currentIndex, search, sort, size,csv) => {
        let payload = {}
 
        const user = JSON.parse(localStorage.getItem('user'));
         if (csv) {
             payload = {
                "filterOption": { 
                    'keyword': labelSearch || search,"startDate": currentDate,
                    "endDate": currentDate
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
            }
         } else {
         }
          payload = {
                "filterOption": { 
                    'keyword': labelSearch || search,"startDate": currentDate,
                    "endDate": currentDate
                },
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
            }
         setIsFetching(true);
         ApiService.post(`v1/admin/reconcilePayout`, payload).then((response) => {
             if (response.status === 200) {
                 if (csv) {
                     setIsFetching(false);
                     options.filename = 'PayOut Report'
                     const csvExporter = new ExportToCsv(options);
                     csvExporter.generateCsv(processDataForCSV(response.data.body.data));
                 } else {
                    setEarningList(response.data.body.data);
                     setTotal(response.data.body.recordCount);
                     setIsFetching(false);
                 }
             } else {
                 setIsFetching(false);
                 toast.error(response.data.message)
             }
         }).catch((error) => {
             toast.error(error.response.data.message);
             setIsFetching(false);
         });
     }
     const columns = [
        {
            field: 'createdOn', headerName: 'Date', flex: 1,
            valueGetter: (params) => {
                return moment(params.row.createdOn).format('MMM Do YYYY, h:mm:ss a');
            },
        },
        {
            field: 'transactionId', headerName: `Transaction Id`, flex: 1,
            renderCell: (params) => {
                return params.row?.transactionId;
            }
        },
        {
            field: 'name', headerName: `Users Name`, flex: 1,
            renderCell: (params) => {
                return params.row?.Users[0].name;
            }
        },
        {
            field: 'panNumber', headerName: `Pan Number`, flex: 1,
            renderCell: (params) => {
                return params.row?.UserPANCard[0].panNumber;
            }
        },
        {
            field: 'amount', headerName: `Withdrawal Amount Less TDS`, flex: 1,
            renderCell: (params) => {
                return '₹ ' +   params.row?.amount;
            }
        },
        {
            field: 'amountInCashFree', headerName: `Transferred Amount By Cashfree To Customer`, flex: 1,
            renderCell: (params) => {
                return '₹ ' +   params.row?.amountInCashFree;
            }
        },
        {
            field: 'difference', headerName: `Difference`, flex: 1,
            renderCell: (params) => {
                if (params.row.difference !== null && params.row.difference !== undefined) {
                return <div style={{ color: params.row?.difference === 0 ? "green" : "red" }}>
                ₹ {params.row?.difference}
                </div>
                // return '₹' + params.row?.difference
            }}
        },
        
    ];
    const handleSearch = () => {
        getPayOutList(0, '');
    }
    useEffect(() => {
        getPayOutList(0, '');
    }, [])
    const downloadList = () => {
        getPayOutList(0, '', '', 100000,' ');
    }
    return (
        <>
         <SectionTitle title="Reconcile Payout" subtitle="Reconcile Payout Report"></SectionTitle>
         <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', margin: '10px' }}>
                        <Grid container spacing={{ xs: 2, md: 2 }} >
                            
                            
                        <Grid item xs={1} sm={4} md={2}>
                        <Datepicker Value={currentDate} onChange={(e)=>setcurrentDate(e.format('YYYY-MMM-DD'))}  label={'Date'} />
                        </Grid>
                        <Grid item xs={1} sm={4} md={2}>
                        <Button variant="contained"
                            style={{ marginTop: '7%' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<SearchIcon />}
                            onClick={() => handleSearch()}>
                            Search
                        </Button>
                        </Grid>
                        
                            <Grid item xs={1} sm={4} md={2}>
                                      <img src={excelIcon} 
                                      alt='1'
                                      width='30px' 
                                      height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: 15,
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                        </Grid>
              </Grid>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row._id}
                            style={{ width: '100%', height: '100px !important' }}
                            rowCount={total}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={EarningList}
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getPayOutList(skip, labelSearch)
                            }}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                setSortOption(sort);
                                getPayOutList(0, '', sort)
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            paginationMode="server"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getPayOutList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                    </div>
                </Paper>
            </Widget>
        </>
        )
    }
    
export default PayOutReport