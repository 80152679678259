import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import ApiService from '../../../api/ApiService';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import SectionTitle from '../../../components/section-title';
import Widget from '../../../components/widget'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ExportToCsv } from 'export-to-csv';
import { useNavigate } from 'react-router-dom';
import { options, setSorting } from '../helper/helper';
import excelIcon from '../../../images/excel.png';
import Switch from '@mui/material/Switch';

export default function FetchSeriesList() {
    const [pageSize, setPageSize] = React.useState(10);
    const [seriesList, setSeriesList] = React.useState([]);
    const [labelSearch, setLabelSearch] = React.useState('');
    const [isLoading, setIsFetching] = React.useState(false);
    const [listCount, setListCount] = React.useState(null);
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    
    const navigate = useNavigate();
 
    const getSeriesList = (currentIndex, search, sort, size,csv) => {
          let payload = {}
        if (csv) {
            payload = {
                "filterOption": labelSearch,
                "sortOption": sort || sortOption,
                "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size }
            }

        } else {
        
        }
         payload = {
            "filterOption": labelSearch || search,
            "sortOption": sort || sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size ? size : pageSize }
        }
        setIsFetching(true);
        ApiService.post(`v1/admin/getAllCompetitions`, payload).then((response) => {
            if (response.status === 200) {
                if (csv) {
                    setIsFetching(false);
                    options.filename = 'Series'
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(response.data.body);
                } else {
                    setSeriesList(response.data.body);
                    setListCount(response.data.body.recordCount)
                }
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }

    const setStatus = (status,cid) => {
        const payload = {
            "cid":cid,
            "isEnabled":status===false? true:false
        }
            ApiService.post(`v1/admin/enableDisableCompetition`, payload).then((response) => {

            if (response.status === 200) {
                toast.success(response.data.message);
                getSeriesList(0, '');
            } else {
                toast.error(response.data.message)
            }
            setIsFetching(false);
        }).catch((error) => {
            toast.error(error.response.data.message);
            setIsFetching(false);
        });
    }

    const columns = [
        { field: 'title', headerName: `Series Name`, flex: 1 },
        { field: 'datestart', headerName: 'Start Date', flex: 1 },
        { field: 'dateend', headerName: 'End Date', flex: 1 },
        { field: 'category', headerName: 'Category', flex: 1 },
        {
            field: 'isEnabled', headerName: `Status`, flex: 1,
            renderCell: (params) => {
                return <Switch
                    checked={params.row.isEnabled}
                    onChange={(e) => setStatus(params.row.isEnabled,params.row.cid)}
                />
            }
        },
        
    ];
    const onSearch = () => {
        getSeriesList(0, '')
    }
    React.useEffect(() => {
        getSeriesList(0, '')
    }, [])
    const downloadList = () => {
        getSeriesList(0, '', '', 100000,' ');
    }
    return (
        <>
            <SectionTitle title="Series" subtitle="Manage Series"></SectionTitle>
            <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', padding: '1%' }}>
                        <TextField id="standard-basic" label="Series name" variant="standard" style={{ width: '30%' }}
                            onChange={(e) => {
                                setLabelSearch(e.target.value)
                            }
                            } />
                              <img src={excelIcon} width='30px' height='30px' style={{
                            display: 'inline-flex',
                            float: 'right',
                            margin: '18px 5px 0 0',
                            cursor:'pointer'
                        }}  onClick={() => downloadList()}/>
                       
                        <Button variant="contained"
                            style={{  margin: '1%' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            startIcon={<SearchIcon />}
                            onClick={() => onSearch()}
                        >
                            Search
                        </Button>
                      
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%', marginTop: '1%' }}>
                        <DataGrid
                            getRowId={(row) => row.id}
                            style={{ width: '100%' }}
                            rowCount={listCount}
                            loading={isLoading}
                            pageSize={pageSize}
                            rows={seriesList}
                            pagination
                            sortingMode="server"
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getSeriesList(0, '', sort);
                                setSortOption(sort)
                            }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getSeriesList(0, labelSearch, '', newPageSize)
                            }}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            columns={columns}

                        />
                    </div>
                </Paper>
                
            </Widget>
        </>
    );
}