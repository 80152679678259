import React from 'react'
import mainLogo from './../css/public/images/btn-goggleplay.png';
import appstore from './../css/public/images/btn-appstore.png';
import icmatch1 from './../css/public/images/ic-match1.png';
import icmatch2 from './../css/public/images/ic-match2.png';
import icmatch3 from './../css/public/images/ic-match3.png';
import userimg1 from './../css/public/images/user_img1.jpg';
import userimg2 from './../css/public/images/user_img2.jpg';
import userimg3 from './../css/public/images/user_img3.jpg';
import logofoo from './../css/public/images/logofoot.png';
import CommanFooter from './CommanFooter';

const Home = () => {
    return (<>
        <header class="home" >
            <div class="container" style={{justifyContent:"space-between"}}>
                {/* <a class="navbar-brand logo" href="#"><img src="assets/images/logo.png" alt="" /></a> */}
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span> </button>
            </div>
        </header>
        <main>
            <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item item1 active">
                        <div class="container">
                            <div class="carousel-caption text-start">
                                <h1>
                                    WORLD'S FASTEST GROWING<br />
                                     FANTASY PLATFORM
                                </h1>
                                <p>
                                    <a href="https://play.google.com/store/apps/details?id=com.pickmonk" download><img class="img-fluid appbtn" src={mainLogo} alt="" /></a>
                                    <a href="https://apps.apple.com/us/app/pickmonk/id1561916915" target="_ios" ><img class="img-fluid appbtn" src={appstore} alt="" /></a>
                                </p>
                                <p class="smalltxt">DOWNLOAD THE PICKMONK APP NOW</p>
                            </div>
                            
                            <img src="assets/images/iphone.png" class="img-fluid iphoneapp" alt="" />
                             
                        </div>
                    </div>
                    <div class="carousel-item item2">
                        <div class="container">
                            <div class="carousel-caption text-start">
                                <h1>
                                WORLD'S FASTEST GROWING<br />
                                     FANTASY PLATFORM
                                </h1>
                                <p>
                                    <a href="https://play.google.com/store/apps/details?id=com.pickmonk" download><img class="img-fluid appbtn" src={mainLogo} alt="" /></a>
                                    <a href="https://apps.apple.com/us/app/pickmonk/id1561916915" target="ios">
                                        <img class="img-fluid appbtn" src={appstore} alt="" /></a></p>
                                <p class="smalltxt">DOWNLOAD THE PICKMONK APP NOW</p>
                            </div>
                            <img src="assets/images/iphone.png" class="img-fluid iphoneapp" alt="" />
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
            <section class="greenstrip">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 text-center">
                            <div class="rating">
                                <span>4.3</span>
                                <p>APP RATING</p>
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <div class="users">
                                <span>10</span>
                                <p>CRORE+ USERS</p>
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <div class="contest">
                                <span>2K+</span>
                                <p>DAILY CONTESTS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="easy-steps">
                <div class="container">
                    <h2>3 Easy Steps</h2>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="select-match">
                                <i><img class="grow" src={icmatch1} alt="" /></i> <span class="title">Select a match</span>
                                <p>
                                    Select an upcoming match of <br />
                                    your choice
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="select-match">
                                <i><img class="grow" src={icmatch2} alt="" /></i> <span class="title">Create your own team</span>
                                <p>
                                    Use your sports knowledge &amp; <br />
                                    check player stats on the platform
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="select-match">
                                <i><img class="grow" src={icmatch3} alt="" /></i> <span class="title">Join free & cash contests</span>
                                <p>
                                    Participate in Cash or Practice<br />
                                    Contests.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="about">
                <div class="container">
                    <h2>Play Fantasy Cricket On Pick<span>monk</span></h2>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 pt20">
                            <h6>1. India’s fastest growing fantasy App</h6>
                            <br />
                            <p>Pickmonk is India's fastest growing gaming platform that offers daily fantasy sports.</p><br></br>
                            <p>You can download App for free and play free and paid contests.</p>
                            <p>Pickmonk offers the ultimate gaming experience and promises unlimited entertainment which makes playing fantasy games all the more fun.</p>
                            <p>The app features cash contests and tournaments for cricket where users can participate and earn money from games daily.</p>
                            <p>The platform also offers free games download for users to train with practice games.</p>
                            <p>Try a handful of free practice games before you accept the challenge to play the cash games.</p><br></br>
                            <p>Earn maximum points with the teams you have created and win cash prizes on the basis of your ranks.</p>
                            <p>You also get a referral bonus for every referral you make. Pickmonk is a 100% secure and legal real money games App recognized by users for the next-level gaming experience.</p>
                            <p>Pickmonk is available on both Google Play store and Apple store in India.</p>
                            <p>Android users can download the Pickmonk app directly from Google Play store, while iOS users can download the app directly from the Apple App Store to play games online for free.</p>
                            <br/>
                            <h6>2. Features:</h6>
                            <br/>
                            <p><b>Live leader Board</b><br></br> Pickmonk offers live leaderboard where you can track your fantasy points and ranks for all the contests.</p>
                            <br></br><p><b>Low Platform fees thus Maximum winnings</b><br></br> Pickmonk offers all the contests with the lowest platform fees thus contestants gets a maximum chance of winning the prizes.</p>
                            <br></br><p><b>Minimum contest joining fees</b><br></br> Pickmonk offers contests with a minimum joining fees, so that everyone can enjoy the fantasy games.</p>
                            <br></br><p><b>Instant withdrawals of Winnings</b><br></br>  Pickmonk offers instant withdrawals of the winnings from the fantasy games, so contestants don’t need to wait to withdraw their winnings.</p>
                            <br></br><p><b>Refer and Earn</b><br></br> Pickmonk also offers Refer and Earn feature, where contestants can invite their friends and family to join them for the games and also earn the referral bonus of Rs.100, which can be used to join paid contests.</p>
                          
                            <div class="getapplink">
                                {/* <span class="title">GET APP DOWNLOAD LINK ON SMS</span> */}
                                {/* <div class="input-group mb-3">
                                    <span class="input-group-text" id="inputGroup-sizing-default">+91</span>
                                    <input type="text" class="form-control" aria-label="Sizing example input" placeholder="Type your mobile no. here..." />
                                    <button class="btn getlinkbtn" type="button" id="button-addon2">Get Download Link</button>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 pt20"> <img class="img-fluid" src="assets/images/appshots.png" alt="" /> </div>
                    </div>
                </div>
            </section>
            <section class="testimonial">
                <div class="container">
                    <h2 class="white">Testimonials</h2>
                    <div class="owl-carousel owl-theme">
                        <div class="item">
                            <img src={userimg1} alt="" class="user-img" />
                            <h4>Sonu Shrivastav</h4>
                            <p> In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without </p>
                        </div>
                        <div class="item">
                            <img src={userimg2} alt="" class="user-img" />
                            <h4>Vivek Mishra</h4>
                            <p> In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without </p>
                        </div>
                        <div class="item">
                            <img src={userimg3} alt="" class="user-img" />
                            <h4>Sunny</h4>
                            <p> In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without </p>
                        </div>
                        <div class="item">
                            <img src={userimg1} alt="" class="user-img" />
                            <h4>Sonu Shrivastav</h4>
                            <p> In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without </p>
                        </div>
                        <div class="item">
                            <img src={userimg2} alt="" class="user-img" />
                            <h4>Vivek Mishra</h4>
                            <p> In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without </p>
                        </div>
                        <div class="item">
                            <img src={userimg3} alt="" class="user-img" />
                            <h4>Sunny</h4>
                            <p> In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without </p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="faq">
                <div class="col-lg-7 col-md-12 container">
                    <h2>Frequently asked questions</h2>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">What is Pickmonk?</button>
                            </h4>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                Pickmonk is one of the fastest growing online fantasy sports gaming platforms in India. Pickmonk enables the users to earn winnings by using their skills and knowledge of online fantasy sports gaming. <br />
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> I don’t know how to play cricket, can I still play on Pickmonk ? </button>
                            </h4>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                The online fantasy cricket gaming services offered by Pickmonk are for everyone including those who don’t know much about cricket.
                                <br/>
                                 So, yes, you can play even if you don’t know much about cricket.
                                 <br/>
                                  In addition, the “How To Play” section of Pickmonk App and website can assist you in playing the game by briefing you about the playing tactics.
                                </div>
                            </div>
                        </div>                                        
                    </div>
                </div>
            </section>
            <CommanFooter />
        </main>
    </>
    )
}
export default Home