import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { IconButton, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ApiService from '../../../api/ApiService';
import SectionTitle from '../../../components/section-title';
import Widget from '../../../components/widget';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import Button from '@mui/material/Button';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { ExportToCsv } from 'export-to-csv';
import SearchIcon from '@mui/icons-material/Search';
import { options, setSorting } from '../helper/helper';
import { useNavigate } from 'react-router-dom';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import DeleteAdminDialog from '../../../components/dialogs/DeleteAdmin';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LockResetIcon from '@mui/icons-material/LockReset';
const SubAdminList = () => {
    const [subAdminList, setSubAdminList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [isLoading, setIsFetching] = useState(false);
    const [labelSearch, setLabelSearch] = useState('');
    const [sortOption, setSortOption] = useState({ createdOn: -1 });
    const [totalRecord, setTotalRecord] = useState(null);
    const [requestDialog, setRequestDialog] = React.useState(false);
    const [requestPasswordDialog, setrequestPasswordDialog] = React.useState(false);
    const [password, setpassword] = useState('');
    const [confirmpassword, setconfirmpassword] = useState('');
    const [index, setIndex] = React.useState(null);
    const [reason, setReason] = useState(null)
    const [data, setData] = React.useState(null);
    const [data1, setData1] = React.useState(null);
    console.log("data1", data1);
    const openRequestDialog = (value) => {
        setData(value);
        setRequestDialog(true);
    }
    const closeRequestDialog = () => {
        setRequestDialog(false);
    }
    const openRequestPassword = (value) => {
        setData1(value);
        setrequestPasswordDialog(true);
    }
    const closeRequestPassword = () => {
        setrequestPasswordDialog(false);
    }
    const navigate = useNavigate();
    const getSubAdminList = (currentIndex, search, sort, size) => {
        setIndex(currentIndex)
        let payload = { 
            "filterOption": search,
            "sortOption": sort || sortOption,
            "pageSizeOption": { "skip": parseInt(currentIndex), "limit": size? size : pageSize }
         }
        setIsFetching(true);
        ApiService.post(`v1/admin/getAdminUsers`, payload).then((response) => {
            if (response.status === 200) {
                setIsFetching(false);
                setSubAdminList(response.data.body.data)
                setTotalRecord(response.data.body.recordCount)
            } else {
                setIsFetching(false);
                toast.error(response.data.message);
            }
            setIsFetching(false);
        }).catch((error) => {
            setIsFetching(false);
            toast.error(error.response.data.message);
        });
    }
    const deleteUser = (id) => {
        ApiService.post(`v1/admin/deleteAdminUser`, {'id':id}).then((response) => {
            if (response.status === 200) {
                setSubAdminList(response.data.body.data);
                toast.success(response.data.message)
                getSubAdminList(0, '');
                closeRequestDialog(false)
            } else {
                toast.error(response.data.message);
            }
            setIsFetching(false);
        })
    }
    const ChangePassword = (id) => {
        if((password.length === 0 && password.length<6) || (confirmpassword.length === 0 && confirmpassword.length<6) || (password !== confirmpassword)){
            return true;
        }
        let payload = { 
            "id": id,
            "password": confirmpassword
         }
        console.log("payload", payload);
        setIsFetching(true);
        ApiService.post(`v1/admin/changeAdminUserPassword`, payload).then((response) => {
            if (response.status === 200) {
                setIsFetching(false);
                toast.success(response.data.message);
                closeRequestPassword(false)
                // setSubAdminList(response.data.body.data)
            } else {
                setIsFetching(false);
                toast.error(response.data.message);
            }
            setIsFetching(false);
        }).catch((error) => {
            setIsFetching(false);
            toast.error(error.response.data.message);
        });
    }

    const columns = [
        { field: 'name', headerName: `Name`, flex: 1 },
        { field: 'username', headerName: `User Name`, flex: 1 },
        { field: 'email', headerName: `Email`, flex: 1 },
        { field: 'mobile', headerName: `Mobile`, flex: 1 },
        {
            field: 'action', headerName: 'Action', flex: 1,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Edit">
                        <IconButton
                            onClick={() =>
                                (navigate(`/admin/sub-admin/edit/${params.id}`))}  >
                            <ModeEditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton onClick={() => (openRequestDialog(params))}>
                            <RemoveCircleOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        },
        {
            field: '1', headerName: 'Change Password', flex: 1,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Edit">
                        <IconButton
                            onClick={() =>
                                (openRequestPassword(params.id))}  >
                            <LockResetIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        },
    ];
    
    useEffect(() => {
        getSubAdminList(0, '');
    }, [])
    
    return (
        <>
            <SectionTitle title="Manage Admins" subtitle="Manage Admins"></SectionTitle>
            <Widget>
                <Paper elevation={2}>
                    <Grid style={{ width: '100%', paddingLeft: '1%' }}>
                        <TextField id="standard-basic" label="Keywords" variant="standard" style={{ width: '30%' }}
                            onChange={(e) => getSubAdminList(0, e.target.value)}
                        />
                        <Button variant="contained"
                            style={{ float: 'right', margin: '10px' }}
                            className='[btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded]'
                            onClick={() => (navigate('/admin/sub-admin/add'))}
                        >
                            Create
                        </Button>
                    </Grid>
                    <div style={{ minHeight: 700, height: 600, width: '100%' }}>
                        <DataGrid
                            style={{ width: '100%', height: '100px !important' }}
                            loading={isLoading}
                            rows={subAdminList}
                            pagination
                            rowCount={totalRecord}
                            pageSize={pageSize}
                            sortingMode="server"
                            paginationMode="server"
                            onPageChange={(index) => {
                                let skip =pageSize*index;
                                getSubAdminList(skip, labelSearch)
                            }}
                            onSortModelChange={(modal) => {
                                const sort = setSorting(modal);
                                getSubAdminList(0,'', sort)
                                setSortOption(sort)
                            }}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                                getSubAdminList(0, labelSearch, '', newPageSize)
                            }}
                            columns={columns}
                        />
                    </div>
                </Paper>
                <DeleteAdminDialog 
                 open={requestDialog} 
                 data={data}
                 handleClose={closeRequestDialog}
                 setReason={setReason}
                 acceptDeleteRequest={deleteUser}
                 />
                 
        <div style={{  width: '500px' }}>
            <Dialog open={requestPasswordDialog} onClose={closeRequestPassword} style={{width:'100%'}}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent><DialogContentText>Enter new password details</DialogContentText></DialogContent>
                    
                    <TextField id="standard-basic" label="New Password" variant='filled' style={{ width: '150%' }}
                                onChange={(e) => {setpassword(e.target.value);}} 
                                />
                                {(password.length>0 && password.length<6) && <div style={{color:"red"}}>Your password should have at least 6 characters</div>}
                    <TextField id="standard-basic" label="Confirm Password" variant="filled" style={{ width: '100%' }}
                                onChange={(e) => {setconfirmpassword(e.target.value);}} 
                                />
                    { (confirmpassword.length !== 0 && (password !== confirmpassword)) && <div style={{color:"red"}}>New password should same as confirm password</div>}
                <DialogActions>
                <Button variant="contained"
                        style={{ float: 'left', marginTop: '2%', marginRight: '1%', }}
                        color="success"
                        fullWidth
                        className='[btn btn-default text-white btn-rounded]'
                        onClick={() =>ChangePassword(data1)}
                        // disabled={(password.length === 0 && password.length<6) || (confirmpassword.length === 0 && confirmpassword.length<6) || (password !== confirmpassword)}
                        >Yes</Button>
                    <Button
                        variant="contained"
                        style={{ float: 'right', marginTop: '2%', marginRight: '1%' }}
                        color="error"
                        fullWidth
                        className='[btn btn-default text-white btn-rounded]'
                        onClick={closeRequestPassword}> Cancel </Button>
                </DialogActions>
            </Dialog>
        </div>
            </Widget>
        </>
    )
}
export default SubAdminList