import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import Centered from './centered'
import Empty from './empty'
import Layout1 from './layout-1'
import Appstore from '../pages/appstore'

const Layouts = ({ children }) => {
    let location = useLocation()
    let { pathname } = { ...location }
    pathname = pathname.toLowerCase();

    if (['/admin/', '/admin', '/admin/login'].includes(pathname)) {
        const NewComponent = Centered({ children });
        return NewComponent;
    } else if (['/app-store', '/appstore', '/Appstore'].includes(pathname)) {
        return <Appstore>{children}</Appstore>
    }
    else if (['/', '/index', '/profile-pic'].includes(pathname)) {
        return <Empty>{children}</Empty>
    } else if (pathname.includes('/content/') || pathname.includes('/facebook-status') || pathname.includes('/digilocker-handler')) {
        return <Empty>{children}</Empty>
    }
    else if (pathname.includes('/admin/')) {
        return <Layout1>{children}</Layout1>
    } else {
        return <Navigate to='/' />
    }
}

export default Layouts
